import ILEmDOEntity from '../common/lemdoEntity'
import * as yup from 'yup'
import { getPropertyName } from 'lib/interfaceUtils'

export default interface ITaak extends ILEmDOEntity{
    naam: string
}

export const taakSchema = yup.object().shape({
    [getPropertyName<ITaak>('naam')]: yup.string().required('Naam is verplicht').max(150)
})
