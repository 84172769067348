import React from 'react'
import { zodiosHooks } from '../../../../api/ApiClient'
import { IStackStyles, Stack, Text } from '@fluentui/react'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import MeterstandenRow from './meterstandenRow'

interface IProps {
  vgeId: number;
}

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '5px',
    margin: '15px 0 10px 0',
    overflowX: 'auto'
  }
}

export const Meterstanden = ({ vgeId }: IProps) => {

  const [error, setError] = React.useState<string>()

  const { data, invalidate } = zodiosHooks.useGetVgeMeterstand(
    { queries: { VgeId: vgeId ?? 0 } }, { onError: (error) => setError(getTitleAndMessage(error).message) })

  return (
    <>
      <Stack styles={stackStyles}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
          <Text style={{ width: 450, minWidth: 200, fontWeight: '800', paddingLeft: 10 }}>Metertype</Text>
          <Text style={{ width: 150, minWidth: 110, fontWeight: '800', textAlign: 'center' }}>Aantal</Text>
          <Text style={{ width: 80, minWidth: 50, fontWeight: '800' }}>Eenheid</Text>
          <Text style={{ width: 240, minWidth: 200, fontWeight: '800', paddingLeft: 5 }}>Reden registratie</Text>
          <Text style={{ width: 80, fontWeight: '800', textAlign: 'left' }}>Historie</Text>
          <span style={{ width: 250 }}></span>
        </div>
        {data?.map((item, index) => (
          <MeterstandenRow key={index} item={item} handleSubmissionSuccess={invalidate} />
        ))}
      </Stack>
      <ErrorMessageBar error={error} />
    </>
  )
}
