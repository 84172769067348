import { useNavigate } from 'react-router-dom'
import { CommandBar, IColumn, ICommandBarItemProps, Link, SearchBox, Stack, StackItem } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import commandBarStyles from 'styles/commandBarStyles'
import { transformDateString } from '../lemdo/common/support'
import { clearSelection, deleteOnderhoudsContract, removeMany } from './onderhoudsContractSlice'
import { fetchRechtspersonen, selectAll as selectSuppliers } from '../relaties/rechtspersoon/rechtspersoonSlice'
import { PageIdDefinition, PAGES } from 'pages'
import TitleTemplate from 'containers/pages/PageTemplates/TitleTemplate'
import { usePagedParams } from '../../../services/usePagedParams'
import { OnderhoudscontractDto, zodiosHooks } from '../../../api/ApiClient'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { z } from 'zod'

type OnderhoudscontractDtoType = z.infer<typeof OnderhoudscontractDto>;

const onGetKey = (item: OnderhoudscontractDtoType) => item?.id

const defaultOnderhoudsContract = {
  id: 0,
  leverancierId: 0,
  voorVolledigBezit: false
} as OnderhoudscontractDtoType

const dateColumnWidth = 100

const OnderhoudsContractGrid: React.FC<{}> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedItems, setSelectedItems] = useState<number[]>([] as number[])
  const suppliers = useSelector(selectSuppliers)
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()

  const {
    data,
    invalidate: fetchOnderhoudsContract,
    isLoading: isFetchingOnderhoudsContract
  } = zodiosHooks.useGetApiOnderhoudsContract({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  })

  const handleListSelectionChanged = (ids: number[]) => {
    setSelectedItems(ids)
  }

  useEffect(() => {
    fetchOnderhoudsContract()
    dispatch(fetchRechtspersonen({ filter: '', relatieRol: 'Leverancier' }))
  }, [])

  const handleItemInvoked = (item: OnderhoudscontractDtoType) => {
    navigate(PAGES[PageIdDefinition.contract.edit].route.replace(':id', item.id.toString()))
  }

  const removeItems = () => {
    dispatch(deleteOnderhoudsContract(selectedItems))
    dispatch(removeMany(selectedItems))
    dispatch(clearSelection())
  }

  const getRelatieHandelsNaam = (relatieId: number) => suppliers.find(i => i.id === relatieId)?.handelsnaam

  const columns = [
    {
      key: getPropertyName<OnderhoudscontractDtoType>('contractNummer'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('contractNummer'),
      name: 'Contractnummer',
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: OnderhoudscontractDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item)}>
          {item.contractNummer}
        </Link>
      )
    },
    {
      key: getPropertyName<OnderhoudscontractDtoType>('contractOmschrijving'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('contractOmschrijving'),
      name: 'Contractomschrijving',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<OnderhoudscontractDtoType>('contractBeheerder'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('contractBeheerder'),
      name: 'Contractbeheerder',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<OnderhoudscontractDtoType>('leverancierId'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('leverancierId'),
      name: 'Contractpartij',
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: OnderhoudscontractDtoType) => <label>{getRelatieHandelsNaam(item.leverancierId)}</label>
    },
    {
      key: getPropertyName<OnderhoudscontractDtoType>('ingangsDatum'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('ingangsDatum'),
      name: 'Ingangsdatum',
      minWidth: dateColumnWidth,
      maxWidth: dateColumnWidth,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: OnderhoudscontractDtoType) => <>{transformDateString(item.ingangsDatum)}</>
    },
    {
      key: getPropertyName<OnderhoudscontractDtoType>('eindDatum'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('eindDatum'),
      name: 'Einddatum',
      minWidth: dateColumnWidth,
      maxWidth: dateColumnWidth,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: OnderhoudscontractDtoType) => <>{transformDateString(item.eindDatum)}</>
    },
    {
      key: getPropertyName<OnderhoudscontractDtoType>('uitersteOpzegDatum'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('uitersteOpzegDatum'),
      name: 'Uiterste opzegdatum',
      minWidth: 150,
      maxWidth: 150,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: OnderhoudscontractDtoType) => <>{transformDateString(item.uitersteOpzegDatum)}</>
    },
    {
      key: getPropertyName<OnderhoudscontractDtoType>('notificatieDatum'),
      fieldName: getPropertyName<OnderhoudscontractDtoType>('notificatieDatum'),
      name: 'Notificatiedatum',
      minWidth: dateColumnWidth,
      maxWidth: dateColumnWidth,
      isResizable: false,
      isRowHeader: true,
      onRender: (item: OnderhoudscontractDtoType) => <>{transformDateString(item.notificatieDatum!)}</>
    }
  ] as IColumn[]

  const commandBarItems = [
    {
      key: 'back',
      iconOnly: true,
      iconProps: { iconName: 'Back', className: 'icon' },
      split: false,
      ariaLabel: 'back',
      onClick: () => navigate(-1)
    },
    {
      key: 'refresh',
      text: 'Verversen',
      iconProps: { iconName: 'Refresh', className: 'icon' },
      split: false,
      ariaLabel: 'Verversen',
      onClick: () => fetchOnderhoudsContract()
    },
    {
      key: 'create',
      text: 'Nieuw',
      iconProps: { iconName: 'NewFolder', className: 'icon' },
      split: false,
      ariaLabel: 'Nieuw',
      onClick: () => {
        handleItemInvoked(defaultOnderhoudsContract)
      }
    },
    {
      key: 'delete',
      text: 'Verwijder',
      iconProps: { iconName: 'Delete', className: 'icon' },
      split: false,
      ariaLabel: 'Verwijderen',
      disabled: selectedItems.length === 0,
      onClick: () => removeItems()
    }
  ] as ICommandBarItemProps[]

  return (
    <div className='componentWrapper'>
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <StackItem align='baseline' grow={3}>
          <CommandBar items={commandBarItems} styles={commandBarStyles} />
        </StackItem>
      </Stack>
      <TitleTemplate title='Onderhoudscontracten' subTitle='Overzicht' />
      <SearchBox
        placeholder='Zoeken ...'
        styles={{ root: { maxWidth: '400px', margin: '6px' } }}
        value={validatedSearchParams.filter}
        onSearch={() => fetchOnderhoudsContract()}
        onClear={_ => setFilter('')}
        onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
        autoComplete='off'
      />
      <VgtPagedTable
        items={data?.items ?? undefined}
        height={'200'}
        isLoading={isFetchingOnderhoudsContract}
        columns={columns}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage ?? false,
          hasPreviousPage: data?.hasPreviousPage ?? false,
          sortKey: validatedSearchParams.sortKey,
          sortDirection: validatedSearchParams.sortDirection
        }}
        getKey={onGetKey}
        onPageChanged={setPage}
        onSortChanged={setOrder}
        onItemInvoked={handleItemInvoked}
        onSelectionChanged={handleListSelectionChanged}
      />
    </div>
  )
}

export default OnderhoudsContractGrid
