import { IColumn, mergeStyleSets, SearchBox, SelectionMode, Stack, StackItem } from "@fluentui/react"
import React, { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { fetchQuery, getLoadingState, getRecordsFound, IVerwerking, selectAll, setSelectedRowIndex } from "./verwerkingSlice"
import FluentTable from "components/FluentTable"
import { getPropertyName } from "lib/interfaceUtils"
import { createColumn, createCustomRenderedColumn } from "lib/gridHelper"
import { PageIdDefinition, PAGES } from "pages"
import MainTemplate from "containers/pages/PageTemplates/MainTemplate"
//import UseApi from "services/UseApi";

const classNames = mergeStyleSets({
  wrapper: {
    marginTop: 10,
    marginLeft: 10,
    height: '90vh',
    position: 'relative',
  },
  listWrapper: {
    marginTop: 10,
    marginLeft: 10,
    height: '90vh',
    width: '99%',
    position: 'absolute',
  },
})

const formatToDateTimeString = (date: Date | undefined) => {
  if (!date) return ''
  const parsedDate = new Date(date)
  return parsedDate.toLocaleDateString('nl-NL', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
}

const Verwerkingen: React.FC<{}> = _props => {
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentFilter, setCurrentFilter] = useState<string | undefined>('')

  const items = useSelector(selectAll)
  const loadingStatus = useSelector(getLoadingState)
  const recordsFound = useSelector(getRecordsFound)
  
  useEffect(() => { fetchData(undefined) }, [])

  const handleListSelectionChanged = (ids: string[]) => setSelectedItems(ids)

  const onRefreshClicked = () => {
    fetchData(currentFilter)
  }

  const fetchData = (filter: string | undefined) => {
    dispatch(fetchQuery({ top: 50000, filter: filter ?? '' }))
  }

  const onSearch = (newValue: string | undefined) => {
    fetchData(newValue)
  }

  const searchBoxChanged = (event, newValue) => {
    setCurrentFilter(newValue)
  }

  const onGetKey = (item: IVerwerking) => item?.id?.toString()

  const handleItemInvoked = (id: string, index: number) => {
    dispatch(setSelectedRowIndex(index))
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('ID', getPropertyName<IVerwerking>('id'), 'S'),
      createColumn('Beschrijving', getPropertyName<IVerwerking>('beschrijving'), 'XL'),
      createCustomRenderedColumn('Gestart op', getPropertyName<IVerwerking>('gestartOp'), (x: IVerwerking) => <>{formatToDateTimeString(x.gestartOp)}</>, 'M', false, false),
      createColumn('Gestart door', getPropertyName<IVerwerking>('gestartDoor'), 'M'),
      createColumn('Status', getPropertyName<IVerwerking>('status'), 'M'),
      createColumn('Foutmelding', getPropertyName<IVerwerking>('foutmelding'), 'M'),
    ]
  }, [])

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => onRefreshClicked(), icon: 'Refresh' },
    { text: 'Naar regels', onClick: () => navigate(PAGES[PageIdDefinition.beheer.imports.verwerkingsRegels].route.replace(':verwerkingen', selectedItems.join(','))), icon: 'Forward' },
  ]

  return (
    <MainTemplate title="Verwerkingen" subTitle="Overzicht" commandItems={commandItems}>
            <Stack horizontal verticalAlign={'center'} tokens={{ padding: 10, childrenGap: 10 }}>
              <StackItem>
                <SearchBox value={currentFilter} onChange={searchBoxChanged} onSearch={onSearch} />
              </StackItem>
              <StackItem><span>{recordsFound} verwerkingen gevonden.</span></StackItem>
            </Stack>
        
        <div className={classNames.listWrapper}>
          <FluentTable
            columns={columns}
            items={items}
            loading={loadingStatus === 'pending'}
            selectionMode={SelectionMode.multiple}
            onSelectionChanged={handleListSelectionChanged}
            onGetKey={onGetKey}
            onItemInvoked={handleItemInvoked}
          />
        </div>
      
    </MainTemplate>
  )
}

export default Verwerkingen
