import React, { useEffect } from 'react'
import MarkdownEditor from '@uiw/react-markdown-editor';
import { IVgtFieldPropsBase } from '../../lib/IVgtFieldPropsBase'
import { Controller } from 'react-hook-form'


interface IVgtFormattedTextProps extends IVgtFieldPropsBase {
  name: string
  label: string
  control: any
  errors: any
  required?: boolean
  defaultValue?: string
  height?: string
  labelWidth?: number
  editorWidth?: string;
  toolbarBottom?: boolean
  readOnly?: boolean
}

const VgtFormattedText: React.FC<IVgtFormattedTextProps> = props => {

  useEffect(() => {
    document.documentElement.setAttribute('data-color-mode', 'light');
  }, []);

  return (
    <div hidden={props.showIf !== undefined && !props.showIf}>
      <div style={{ display: 'flex', width: '100%', paddingTop: '5px' }}>
        <label style={{ display: 'block', marginBottom: '8px', width: props.labelWidth, paddingLeft: '16px' }}>
          {props.label}{props.required && ' *'}
        </label>
        <div style={{ flexGrow: props.editorWidth ? 0 : 1, width: props.editorWidth ?? '100%' }}>
          <Controller
            name={props.name}
            control={props.control}
            defaultValue={props.defaultValue}
            rules={{ required: props.required ? 'This field is required' : false }}
            render={({ onChange, value }) => (
              <MarkdownEditor
                value={value ?? ''}
                readOnly={props.readOnly}
                height={props.height ?? '200px'}
                visible={true}
                onChange={onChange}
                toolbars={['bold', 'italic', 'underline', 'ulist', 'olist']}
                toolbarBottom={props.toolbarBottom ?? true}
              />
            )} />
          {props.errors[props.name] && <p
            style={{
              fontWeight: 400,
              fontSize: '12px',
              color: 'rgb(164, 34, 48)'
            }}>{props.errors[props.name].message}</p>}
        </div>
      </div>
    </div>
  )
}

export default VgtFormattedText


