import React, { useState } from 'react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { useNavigate, useParams } from 'react-router-dom'
import { VheForm } from './VheForm'
import { zodiosHooks } from '../../api/ApiClient'
import { z } from 'zod'
import { getTitleAndMessage } from '../../services/HandleError'

export const EditParams = z.object({
  id: z.string().regex(/^\d+$/, 'Geen geldige id').transform(s => Number(s))
})

export const VheEdit = () => {
  const navigate = useNavigate()
  const params = useParams()
  const validatedParams = EditParams.parse(params)
  const [error, setError] = useState<string>()

  const { data, isLoading, invalidate } = zodiosHooks.useGetVhesId({ params: { id: validatedParams.id }}, {enabled: validatedParams.id > 0})

  const { mutate, isLoading: isSubmitting } = zodiosHooks.usePutVhesId(
    { params: { id: validatedParams.id } }, {
      onSuccess: () => invalidate().then(() => navigate(-1)),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true }
  ]

  const setComponentError = (error: string) => setError(error)

  return (
    <MainTemplate title='Verhuureenheid' subTitle='bewerken' commandItems={commandItems} error={error}>
      <VheForm
        vheId={validatedParams.id}
        data={data}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        onError={setComponentError}
        onSubmit={(data) => mutate(data)}
        onCancel={() => navigate(-1)} />
    </MainTemplate>
  )
}