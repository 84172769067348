import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DefaultButton, DetailsList, IColumn, IObjectWithKey, mergeStyleSets, Panel, PanelType, SearchBox, Selection, SelectionMode, Stack, Sticky, StickyPositionType } from '@fluentui/react'
import { debounce } from 'lodash'
import { getPropertyName } from 'lib/interfaceUtils'
import { clearResult, fetchAll, ITenantTaak, selectAll } from '../lemdo/tenantTaak/tenantTaakSlice'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  onSelect: (taken: ITenantTaak[]) => void
  excludeIds: number[]
}

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    margin: '10px',
  },
  filter: {
    backgroundColor: 'white',
    paddingBottom: 20,
    maxWidth: 300,
  },
  header: {
    margin: 0,
    backgroundColor: 'white',
  },
  row: {
    display: 'inline-block',
  },
})

const createFilteredTaken = (taken: ITenantTaak[], excludeIds: number[]) => {
  return taken.filter(taak => excludeIds.findIndex(t => t === taak.tenantTaakId) === -1)
}

const SelectTaakPanel: React.FC<IEditPanelProps> = props => {
  const unfilteredTaken = useSelector(selectAll)
  const taken = useMemo(() => createFilteredTaken(unfilteredTaken, props.excludeIds!), [unfilteredTaken, props.excludeIds])
  const dispatch = useDispatch()
  const [currentSearchValue, setCurrentSearchValue] = useState<string>()

  useEffect(() => {
    dispatch(clearResult())
  }, [])

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ITenantTaak>({ mode: 'onChange' })

  const clearSearch = () => {
    setCurrentSearchValue('')
    dispatch(clearResult())
  }

  const closePanel = () => {
    clearSearch()
    props.dismissPanel()
  }

  const onCancel = () => {
    closePanel()
  }

  useEffect(() => {
    if (props.isOpen) clearSearch()
  }, [props.isOpen])

  const onSubmit = () => {
    if (selection.count > 0) {
      const items = selection.getSelection() as ITenantTaak[]
      props.onSelect(items)
    }
  }

  const [selection] = React.useState(() => {
    return new Selection<ITenantTaak>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: item => item.tenantTaakId,
    })
  })

  const delayedSearch = debounce(eventData => dispatch(fetchAll({ filter: eventData, onlyForOnderhoudscontract: true })), 500)
  

  const onSearchBoxChanged = (newValue: string | undefined) => {
    setCurrentSearchValue(newValue)
    if (newValue && newValue.length >= 2) {
      delayedSearch(newValue)
    }
  }

  const onClearSearchBox = () => {
    clearSearch()
  }

  const onRenderFooterContent = () => {
    return (
      <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
        <DefaultButton text="Koppelen" type="submit" primary disabled={isSubmitting} onClick={onSubmit} />
        <DefaultButton text="Annuleren" onClick={onCancel} />
      </Stack>
    )
  }

  const columns = [
    {
      name: 'Naam',
      fieldName: getPropertyName<ITenantTaak>('taakNaam'),
      key: getPropertyName<ITenantTaak>('taakNaam'),
      minWidth: 50,
      maxWidth: 400,
      isResizable: true,
    },
  ] as IColumn[]

  return (
    <Panel
      type={PanelType.medium}
      headerText={'Koppelen taken'}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}
    >
      <div className={classNames.wrapper}>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <SearchBox placeholder="Zoek taak" onChanged={onSearchBoxChanged} onClear={onClearSearchBox} value={currentSearchValue} autoComplete="off" />
          </Sticky>
          <DetailsList items={taken} selection={selection as Selection<IObjectWithKey>} columns={columns} />
        </form>
      </div>
    </Panel>
  )
}

export default SelectTaakPanel
