import { IColumn, Link, SelectionMode } from '@fluentui/react'
import FluentTable from 'components/FluentTable'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { PageIdDefinition, PAGES } from 'pages'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchGebouwen, IGebouw, } from '../beheer/fysiek/gebouwen/gebouwenSlice'
import { selectAll, getLoadingState } from './fysiekSlice'

const GebouwenTable: React.FC<{}> = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const items = useSelector(selectAll)
  const loadingStatus = useSelector(getLoadingState)
  const onGetKey = (item: IGebouw) => item?.id?.toString()

  useEffect(() => {
    dispatch(fetchGebouwen({filter: ''}))
  }, [])

  const getGebouwType = (type: number | undefined) => {
    switch (type) {
      case 1:
        return <span>Hoogbouw</span>
      case 2:
        return <span>Laagbouw</span>
      default:
        return ''
    }
  }

  const handleItemInvoked = (id: string) => {
    let url: string
    if (id) url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route.replace(':id', id)
    else url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route
    navigate(url)
  }

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<IGebouw>('code'), 
      (item: IGebouw) => <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>{item.code}</Link>, 65, false),
    createColumn('Naam', getPropertyName<IGebouw>('naam'), 275),
    createCustomRenderedColumn('Type bouw', getPropertyName<IGebouw>('type'), (item: IGebouw) => getGebouwType(item.type), 75, false),
    createColumn('Omschrijving', getPropertyName<IGebouw>('omschrijving'), 275)
  ]

  return (
    <div className="componentWrapper">
      <div className="listContent">
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingStatus === 'pending'}
          selectionMode={SelectionMode.multiple}
          //onSelectionChanged={handleListSelectionChanged}
          onGetKey={onGetKey}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </div>
  )
}

export default GebouwenTable
