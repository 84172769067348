import { DirectionalHint, ITooltipHostStyles, ITooltipProps, TooltipHost } from '@fluentui/react'
import React from 'react'
import ReadOnlyField from '../ReadOnlyField'

interface ITooltipFieldProps {
  tooltipId: string
  title: string
  data: { key: string, value: string | null }[]
  onClick: (key: string) => void
  labelWidth?: number
  fieldWidth?: number
}

export const VgtTooltip: React.FC<ITooltipFieldProps> = props => {

  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <>
        <div>{props.title}:</div>
        <ul style={{ margin: 10, padding: 0 }}>
          {(props.data || []).map((item, index) => (
            <li key={index} onClick={() => props.onClick(item.key)}
                style={{ margin: '18px 0', cursor: 'pointer' }}>{item.value}<br />
            </li>
          ))}
        </ul>
      </>
    )
  }

  const calloutProps = { gapSpace: 0 }
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: {
      display: 'inline-block',
      border: '1px solid rgb(255 0 0 / 6%)',
      borderRadius: '5px'
    }
  }

  return (
    <TooltipHost id={props.tooltipId} tooltipProps={tooltipProps} calloutProps={calloutProps}
                 styles={hostStyles} directionalHint={DirectionalHint.bottomRightEdge}>
      <ReadOnlyField title={props.title} value={props.data.map(item => item.value).join(', ')}
                     fieldWidth={props.fieldWidth ?? '100%'} labelWidth={props.labelWidth ?? 165} />

    </TooltipHost>
  )
}