import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IVeraEntity } from '../common/lemdoEntity'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import { IDropdownOption } from '@fluentui/react'

export interface IWoningverbetering extends IVeraEntity  { 
    woningverbeteringId: number,
    omschrijving: string,
    voorziening: string,
    iconCacheId: string,
    iconFileExtension: string,
    iconUrl: string,
    elementElementDetailId: number|undefined,
    cartotheekItem: string,
    inspectietaakId: number|undefined,
    inspectietaak: string
    fonticoonId?: number
    fonticoonNaam?: string
}

const entityAdapter = createEntityAdapter<IWoningverbetering>({
    sortComparer: (a, b) => a.omschrijving?.localeCompare(b.omschrijving),
    selectId: x => x.woningverbeteringId
  })
  
  const baseUrl = '/woningverbetering'
  const basePrefix = 'woningverbetering'

  export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (_, thunkAPI) => {
    return await invokeFetch<PagedEntities<IWoningverbetering>>(thunkAPI, 'GET', `${baseUrl}`)
  })
  
  const setPendingState = (state: EntityState<IWoningverbetering> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
  }
  const setSucceededState = (state: EntityState<IWoningverbetering> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
  }
  const setRejectedState = (state: EntityState<IWoningverbetering> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
  }
  
  export const WoningverbeteringSlice = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),
  
    reducers: {
      clearError: state => {
        state.error = null
      },
      select: (state, action: PayloadAction<string | undefined>) => {
        state.selectedId = action.payload
      },
      clearSelection: state => {
        state.selectedId = undefined
      },
      add: entityAdapter.addOne,
      modify: entityAdapter.upsertOne,
      removeMany: entityAdapter.removeMany,
      setAll: entityAdapter.setAll,
    },
  
    extraReducers: builder => {
      builder.addCase(fetchAll.pending, state => setPendingState(state))
      builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IWoningverbetering>>) => {
        setSucceededState(state)
        entityAdapter.setAll(state, action.payload.items)
      })
      builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
    },
  })
  
  export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => state.woningverbeteringen)


  export const getWoningverbeteringAsOptions = (state: RootState) => state.woningverbeteringen.ids.map((id: EntityId) => {
    const entity = state.woningverbeteringen.entities[id]
    return {
        key: id,
        text: `${entity?.omschrijving}`
    } as IDropdownOption
  })
  
  export default WoningverbeteringSlice.reducer
