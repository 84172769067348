import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'

export const entityAdapterInitState: IEntityAdapterState = {
    selectedId: undefined,
    status: 'idle',
    error: null,
    searchFilter: '',
}
const entityAdapter = createEntityAdapter<IFysiekOption>({
    sortComparer: (a, b) => a.title?.localeCompare(b.title),
})

const baseUrl = '/fysiek'
const basePrefix = 'fysiekdropdownoptions'
const getSliceState = (state: RootState) => state.fysiekOptions

export interface IFysiekOption {
    id: number
    title: string
    url: string
}

export const fetchFysiekDropdownOptions = createAsyncThunk(`${basePrefix}/fetchStatus`, async (_, thunkAPI) => {
    return await invokeFetch<IFysiekOption[]>(thunkAPI, 'GET', `${baseUrl}`)
})

const setPendingSate = (state: IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
}
const setSucceededSate = (state: IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
}
const setRejectedState = (state: IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
}

export const fysiekOptions = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(entityAdapterInitState),

    reducers: {},

    extraReducers: builder => {
        builder.addCase(fetchFysiekDropdownOptions.pending, state => setPendingSate(state))
        builder.addCase(fetchFysiekDropdownOptions.fulfilled, (state, action) => {
            setSucceededSate(state)
            entityAdapter.setAll(state, action.payload)
        })
        builder.addCase(fetchFysiekDropdownOptions.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
}
export const { selectAll } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export default fysiekOptions.reducer
