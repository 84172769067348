import React, { useEffect, useState } from 'react'
import { OppervlakteNen2580Dto, OppervlakteNen2580HeaderDto, zodiosHooks } from '../../../../api/ApiClient'
import InputCard from '../../../../components/InputCard'
import { Checkbox, ICheckboxStyles} from '@fluentui/react'
import ReadOnlyField from '../../../../components/ReadOnlyField'
import { z } from 'zod'
import { convertDecimalToText } from '../../../../lib/controlSupport'

interface OppervlakteProps {
  vgeId?: number
  gebouwId?: number
  gebouwdeelId?: number
}

type dtoType = z.infer<typeof OppervlakteNen2580HeaderDto>;
type recordType = z.infer<typeof OppervlakteNen2580Dto>;

type BouwlagenProps = {
  data?: dtoType
  onChange?: (value: number[]) => void
}

const Bouwlagen: React.FC<BouwlagenProps> = ({ data, onChange }) => {
  const [selectedBouwlagen, setSelectedBouwlagen] = useState<number[]>([])
  useEffect(() => {

    if (data?.oppervlaktes) {
      const bouwlagen = data.oppervlaktes.map(oppervlakte => oppervlakte.bouwlaagId ?? 0)
      setSelectedBouwlagen(bouwlagen)
      if (onChange) {
        onChange(bouwlagen)
      }
    }
  }, [data])

  if (!data) return (
    <></>
  )
  if (!data.oppervlaktes) return (
    <></>
  )

  const colors = ['orange', 'lightGreen', 'lightBlue', 'yellow', 'rgb(185, 62, 62)', 'pink']

  const handleCheckboxChange = (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean, bouwlaagId?: number) => {
    let updatedBouwlagen
    if (isChecked && bouwlaagId) {
      updatedBouwlagen = [...selectedBouwlagen, bouwlaagId]
    } else if (!isChecked && bouwlaagId) {
      updatedBouwlagen = selectedBouwlagen.filter(id => id !== bouwlaagId)
    }
    setSelectedBouwlagen(updatedBouwlagen)
    if (onChange) {
      onChange(updatedBouwlagen)
    }
  }

  return (
    <div>
      <div style={{ width: '0', height: '0', borderLeft: '100px solid transparent', borderRight: '108px solid transparent', borderBottom: '40px solid black' }}></div>
      {data.oppervlaktes.map((oppervlakte, index) => {
        const color = colors[index % colors.length]
        const checkboxStyles = (): Partial<ICheckboxStyles> => ({
          root: {
            border: 'none',
            marginLeft: '6px'
          },
          checkbox: {
            backgroundColor: color
          },
          label: {
            color: 'black',
            fontWeight: 'bold'
          },
          text: {
            marginLeft: '16px'
          },
          checkmark: {
            color: 'black'
          }
        })

        return (
          <div key={oppervlakte.bouwlaag}>
            <div style={{ backgroundColor: color, width: '200px', height: '100%', padding: '4px' }}>
              <Checkbox
                key={oppervlakte.bouwlaagId}
                label={oppervlakte.bouwlaag ?? ''}
                styles={checkboxStyles}
                defaultChecked={true}
                onChange={(ev, isChecked) => handleCheckboxChange(ev, isChecked, oppervlakte.bouwlaagId)}
              />
            </div>
          </div>
        )
      })
      }
    </div>
  )
}

interface IRowProps {
  label: string
  value?: number | null
}

const Row: React.FC<IRowProps> = (props: IRowProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
      <div style={{ width: '100%' }}>{props.label}</div>
      <div style={{ textAlign: 'end' }}>{convertDecimalToText(props.value)}</div>
      <div style={{ textAlign: 'end' }}>&nbsp;m2</div>
    </div>
  )
}

function TotalRow(props: { value: number | null | undefined }) {
  return (
    <>
      <div style={{ borderBottom: '1px solid #efefef', marginBottom: '10px' }}></div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div style={{ width: '100%', fontWeight: 'bold' }}>Totaal</div>
        <div style={{ textAlign: 'end', fontWeight: 'bold' }}>{convertDecimalToText(props.value)}</div>
        <div style={{ textAlign: 'end', fontWeight: 'bold' }}>&nbsp;m2</div>
      </div>
    </>
  )
}

interface ICardProps {
  title: string,
  children: React.ReactNode
}

const Card: React.FC<ICardProps> = (props: ICardProps) => {
  return (
    <div style={{ border: '1px solid #efefef', borderRadius: '5px', padding: '10px', marginBottom: '20px', maxWidth: '600px', minWidth: '400px' }}>
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>{props.title}</div>
      {props.children}
    </div>
  )
}

const Header: React.FC<{ data?: dtoType }> = ({ data }) => {
  return (
    <InputCard title="Algemeen" width={'calc(100%)'}>
      <div style={{ display: 'flex', marginRight: '20px' }}>
        <div style={{ flex: '1' }}>
          <ReadOnlyField title="Kompasrichting" value={data?.kompasrichting} />
          <ReadOnlyField title="Berekeningswijze" value={data?.berekeningswijze} />
        </div>
        <div style={{ flex: '1' }}>
          <ReadOnlyField title="Importbestand" value={data?.importBestand} />
          <ReadOnlyField title="Import op" value={data?.importOp} />
          <ReadOnlyField title="Import door" value={data?.importDoor} />
        </div>
      </div>
    </InputCard>
  )
}

const Oppervlakte = ({ vgeId, gebouwId, gebouwdeelId }: OppervlakteProps) => {
  const { data : vgeData, isLoading: isLoadingVge } = zodiosHooks.useGetVgeIdOppervlaktesNEN2580(
    { params: { id: vgeId ?? 0 } }, { enabled: (vgeId ?? 0) > 0 }
  )
  const { data: gebouwData, isLoading: isLoadingGebouw } = zodiosHooks.useGetGebouwenIdOppervlaktesNEN2580(
    { params: { id: gebouwId ?? 0 } }, { enabled: (gebouwId ?? 0) > 0 }
  )
  const { data: gebouwdeelData, isLoading: isLoadingGebouwdeel } = zodiosHooks.useGetGebouwdelenIdOppervlaktesNEN2580(
    { params: { id: gebouwdeelId ?? 0 } }, { enabled: (gebouwdeelId ?? 0) > 0 }
  )

  const data = vgeData ?? gebouwData ?? gebouwdeelData

  const [totals, setTotals] = useState<recordType>({} as recordType)
  const calculatedTotals = (selectedBouwlagen: number[]) => {
    let total: recordType = {} as recordType

    // sum the selected bouwlagen
    data?.oppervlaktes?.forEach(oppervlakte => {
        if (selectedBouwlagen.includes(oppervlakte.bouwlaagId ?? 0)) {
          total = {
            goWoonfunctie: (total.goWoonfunctie ?? 0) + (oppervlakte.goWoonfunctie ?? 0),
            goOverigeFunctie: (total.goOverigeFunctie ?? 0) + (oppervlakte.goOverigeFunctie ?? 0),
            goGemeenschappelijkeRuimten: (total.goGemeenschappelijkeRuimten ?? 0) + (oppervlakte.goGemeenschappelijkeRuimten ?? 0),
            goUtiliteitsfuncties: (total.goUtiliteitsfuncties ?? 0) + (oppervlakte.goUtiliteitsfuncties ?? 0),
            goOverigeAangrenzendeRuimteBergingGarage: (total.goOverigeAangrenzendeRuimteBergingGarage ?? 0) + (oppervlakte.goOverigeAangrenzendeRuimteBergingGarage ?? 0),
            vvoUnit: (total.vvoUnit ?? 0) + (oppervlakte.vvoUnit ?? 0),
            vvoGemeenschappelijkeRuimten: (total.vvoGemeenschappelijkeRuimten ?? 0) + (oppervlakte.vvoGemeenschappelijkeRuimten ?? 0),
            vvoOverigeRuimten: (total.vvoOverigeRuimten ?? 0) + (oppervlakte.vvoOverigeRuimten ?? 0),
            bvoGebouw: (total.bvoGebouw ?? 0) + (oppervlakte.bvoGebouw ?? 0),
            bvoOverdekteGebouwgebondenBuitenruimten: (total.bvoOverdekteGebouwgebondenBuitenruimten ?? 0) + (oppervlakte.bvoOverdekteGebouwgebondenBuitenruimten ?? 0),
            bvoNietOverdekteGebouwgebondenBuitenruimten: (total.bvoNietOverdekteGebouwgebondenBuitenruimten ?? 0) + (oppervlakte.bvoNietOverdekteGebouwgebondenBuitenruimten ?? 0),
            bvoGemeenschappelijkeBinnenruimteWoningen: (total.bvoGemeenschappelijkeBinnenruimteWoningen ?? 0) + (oppervlakte.bvoGemeenschappelijkeBinnenruimteWoningen ?? 0),
            bvoGemeenschappelijkeBinnenruimteBouwlaag: (total.bvoGemeenschappelijkeBinnenruimteBouwlaag ?? 0) + (oppervlakte.bvoGemeenschappelijkeBinnenruimteBouwlaag ?? 0),
            bvoGemeenschappelijkeBinnenruimteGebouw: (total.bvoGemeenschappelijkeBinnenruimteGebouw ?? 0) + (oppervlakte.bvoGemeenschappelijkeBinnenruimteGebouw ?? 0),
            bvoGemeenschappelijkeNietOverdekteBuitenruimten: (total.bvoGemeenschappelijkeNietOverdekteBuitenruimten ?? 0) + (oppervlakte.bvoGemeenschappelijkeNietOverdekteBuitenruimten ?? 0),
            bvoOverigeRuimten: (total.bvoOverigeRuimten ?? 0) + (oppervlakte.bvoOverigeRuimten ?? 0),
            goTotaal: (total.goTotaal ?? 0) + (oppervlakte.goTotaal ?? 0),
            vvoTotaal: (total.vvoTotaal ?? 0) + (oppervlakte.vvoTotaal ?? 0),
            bvoTotaal: (total.bvoTotaal ?? 0) + (oppervlakte.bvoTotaal ?? 0)
          } as recordType
        }
      }
    )

    setTotals(total)
  }

  if ((vgeId && isLoadingVge) || (gebouwdeelId && isLoadingGebouwdeel) || (gebouwId && isLoadingGebouw)) return <div>Loading...</div>

  if (!data?.oppervlaktes || data.oppervlaktes.length === 0) return <div>Geen oppervlakte data beschikbaar</div>

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginRight: '16px' }}>
      <div>
        { (vgeId ?? 0) > 0 ? <Header data={data} /> : null}
      </div>

      <div style={{ display: 'flex', flex: 1, marginTop: '20px' }}>
        <div style={{ flex: '0 0 auto' }}>
          <Bouwlagen data={data} onChange={calculatedTotals} />
        </div>
        <div style={{ flex: '1 1 auto', overflowY: 'auto', marginLeft: '20px' }}>
          <Card title="Gebruiksoppervlakte">
            <Row label="Woonfunctie" value={totals?.goWoonfunctie} />
            <Row label="Overige functie" value={totals?.goOverigeFunctie} />
            <Row label="Gemeenschappelijke ruimten" value={totals?.goGemeenschappelijkeRuimten} />
            <Row label="Utiliteitsfuncties" value={totals?.goUtiliteitsfuncties} />
            <Row label="Overige aangrenzende ruimte (berging/garage)" value={totals?.goOverigeAangrenzendeRuimteBergingGarage} />
            <TotalRow value={totals?.goTotaal} />
          </Card>
          <Card title="Verhuurbare vloeroppervlakte">
            <Row label="Unit" value={totals?.vvoUnit} />
            <Row label="Gemeenschappelijke ruimten" value={totals?.vvoGemeenschappelijkeRuimten} />
            <Row label="Overige ruimten" value={totals?.vvoOverigeRuimten} />
            <TotalRow value={totals?.vvoTotaal} />
          </Card>
        </div>
        <div style={{ flex: '1 1 auto', marginLeft: '20px' }}>
          <Card title="Bruto vloeroppervlakte">
            <Row label="Gebouw" value={totals?.bvoGebouw} />
            <Row label="Overdekte gebouwgebonden buitenruimten" value={totals?.bvoOverdekteGebouwgebondenBuitenruimten} />
            <Row label="Niet overdekte gebouwgebonden buitenruimten" value={totals?.bvoNietOverdekteGebouwgebondenBuitenruimten} />
            <Row label="Gemeenschappelijke binnenruimten woningen" value={totals?.bvoGemeenschappelijkeBinnenruimteWoningen} />
            <Row label="Gemeenschappelijke binnenruimten bouwlaag" value={totals?.bvoGemeenschappelijkeBinnenruimteBouwlaag} />
            <Row label="Gemeenschappelijke binnenruimten gebouw" value={totals?.bvoGemeenschappelijkeBinnenruimteGebouw} />
            <Row label="Gemeenschappelijke niet overdekte buitenruimten" value={totals?.bvoGemeenschappelijkeNietOverdekteBuitenruimten} />
            <Row label="Overige ruimten" value={totals?.bvoOverigeRuimten} />
            <TotalRow value={totals?.bvoTotaal} />
          </Card>
        </div>
      </div>
    </div>
  )
}

export default Oppervlakte