import React, { useEffect } from 'react'
import { IColumn, Link, SelectionMode } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import { fetchGebouwenClusters, IGebouwenCluster } from '../beheer/fysiek/gebouwenclusters/gebouwenClustersSlice'
import { selectAll, getLoadingState } from './fysiekSlice'
import { PageIdDefinition, PAGES } from 'pages'

const GebouwenClustersTable: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const items = useSelector(selectAll)
  const loadingStatus = useSelector(getLoadingState)

  const refreshItems = () => dispatch(fetchGebouwenClusters({ filter: '' }))

  useEffect(() => {
    if (items.length === 0) refreshItems()
  }, [])

  const handleItemInvoked = (id: string) => {
    navigate(PAGES[PageIdDefinition.beheer.fysiek.gebouwenClusterEdit].route.replace(':id', id))
  }
  const columns: IColumn[] = [
    {
      key: getPropertyName<IGebouwenCluster>('code'),
      name: 'Code',
      fieldName: getPropertyName<IGebouwenCluster>('code'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: IGebouwenCluster) => (
        <Link key={item.code} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      ),
    },

    {
      key: getPropertyName<IGebouwenCluster>('naam'),
      name: 'Naam',
      fieldName: getPropertyName<IGebouwenCluster>('naam'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
    },
  ]

  return (
    <div className="componentWrapper">
      <div className="listContent">
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingStatus === 'pending'}
          selectionMode={SelectionMode.multiple}
          onGetKey={(item: IGebouwenCluster) => item.id?.toString()}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </div>
  )
}
export default GebouwenClustersTable
