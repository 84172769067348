import React, {useMemo, useState} from "react";
import MainTemplate from "../../../containers/pages/PageTemplates/MainTemplate";
import {useNavigate} from "react-router-dom";
import {DefaultButton, DetailsList, Dropdown, IColumn, IDropdownOption, Label, Stack} from "@fluentui/react";
import useApi from "../../../services/UseApi";
import {createColumn, createCustomRenderedColumn} from "../../../lib/gridHelper";
import {getPropertyName} from "../../../lib/interfaceUtils";
import {IVerwerking} from "./verwerkingSlice";

export interface Verwerkingstype {
    id: number;
    code: string;
    naam: string;
    bestandNoodzakelijk: boolean;
    alleTenants: boolean;
}

const InvokeVerwerking = () => {
    const navigate = useNavigate()
    const {execute: executeVerwerking} = useApi('verwerking', "POST", false)
    const {state: verwerkingen, execute: fetchVerwerking, setUrl} = useApi('verwerking?top=20', "GET")
    const {state} = useApi('verwerking/verwerkingtypes', "GET")
    const [selectedType, setSelectedType] = useState<number>(0)
    const options = useMemo(() => {
            const result: IDropdownOption[] = []
            if (!state.data) return result
            state.data?.forEach(row => {
                if (!row.alleTenants && !row.bestandNoodzakelijk)
                    result.push({key: row.id, text: row.naam} as IDropdownOption)
            })
            return result
        }
        , [state.data]
    )

    const onSelect = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setUrl(`verwerking?top=20&typeid=${option.key}`)
            setSelectedType(option.key as number)
        }
    }

    const commandItems = [
        {text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true},
    ]

    const formatToDateTimeString = (date: Date | undefined) => {
        if (!date) return ''
        const parsedDate = new Date(date)
        return parsedDate.toLocaleDateString('nl-NL', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    const columns: IColumn[] = React.useMemo(() => {
        return [
            createColumn('ID', getPropertyName<IVerwerking>('id'), 'S', true),
            createColumn('Beschrijving', getPropertyName<IVerwerking>('beschrijving'), 'XL'),
            createCustomRenderedColumn('Gestart op', getPropertyName<IVerwerking>('gestartOp'), (x: IVerwerking) => <>{formatToDateTimeString(x.gestartOp)}</>, 'M', false, false),
            createCustomRenderedColumn('Gereed op', getPropertyName<IVerwerking>('gereedOp'), (x: IVerwerking) => <>{formatToDateTimeString(x.gereedOp)}</>, 'M', false, false),
            createColumn('Status', getPropertyName<IVerwerking>('status'), 'M'),
            createColumn('Foutmelding', getPropertyName<IVerwerking>('foutmelding'), 'M'),
        ]
    }, [])

    function onUitvoerenClick() {
        if (selectedType === 0) return
        executeVerwerking({verwerkingstypeId: selectedType})
        setTimeout(() => fetchVerwerking(), 1000);
    }

    return (
        <MainTemplate title="Verwerkingen" subTitle="Uitvoeren" commandItems={commandItems}>
            <div style={{marginLeft: 10, marginRight: 10}}>
                <Label>Verwerkingstype</Label>
                <Stack horizontal tokens={{childrenGap: 10}}>
                    <Dropdown style={{width: "400px"}} options={options} onChange={onSelect}/>
                    <DefaultButton onClick={onUitvoerenClick} text={"Uitvoeren"}/>
                </Stack>
                <Label style={{marginTop: 20}}>Laatste 20 verwerkingen</Label>
                <DetailsList items={verwerkingen.data?.items ?? []} columns={columns}/>
            </div>
        </MainTemplate>
    )
}

export default InvokeVerwerking