import React, { useMemo, useState } from 'react'
import {
  CommandBar,
  DetailsList,
  IColumn,
  ICommandBarItemProps, IObjectWithKey,
  Link,
  ScrollablePane,
  Selection,
  SelectionMode
} from '@fluentui/react'
import commandBarStyles from 'styles/commandBarStyles'
import { IVge } from 'features/vge/vgeSlice'
import { getPropertyName } from 'lib/interfaceUtils'
import { useBoolean } from '@fluentui/react-hooks'
import SelectAdressenPanel from './selectAdressenPanel'
import { createCommandButton } from 'lib/commandBarHelper'
import { createBooleanColumn, createColumn, createCustomRenderedColumn } from 'lib/gridHelper'
import { useNavigate } from 'react-router-dom'
import { PageIdDefinition, PAGES } from 'pages'
import { zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'

interface IGebouwdeelAdressenProps {
  isTerrein: boolean
  gebouwId: number
  gebouwdeelId: number
}

const GebouwdeelPercelen: React.FC<IGebouwdeelAdressenProps> = ({ gebouwdeelId, gebouwId, isTerrein }) => {
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const navigate = useNavigate()
  const [error, setError] = useState<string>()

  const { data: selectedGebouwen, invalidate: refetchSelectedGebouwen } = zodiosHooks.useGetGebouwendetailsId({
    params: {
      id: gebouwId
    }
  }, { enabled: gebouwId !== undefined })

  const { mutate: postVgesToGebouwdeel } = zodiosHooks.usePostGebouwdelenVge({},
    {
      onSuccess: refetchSelectedGebouwen,
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: deleteVgesFromGebouwdeel } = zodiosHooks.useDeleteGebouwdelenVge({},
    {
      onSuccess: refetchSelectedGebouwen,
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const gebouwdeel = selectedGebouwen?.gebouwDelen?.filter(v => v.id === gebouwdeelId)[0]

  const [selection] = React.useState(() => {
    const s = new Selection<IVge>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: item => item.id
    })

    return s
  })

  const AddToSelection = () => {
    openPanel()
  }

  const RemoveFromSelection = () => {
    if (selection.count > 0) {
      const items = selection.getSelection()
      deleteVgesFromGebouwdeel({ gebouwdeelId: gebouwdeel?.id ?? 0, vgeIds: items.map(v => v.key) as number[] })
    }
  }

  const onConnectVges = (items: number[]) => {
    postVgesToGebouwdeel({ gebouwdeelId: gebouwdeel?.id!, vgeIds: items })
  }

  const selectCommandBarItems: ICommandBarItemProps[] = useMemo(
    () => [createCommandButton('Koppelen', 'PlugConnected', AddToSelection), createCommandButton('Ontkoppelen', 'PlugDisconnected', RemoveFromSelection)],
    []
  )

  const onLinkClick = (id: string) => {
    navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', id.toString()))
  }

  const terreinColumns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srt'), 'XL', false),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl'), 'M', false),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<IVge>('str'),
      (item: IVge) => (
        <Link key={item.str} onClick={() => onLinkClick(item.id.toString())}>
          {item.str}
        </Link>
      ),
      250,
      false
    ),
    createColumn('Locatieaanduiding', getPropertyName<IVge>('locatieaanduiding'), 'L'),
    createColumn('Terreinclassificatie', getPropertyName<IVge>('trnNm'), 240, false),
    createColumn('Kadastrale aand. vlak', getPropertyName<IVge>('kadV'), 'L', false),
    createColumn('Grootte', getPropertyName<IVge>('grt'), 75, false),
    createColumn('Opmerking', getPropertyName<IVge>('opm'), 'L', false),
    createBooleanColumn('Historisch', getPropertyName<IVge>('hist'), 'S')
  ]

  const columnsOverigGebouwd: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srt'), 'XL', false),
    createColumn('Woonplaats', getPropertyName<IVge>('wpl'), 90),
    createCustomRenderedColumn(
      'Straat',
      getPropertyName<IVge>('str'),
      (item: IVge) => (
        <Link key={item.str} onClick={() => onLinkClick(item.id.toString())}>
          {item.str}
        </Link>
      ),
      'L',
      false
    ),
    createColumn('Adresaanduiding', getPropertyName<IVge>('aa'), 115),
    createColumn('Locatieaanduiding', getPropertyName<IVge>('locatieaanduiding'), 'L'),
    createColumn('Gebouwdeel', getPropertyName<IVge>('gdlCd'), 90),
    createCustomRenderedColumn('Verhuureenheid', getPropertyName<IVge>('vhes'), item => item[getPropertyName<IVge>('vhes')].join(', '), 110, false),
    createColumn('Gerelateerd', getPropertyName<IVge>('rel'), 'L'),
    createColumn('Beheerder', getPropertyName<IVge>('bn'), 175),
    createBooleanColumn('Historisch', getPropertyName<IVge>('hist'), 'S')
  ]

  const columns: IColumn[] = isTerrein ? terreinColumns : columnsOverigGebouwd

  return (
    <>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      <SelectAdressenPanel dismissPanel={dismissPanel} isOpen={isPanelOpen} onConnectVges={onConnectVges}
                           withoutAdres={true} isTerrein={isTerrein} />
      <ErrorMessageBar error={error} />
      <div style={{ height: '50vh', position: 'relative' }}>
        <ScrollablePane>
          <DetailsList
            columns={columns}
            selection={selection as Selection<IObjectWithKey>}
            styles={{ root: { fontSize: '14px' } }}
            items={
              isTerrein && gebouwdeel?.vgesZonderAdres
                ? [...gebouwdeel.vgesZonderAdres].filter(x => x.srt === 'TER')
                : gebouwdeel?.vgesZonderAdres
                  ? [...gebouwdeel.vgesZonderAdres]
                    .filter(x => x.srt !== 'TER')
                    .sort((a, b) => {
                      if (a.str === b.str) {
                        if (a.aa === b.aa) {
                          return 0
                        } else if (a.aa === null) {
                          return 1
                        } else if (b.aa === null) {
                          return -1
                        }
                        return a.aa ?? '' < (b.aa ?? '') ? -1 : 1
                      } else {
                        return a.str ?? '' < (b.str ?? '') ? -1 : 1
                      }
                    })
                  : []
            }
          ></DetailsList>
        </ScrollablePane>
      </div>
    </>
  )
}

export default GebouwdeelPercelen
