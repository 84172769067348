import { debounce } from 'lodash'
import { SearchBox, Stack, StackItem } from '@fluentui/react'
import React from 'react'

export function VgtSearch(props: Readonly<{ filter: string, onSearch: (filter: string) => void }>) {

  const delayedSearch = debounce(eventData => props.onSearch(eventData), 500)

  const onSearchBoxChanged = (newValue: string | undefined) => {
    if (newValue && newValue.length >= 2) {
      delayedSearch(newValue)
    } else {
      delayedSearch('')
    }
  }

  return <Stack horizontal tokens={{ childrenGap: 10 }}>
    <StackItem>
      <SearchBox styles={{ root: { width: '400px', margin: '6px' } }} placeholder='Zoeken in de lijst' onChange={(_, v) => onSearchBoxChanged(v)} onSearch={props.onSearch} defaultValue={props.filter} onClear={onSearchBoxChanged} />
    </StackItem>
  </Stack>
}