import { IconButton, IDatePickerProps, IStyle, mergeStyles, Stack, StackItem } from '@fluentui/react'
import React, { FC } from 'react'
import VgtDatePicker from './index'
import { IVgtFieldPropsBase } from '../../lib/IVgtFieldPropsBase'

export interface IVgtHorizontalDatePickerProps extends IDatePickerProps, IVgtFieldPropsBase {
  name: string
  label: string
  control: any
  register?: any
  errors?: any
  required?: boolean
  readOnly?: boolean
  onClear?: () => void
}

const dateToISOButLocal = (date: Date) => {
  return date.toLocaleString('sv').replace(' ', 'T')
}

export const convertToDateString = (date: any) => {
  const castDate = date as Date
  return dateToISOButLocal(castDate)
}

export const parseDate = (strDate: string | undefined) => {
  if (!strDate)
    return undefined
  return new Date(strDate)
}

const createStackClassName = () => {
  const stackStyle = {
    // borderBottomStyle: 'solid',
    // borderBottomWidth: 1,
    // borderBottomColor: 'rgb(219, 219, 219)',
    paddingLeft: 12,
    '& .datepicker-label': {
      paddingTop: 6,
      borderBottomStyle: 'initial',
      width: 208
    },
    ':hover': {
      borderBottomColor: 'black'
    },
    '& .ms-DatePicker': {
      borderBottomStyle: 'initial',
      width: '200px'
    },
    height: 34
  } as IStyle
  return mergeStyles(stackStyle)
}

const HorizonalDatePicker: FC<IVgtHorizontalDatePickerProps> = (props: IVgtHorizontalDatePickerProps) => {
  const classStack = createStackClassName()
  return (
    <Stack horizontal className={classStack}>
      <StackItem className='datepicker-label'>
        <label>{props.label}</label>
      </StackItem>
      <StackItem grow={1}>
        <VgtDatePicker {...props} />
      </StackItem>
      {props.onClear && (
        <StackItem>
          <IconButton aria-label='Wis de einddatum' iconProps={{ iconName: 'Cancel' }} onClick={props.onClear} />
        </StackItem>
      )}
    </Stack>
  )
}

export default HorizonalDatePicker
