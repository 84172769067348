import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import VgtTextField from 'components/VgtTextField'
import { getPropertyName } from 'lib/interfaceUtils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import {
  getLoadingState,
  IOnderhoudsspecialisme,
  OnderhoudsspecialismeSchema, getSelectedEntity, updateOnderhoudsspecialisme, addOnderhoudsspecialisme, selectAll
} from './onderhoudsspecialismeSlice'
import React, { useEffect, useState } from 'react'
import useRoles from 'services/UseRoles'
import { useWarningDialog } from 'components/WarningDialog'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
}

export const OnderhoudsspecialismeEditPanel: React.FC<IEditPanelProps> = props => {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState('')
  const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
  const fetchStatus = useSelector(getLoadingState)
  const selectedEntity = useSelector(getSelectedEntity)
  const items = useSelector(selectAll)
  const { isAdmin } = useRoles()
  const [WarningDialog, setDialogSubText, showDialog] = useWarningDialog()

  useEffect(() => {
    setCloseOnFulFilled(false)
  }, [])

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<IOnderhoudsspecialisme>({ resolver: yupResolver(OnderhoudsspecialismeSchema), mode: 'all' })

  useEffect(() => {
    setTitle(`Onderhoudsspecialisme ${selectedEntity ? isAdmin ? 'wijzigen' : '' : 'toevoegen'}`)
    if (selectedEntity) {
      reset(selectedEntity)
    } else {
      reset({})
    }
  }, [selectedEntity])

  const onCancel = () => {
    props.dismissPanel()
  }

  const doesCodeExistInOnderhoudsspecialismenItems = (data: IOnderhoudsspecialisme) => {
    return items.some(onderhoudsspecialismen => onderhoudsspecialismen.code === data.code)
  }
  const onSubmit = (data: IOnderhoudsspecialisme) => {
    if (selectedEntity) {
      data.onderhoudsspecialismeId = selectedEntity.onderhoudsspecialismeId
      dispatch(updateOnderhoudsspecialisme(data))
      setCloseOnFulFilled(true)
    } else {
      if (doesCodeExistInOnderhoudsspecialismenItems(data)) {
        setDialogSubText('Onderhoudsspecialiteiten Code bestaat al.')
        showDialog(true)
      } else {
        dispatch(addOnderhoudsspecialisme(data))
        setCloseOnFulFilled(true)
      }
    }
  }

  useEffect(() => {
    if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel()
  }, [fetchStatus])

  return (
    <div>
      {WarningDialog}
      <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField label='Code' name={getPropertyName<IOnderhoudsspecialisme>('code')} register={register}
                        control={control} errors={errors} required={isAdmin} readOnly={!isAdmin} />
          <VgtTextField label='Naam' name={getPropertyName<IOnderhoudsspecialisme>('naam')} register={register}
                        control={control} errors={errors} required={isAdmin} multiline autoAdjustHeight
                        readOnly={!isAdmin} />
          <br />
          {isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text='Opslaan' type='submit' primary disabled={isSubmitting}
                             onClick={handleSubmit(onSubmit)} />
              <DefaultButton text='Annuleren' onClick={onCancel} />
            </Stack> : null}
        </form>
      </Panel>
    </div>
  )
}
export default OnderhoudsspecialismeEditPanel