import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'

export interface IVerwerkingsRegel {
  id: number
  verwerkingNaam: string
  verwerkingId: number
  kadastraalobjectId: string
  vastgoedeenheidId: number
  verblijfsobjectId: string
  details: string
  objectType: string
  logTypeId: number
  actieId: number
  bestandUrl: string
}

const entityAdapter = createEntityAdapter<IVerwerkingsRegel>({})

export const entityAdapterInitState: IEntityAdapterState = {
  selectedId: undefined,
  status: 'idle',
  error: null,
  searchFilter: ''
}
const baseUrl = '/verwerking/regels'
const basePrefix = 'verwerkingen'
const getSliceState = (state: RootState) => state.verwerkingsregels

export interface IVerwerkingsFilter {
  verwerkingen?: string
  logTypes?: string
  actieIds?: string
  objectType?: string
  detailsFilter?: string
  verblijfsobjectId?: string
  kadastraalObjectId?: string
  vastgoedeenheidId?: string
}

const serializeObjectToQueryString = (obj: IVerwerkingsFilter) => {
  const keys = Object.keys(obj)
  let queryString = ''
  for (let i = 0; i < keys.length; i++) {
    if (obj[keys[i]]) {
      queryString += `${keys[i]}=${obj[keys[i]]}&`
    }
  }

  return queryString
}

export const fetchQuery = createAsyncThunk(`${basePrefix}/fetchStatus`, async (filter: IVerwerkingsFilter, thunkAPI) => {
  // serialize filter to uri
  let url = `${baseUrl}?verwerkingen=${filter.verwerkingen}&top=50000& ${serializeObjectToQueryString(filter)}`
  return await invokeFetch<PagedEntities<IVerwerkingsRegel>>(thunkAPI, 'GET', encodeURI(url))
})

const setPendingSate = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededSate = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const verwerkingQuery = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
  },

  extraReducers: builder => {
    builder.addCase(fetchQuery.pending, state => setPendingSate(state))
    builder.addCase(fetchQuery.fulfilled, (state, action) => {
      setSucceededSate(state)
      entityAdapter.setAll(state, action.payload.items)
      state.searchFilter = action.meta.arg.detailsFilter
    })
    builder.addCase(fetchQuery.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getRecordsFound = (state: RootState) => {
  return getSliceState(state).ids.length
}

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export default verwerkingQuery.reducer
