import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from '@fluentui/react'
import React from 'react'
import useRoles from '../../../services/UseRoles'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { SleuteltypeAddOrUpdateDto, zodiosHooks } from '../../../api/ApiClient'
import VgtTextField from '../../../components/VgtTextField'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { getTitleAndMessage } from '../../../services/HandleError'
import { z } from 'zod'
import VgtDropdown from '../../../components/FluentDropdown'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'

export interface SleutelPanelProps {
  sleuteltypeId: number,
  isOpen: boolean,
  dismissPanel: any,
  refreshTable: () => void,
}

export type SleuteltypeDtoType = z.infer<typeof SleuteltypeAddOrUpdateDto>;

export const SleuteltypeEdit: React.FC<SleutelPanelProps> = ({ sleuteltypeId, dismissPanel, isOpen, refreshTable }) => {
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultSleutel = {
    inspectietaakId: null,
    omschrijving: ''
  } as SleuteltypeDtoType

  const {
    control,
    handleSubmit,
    reset,
    register,
    formState: { isSubmitting, errors }
  } = useForm<SleuteltypeDtoType>({
    resolver: zodResolver(SleuteltypeAddOrUpdateDto), mode: 'all', defaultValues: defaultSleutel
  })

  const { data: inspectietaken } = zodiosHooks.useGetApiInspectietaak({})

  const inspectietaakOpties = inspectietaken?.items?.map((item) => {
    return {
      key: item.inspectietaakId,
      text: item.taak
    } as IDropdownOption
  })

  const { data } = zodiosHooks.useGetSleuteltypeId(
    {
      params: { id: sleuteltypeId }
    }, { onSuccess: (data) => reset(data), enabled: sleuteltypeId !== undefined }
  )

  const { mutate: addSleuteltype } = zodiosHooks.usePostSleuteltype(
    {}, {
      onSuccess: () => onCancel, onError: (error) => setError(getTitleAndMessage(error).message),
      onSettled: () => {
        refreshTable()
      }
    }
  )

  const { mutate: updateSleuteltype } = zodiosHooks.usePutSleuteltypeId(
    {
      params: { id: sleuteltypeId }
    }, {
      onSuccess: () => onCancel, onError: (error) => setError(getTitleAndMessage(error).message),
      onSettled: () => {
        refreshTable()
      }
    }
  )

  const { data: FontIcoonOptions } = zodiosHooks.useGetApiFonticoon()

  const fontIcons = FontIcoonOptions?.items?.filter(item => item.naam !== null)
    .map(item => ({ key: item.fonticoonId, text: item.omschrijving! }))

  const onCancel = () => {
    dismissPanel()
  }

  const onSubmit = (data: SleuteltypeDtoType) => {
    if (sleuteltypeId !== undefined) {
      updateSleuteltype(data)
    } else {
      addSleuteltype(data)
    }
    dismissPanel()
  }

  return (
    <div>
      <Panel type={PanelType.medium} headerText={'Sleuteltype'} isOpen={isOpen} onDismiss={onCancel}
             closeButtonAriaLabel="Sluiten">
        <br />
        <ErrorMessageBar error={error} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtTextField
            label="Omschrijving"
            name={getPropertyName<SleuteltypeDtoType>('omschrijving')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isAdmin}
            defaultValue={data?.omschrijving ?? ''}
          />
          <VgtDropdown label="Inspectietaak" name={getPropertyName<SleuteltypeDtoType>('inspectietaakId')}
                       control={control} options={inspectietaakOpties} errors={errors}
                       required={true}
          />
          <VgtDropdown label="Fonticoon" name={getPropertyName<SleuteltypeDtoType>('fonticoonId')}
                       register={register} control={control} errors={errors} required options={fontIcons} />
          <br />
          {isAdmin ?
            <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
              <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} />
              <DefaultButton text="Annuleren" onClick={onCancel} />
            </Stack> : null
          }
        </form>
      </Panel>
    </div>
  )
}