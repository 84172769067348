import {
  DetailsList,
  IColumn,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Sticky,
  StickyPositionType
} from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import { fetchAll, IGebouwOnderhoudsbedrijf, select, selectAll } from './gebouwOnderhoudsbedrijfSlice'
import { selectAll as selectAllRechtspersonen } from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import { useBoolean } from '@fluentui/react-hooks'
import { GebouwOnderhoudsbedrijfEditPanel } from './editPanel'

export interface IGebouwOnderhoudsbedrijfProps {
  gebouwId: number
}

const GebouwOnderhoudsbedrijfList: React.FC<IGebouwOnderhoudsbedrijfProps> = (props: IGebouwOnderhoudsbedrijfProps) => {
  const dispatch = useAppDispatch()
  const items = useSelector(selectAll)
  const refreshItems = () => dispatch(fetchAll({ gebouwId: props.gebouwId }))
  const rechtspersonen = useSelector(selectAllRechtspersonen)
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)

  useEffect(() => {
    refreshItems()
  }, [])

  useEffect(() => {
    if (!isPanelOpen) refreshItems()
  }, [isPanelOpen])

  const handleItemInvoked = (id: string) => {
    dispatch(select(id))
    openPanel()
  }

  const renderOnderhoudsbedrijf = (rechtpersoonId: number) => {
    return <>{rechtspersonen.find(x => x.id === rechtpersoonId)?.handelsnaam}</>
  }

  const columns: IColumn[] = [
    createCustomRenderedColumn('Type onderhoudsbedrijf', getPropertyName<IGebouwOnderhoudsbedrijf>('typeringNaam'), (x: IGebouwOnderhoudsbedrijf) =>
      <Link key={x.onderhoudsbedrijfTyperingId}
            onClick={() => handleItemInvoked(x.onderhoudsbedrijfTyperingId.toString())}>{x.typeringNaam}</Link>, 'XXL', false),
    createCustomRenderedColumn('Onderhoudsbedrijf', getPropertyName<IGebouwOnderhoudsbedrijf>('handelsNaam'), (x: IGebouwOnderhoudsbedrijf) => renderOnderhoudsbedrijf(x.rechtspersoonId), 'XXL', false),
    createCustomRenderedColumn('Email-adres', getPropertyName<IGebouwOnderhoudsbedrijf>('emailadres'), (x: IGebouwOnderhoudsbedrijf) =>
      <span>{x.emailadres}</span>, 'XXL', false),
    createCustomRenderedColumn('Telefoonnummer', getPropertyName<IGebouwOnderhoudsbedrijf>('telefoonnummer'), (x: IGebouwOnderhoudsbedrijf) =>
      <span>{x.telefoonnummer}</span>, 'XXL', false),
    createCustomRenderedColumn('Contactpersoon', getPropertyName<IGebouwOnderhoudsbedrijf>('contactpersoon'), (x: IGebouwOnderhoudsbedrijf) =>
      <span>{x.contactpersoon}</span>, 'XXL', false)

  ]

  return (
    <div style={{ height: '78.7vh', position: 'relative' }}>
      <GebouwOnderhoudsbedrijfEditPanel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
        gebouwId={props.gebouwId}
      />
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          columns={columns}
          items={items}
          selectionMode={SelectionMode.none}
          onRenderDetailsHeader={(headerProps, defaultRender) => {
            return (
              <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}
                      stickyBackgroundColor="transparent">
                <div>{defaultRender && defaultRender(headerProps)}</div>
              </Sticky>
            )
          }}
        />
      </ScrollablePane>
    </div>
  )
}

export default GebouwOnderhoudsbedrijfList