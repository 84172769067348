import { ITextFieldStyles, Link, TextField } from "@fluentui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const labelStyles: Partial<ITextFieldStyles> = {
    wrapper: {
        borderBottom: '1px solid #efefef',
    },
    subComponentStyles: {
        label: {
            root: { width: 350, fontWeight: 'normal' },
        },
    },
    field: {
        fontWeight: 600,
    },
    suffix: {
        background: '',
    },
}

interface IReadonlyFieldProps {
    title: string;
    value: any;
}
interface ILinkReadonlyFieldProps {
    title: string;
    value?: any;
    url: string | null;
}
export const LinkReadOnlyField: FC<ILinkReadonlyFieldProps> = (props: ILinkReadonlyFieldProps) => {
    const navigate = useNavigate();

    const onclick = () => {
        if (props.url)
            navigate(props.url);
    };

    return (
        <Link onClick={() => onclick()}>
            <TextField
                autoComplete="off"
                styles={labelStyles}
                label={props.title}
                underlined
                readOnly
                value={props.value}
                placeholder={'---'}
                style={{ cursor: 'pointer' }} />
        </Link>
    );
};
export const ReadOnlyField: FC<IReadonlyFieldProps> = (props: IReadonlyFieldProps) => {
    return <TextField autoComplete="off" styles={labelStyles} label={props.title} underlined readOnly value={props.value} placeholder={'---'} />;
};
