import { IColumn, SearchBox, SelectionMode, Link } from "@fluentui/react"
import { getPropertyName } from "lib/interfaceUtils"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "store"
import { createCustomRenderedColumn, createColumn } from "lib/gridHelper"
import { clearSelection, deleteDefectoorzaak, fetchAll, getLoadingState, IDefectoorzaak, select, selectAll } from "./defectoorzaakSlice"
import MainTemplate from "containers/pages/PageTemplates/MainTemplate"
import FluentTable from "components/FluentTable"
import { DefectoorzaakEditPanel } from "./editPanel"
import { useBoolean } from "@fluentui/react-hooks"
import useRoles from "services/UseRoles";

const DefectoorzaakList: React.FC<{}> = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const items = useSelector(selectAll)
    const loadingState = useSelector(getLoadingState)
    const [filter, setFilter] = useState("")
    const refreshItems = () => dispatch(fetchAll({ filter: filter }))
    const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
    const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
    const { isAdmin } = useRoles()

    useEffect(() => {
        refreshItems()
    }, []);

    const handleItemInvoked = (id) => {
        dispatch(select(id))
        openPanel()
    }
    
    const commandItems = useMemo(() => [
        { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
        { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
        { visible: isAdmin, text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); openPanel() }, icon: 'Add'},
        { visible: isAdmin, text: 'Verwijderen', onClick: () => 
                dispatch(
                    deleteDefectoorzaak(
                        selectedItems.map(value => parseInt(value))
                    )
                )
            , icon: 'Delete', disabled: !selectedItems.length },
    ], [isAdmin, selectedItems.length])

    const renderBeschrijving = (x: IDefectoorzaak) => <>{x.omschrijving}</>
    const renderCode = (x: IDefectoorzaak) => <Link key={x.defectoorzaakId} onClick={() => handleItemInvoked(x.defectoorzaakId.toString())}>{x.code}</Link>

    const columns: IColumn[] = [
        createCustomRenderedColumn('Code', getPropertyName<IDefectoorzaak>('code'), renderCode, 'M', false),
        createColumn('Naam', getPropertyName<IDefectoorzaak>('naam'), 'XL'),
        createCustomRenderedColumn('Omschrijving', getPropertyName<IDefectoorzaak>('omschrijving'), renderBeschrijving, 'XXL', false, true),
    ]

    return (<>
        <MainTemplate title="Defectoorzaak" subTitle="Overzicht" commandItems={commandItems}>
            <DefectoorzaakEditPanel
                dismissPanel={dismissPanel}
                isOpen={isPanelOpen}
            />
            <SearchBox placeholder='Voer een tekst in om te filteren' styles={{ root: { width: '400px', margin: '6px' } }}
                value={filter}
                onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                onSearch={() => refreshItems()}
            />

             <div style={{ height: '77vh' }}>
                 <FluentTable 
                    columns={columns} 
                    items={items} 
                    loading={loadingState === 'pending'} 
                    selectionMode={SelectionMode.multiple}
                    onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
                    onGetKey={(item: IDefectoorzaak) => item.defectoorzaakId.toString()}
                    onItemInvoked={(id: string) => {handleItemInvoked(id)}}
                />
            </div>
        </MainTemplate>
    </>)
}

export default DefectoorzaakList