import React, { useMemo, useState } from 'react'
import { IColumn, Link, SearchBox, SelectionMode } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import FluentTable from 'components/FluentTable'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelRuimteSoorten from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import { createBooleanColumn } from 'lib/gridHelper'
import ICoMoonFontIcon from '../../../../components/IcoMoon'
import { CoenCadRuimteSoortDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'

type CoenCadRuimteSoortDtoType = z.infer<typeof CoenCadRuimteSoortDto>;

const RuimteSoortenList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const { validatedSearchParams, setFilter } = usePagedParams()
  const { isAdmin } = useRoles()
  const [selectedRuimteSoort, setSelectedRuimteSoort] = useState<CoenCadRuimteSoortDtoType | undefined>()
  const [error, setError] = useState<string>()

  const {
    data: ruimteSoort,
    invalidate: fetchRuimteSoort,
    isLoading: isFetchingRuimteSoort
  } = zodiosHooks.useGetRuimteSoort({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteRuimteSoort } = zodiosHooks.useDeleteRuimteSoort({}, {
    onSuccess: () => fetchRuimteSoort(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const handleListSelectionChanged = (ids: string[]) => setSelectedItems(ids)
  const refreshItems = () => fetchRuimteSoort()
  const onGetKey = (item: CoenCadRuimteSoortDtoType) => item.id.toString()

  const handleItemInvoked = (id: string) => {
    setSelectedRuimteSoort(ruimteSoort?.items?.find(item => item.id === parseInt(id)))
    openPanel()
  }

  const removeItems = () => {
    const ids = selectedItems.map(value => parseInt(value))
    deleteRuimteSoort(ids)
    setSelectedItems([])
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<CoenCadRuimteSoortDtoType>('code'),
      name: 'Code',
      fieldName: getPropertyName<CoenCadRuimteSoortDtoType>('code'),
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isRowHeader: true,
      data: 'string',
      onRender: (item: CoenCadRuimteSoortDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item.id.toString())}>
          {item.code}
        </Link>
      )
    },
    {
      key: getPropertyName<CoenCadRuimteSoortDtoType>('omschrijving'),
      name: 'Omschrijving',
      fieldName: getPropertyName<CoenCadRuimteSoortDtoType>('omschrijving'),
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    {
      key: getPropertyName<CoenCadRuimteSoortDtoType>('defectLocatieNaam'),
      name: 'Defectlocatie',
      fieldName: getPropertyName<CoenCadRuimteSoortDtoType>('defectLocatieNaam'),
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isRowHeader: true,
      data: 'string'
    },
    createBooleanColumn('Woningwaardering', getPropertyName<CoenCadRuimteSoortDtoType>('wwd'), 'L'),
    createBooleanColumn('Vertrek', getPropertyName<CoenCadRuimteSoortDtoType>('vertrek'), 'S'),
    createBooleanColumn('Overige ruimte', getPropertyName<CoenCadRuimteSoortDtoType>('overigeRuimte'), 'M'),
    createBooleanColumn('Buitenruimte', getPropertyName<CoenCadRuimteSoortDtoType>('buitenRuimte'), 'M'),
    createBooleanColumn('Verkeersruimte', getPropertyName<CoenCadRuimteSoortDtoType>('verkeersruimte'), 'M'),

    {
      key: getPropertyName<CoenCadRuimteSoortDtoType>('aantal'),
      name: 'Aantal',
      fieldName: getPropertyName<CoenCadRuimteSoortDtoType>('aantal'),
      minWidth: 50,
      maxWidth: 50,
      data: 'number'
    },
    {
      key: 'fonts-icon',
      name: 'Fonticoon',
      fieldName: getPropertyName<CoenCadRuimteSoortDtoType>('fonticoonId'),
      minWidth: 70,
      maxWidth: 70,
      isResizable: false,
      isRowHeader: false,
      onRender: (item: CoenCadRuimteSoortDtoType) => <ICoMoonFontIcon iconName={item?.fonticoonNaam!} />
    }
  ]

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      visible: isAdmin, text: 'Toevoegen', onClick: () => {
        setSelectedRuimteSoort(undefined)
        openPanel()
      }, icon: 'Add'
    },
    {
      visible: isAdmin,
      text: 'Verwijderen',
      onClick: () => removeItems(),
      icon: 'Delete',
      disabled: !selectedItems.length
    }
  ], [isAdmin, selectedItems.length])

  return (
    <MainTemplate title='Ruimtesoorten' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder='Filter' styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => fetchRuimteSoort()} />
      <EditPanelRuimteSoorten selectedRuimteSoort={selectedRuimteSoort} fetchRuimteSoort={fetchRuimteSoort}
                              dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <FluentTable
        height='81vh'
        columns={columns}
        items={ruimteSoort?.items ?? []}
        loading={isFetchingRuimteSoort}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={handleListSelectionChanged}
        onGetKey={onGetKey}
        onItemInvoked={handleItemInvoked}
      />
    </MainTemplate>
  )
}
export default RuimteSoortenList
