import { DefaultButton, IDropdownOption, Panel, PanelType, Stack } from "@fluentui/react"
import { getPropertyName } from "lib/interfaceUtils"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import {
    getLoadingState,
    IGebouwOnderhoudsbedrijf,
    GebouwOnderhoudsbedrijfSchema, getSelectedEntity, updateGebouwOnderhoudsbedrijf, addGebouwOnderhoudsbedrijf } from "./gebouwOnderhoudsbedrijfSlice"
import React, { useEffect, useState } from "react"
import { selectAll as selectAllRechtspersonen } from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import VgtDropdown from "components/FluentDropdown"
import VgtTextField from "components/VgtTextField"

interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
    gebouwId: number
}

export const GebouwOnderhoudsbedrijfEditPanel: React.FC<IEditPanelProps> = (props: IEditPanelProps) => {
    const dispatch = useAppDispatch()
    const [title, setTitle] = useState('')
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const fetchStatus = useSelector(getLoadingState)
    const selectedEntity = useSelector(getSelectedEntity)
    const rechtspersonen = useSelector(selectAllRechtspersonen)

    useEffect(() => {
        setCloseOnFulFilled(false)
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<IGebouwOnderhoudsbedrijf>({ resolver: yupResolver(GebouwOnderhoudsbedrijfSchema), mode: 'all' })

    useEffect(() => {
        setTitle(`Gebouw Onderhoudsbedrijf ${selectedEntity ?  'wijzigen' : 'toevoegen'}`)
        if (selectedEntity) {
            reset(selectedEntity)
        } else {
            reset({})
        }
    }, [selectedEntity])

    const onCancel = () => { props.dismissPanel() }

    const getOnderhoudsbedrijfAsOptions = () => {
        const nullOption = { key: 0, text: '---' } as IDropdownOption
        const leverancierRechtspersonenOptions = rechtspersonen.filter(x => x.rolId === 1).map(x => ({ key: x.id, text: `${x.handelsnaam}` })) as IDropdownOption[]
        return [nullOption, ...leverancierRechtspersonenOptions]
    }

    const onSubmit = (data: IGebouwOnderhoudsbedrijf) => {
        if (selectedEntity) {
            data.onderhoudsbedrijfTyperingId = selectedEntity.onderhoudsbedrijfTyperingId
            data.gebouwId = props.gebouwId
           if (selectedEntity.rechtspersoonId === null || selectedEntity.rechtspersoonId === undefined)
            {
                dispatch(addGebouwOnderhoudsbedrijf(data))
            }
            else
            {
                dispatch(updateGebouwOnderhoudsbedrijf(data))
            }
            onCancel()
        }
    }

    useEffect(() => {
        if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel()
    }, [fetchStatus])

    return (
        <div>
            <Panel type={PanelType.medium} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VgtTextField label="Type onderhoudsbedrijf"  name={getPropertyName<IGebouwOnderhoudsbedrijf>('typeringNaam')} register={register} control={control} errors={errors} readOnly/>
                    <VgtDropdown
                        options={getOnderhoudsbedrijfAsOptions()}
                        label='Onderhoudsbedrijf'
                        name={getPropertyName<IGebouwOnderhoudsbedrijf>('rechtspersoonId')}
                        register={register}
                        control={control}
                        errors={errors}
                    />
                    <br />
                    <Stack horizontal horizontalAlign={'end'} tokens={{ childrenGap: '12 12' }}>
                        <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
                        <DefaultButton text="Annuleren" onClick={onCancel} />
                    </Stack>
                </form>
            </Panel>
        </div>
    )
}
