import {
  DefaultButton,
  ISpinnerStyles,
  IStackStyles,
  SearchBox,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import downloadFile from 'services/downloadFile'
import DeleteWarningDialog from './deleteWarningDialog'
import GebouwenClusterRow from './gebouwenClusterRow'
import { zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'
import { GebouwenClusterEditPanel } from './editGebouwenClusterPanel'
import { useBoolean } from '@fluentui/react-hooks'

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '5px',
    overflow: 'auto'
  }
}

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

type sortOrder = {
  code: 'asc' | 'desc';
  naam: 'asc' | 'desc';
};

const GebouwenClustersList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setFilter, setPage, setOrder } = usePagedParams()
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)
  const [sortOrder, setSortOrder] = useState<sortOrder>({ code: 'asc', naam: 'asc' })

  const toggleOrder = (column: 'code' | 'naam') => {
    setSortOrder((prevOrder) => ({
      ...prevOrder,
      [column]: prevOrder[column] === 'asc' ? 'desc' : 'asc'
    }))
  }

  useEffect(() => {
    setOrder('code', 'asc')
  }, [])

  const { data: gebouwenClusters, invalidate: fetchGebouwenClusters } = zodiosHooks.useGetGebouwenClustersdetails({
    queries: {
      Filter: validatedSearchParams.filter,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize
    }
  })
  const { data: gebouwens } = zodiosHooks.useGetGebouwen()

  const handleListSelectionChanged = (id: number) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter(item => item !== id)
      } else {
        return [...prevSelectedItems, id]
      }
    })
  }

  const [hideDialog, setHideDialog] = useState<boolean>(true)

  function exportVastgoed() {
    downloadFile('/vastgoed/export', 'vastgoeddata.xlsx')
  }

  const hasGebouwenLinkedToSelectedClusters = () => {
    return selectedItems.some(clusterId => gebouwens?.items?.some(gebouw => gebouw.gebouwenClusterId === clusterId))
  }

  const { mutate: deleteGebouwenClusters } = zodiosHooks.useDeleteGebouwenClusters({}, {
    onSuccess: () => fetchGebouwenClusters(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const removeItems = () => {
    if (hasGebouwenLinkedToSelectedClusters()) {
      setHideDialog(false)
    } else {
      deleteGebouwenClusters(selectedItems)
      setSelectedItems([])
    }
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchGebouwenClusters(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        openEditPanel()
      }, icon: 'Add'
    },
    { text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length },
    { text: 'Exporteren vastgoed', onClick: () => exportVastgoed(), icon: 'Export', disabled: !selectedItems.length }
  ], [selectedItems.length])

  return (
    <MainTemplate title='Gebouwenclusters' subTitle='Overzicht' commandItems={commandItems}>
      <GebouwenClusterEditPanel fetchData={fetchGebouwenClusters} isOpen={inEditMode} dismissPanel={dismissEditPanel} />
      <SearchBox placeholder='Filter' styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => fetchGebouwenClusters()} />
      <DeleteWarningDialog onCancel={() => setHideDialog(true)} hideDialog={hideDialog} />
      <ErrorMessageBar error={error} />
      <div style={{ overflow: 'auto', marginBottom: 5 }}>
        <Stack styles={stackStyles}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
            <span style={{ width: 100 }}></span>
            <Text style={{ width: 150, fontWeight: '600', cursor: 'pointer' }} onClick={() => {
              toggleOrder('code')
              setOrder('code', sortOrder.code === 'asc' ? 'desc' : 'asc')
            }}>
              Gebouwenclustercode
            </Text>
            <Text style={{ width: 275, fontWeight: '600', cursor: 'pointer' }} onClick={() => {
              toggleOrder('naam')
              setOrder('naam', sortOrder.naam)
            }}>
              Gebouwenclusternaam
            </Text>
          </div>
          {gebouwenClusters ? gebouwenClusters?.items?.map((item, index) =>
            <GebouwenClusterRow key={item.gebouwClusterId} index={index} item={item}
                                handleItemSelectionChanged={handleListSelectionChanged} />
          ) : <Spinner size={SpinnerSize.large} styles={spinnerStyles} />}
        </Stack>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
        <div>
          <DefaultButton iconProps={{ iconName: 'previous' }} disabled={!gebouwenClusters?.hasPreviousPage}
                         onClick={() => setPage(validatedSearchParams.page - 1)} />
        </div>
        <div style={{ paddingLeft: 12, paddingTop: 4 }}>
          <Text>{gebouwenClusters?.totalCount} resultaten.
            Pagina {(validatedSearchParams.page).toString()} van {gebouwenClusters?.totalPages}</Text>
        </div>
        <div style={{ paddingLeft: 12 }}>
          <DefaultButton iconProps={{ iconName: 'next' }} disabled={!gebouwenClusters?.hasNextPage}
                         onClick={() => setPage(validatedSearchParams.page + 1)} />
        </div>
      </div>
    </MainTemplate>
  )
}
export default GebouwenClustersList
