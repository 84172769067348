import React from 'react'
import { mergeStyleSets, Text } from '@fluentui/react'

interface IInputCardProps {
  title?: string
  boldTitle?: boolean
  className?: string
  width?: string
  height?: string
  marginTop?: string
  icon?: string
  children?: React.ReactNode,
  contentPaddingLeft?: string | number,
}

const InputCard: React.FC<IInputCardProps> = ({
                                                title,
                                                boldTitle,
                                                className,
                                                children,
                                                width,
                                                height,
                                                marginTop,
                                                icon,
                                                contentPaddingLeft
                                              }) => {

  const classNames = mergeStyleSets({
    inputCardContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100% - 10px',
      marginTop: icon ? '-45px' : '0px',
      paddingLeft: contentPaddingLeft
    },
    inputCardTitle: {
      marginBlockEnd: '10px',
      fontWeight: boldTitle ? 'bold' : 'normal'
    },
    inputCardTitleBold: {
      marginBlockEnd: '10px'
    },
    inputCard: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexShrink: 0,
      border: `1px solid #efefef`,
      boxShadow: '#efefef 0px 0px 9px -1px',
      width: width ?? '398px',
      height: height ?? 'auto',
      padding: '4px 14px 24px',
      boxSizing: 'border-box',
      marginBottom: 0,
      marginTop: marginTop ?? '0px'
    }
  })

  return (

    <div className={`${classNames.inputCard} ${className}`}>
      <Text variant='large' className={classNames.inputCardTitle}>
        {title}
      </Text>
      {
        icon ?
          <img alt='' style={{
            height: '40px',
            width: '40px',
            zIndex: 1,
            position: 'relative',
            left: '93%',
            top: '-38px'
          }}
               src={icon} />
          : null
      }
      <div className={classNames.inputCardContent}>{children}</div>
    </div>
  )
}

export default InputCard
