import { z } from 'zod'
import { DefectoorzaakKostensoortListDto, zodiosHooks } from '../../../../api/ApiClient'
import { useNavigate } from 'react-router-dom'
import { usePagedParams } from '../../../../services/usePagedParams'
import { useBoolean } from '@fluentui/react-hooks'
import React, { useMemo, useState } from 'react'
import useRoles from '../../../../services/UseRoles'
import { getTitleAndMessage } from '../../../../services/HandleError'
import MainTemplate from '../../../../containers/pages/PageTemplates/MainTemplate'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link } from '@fluentui/react'
import { createColumn } from '../../../../lib/gridHelper'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { DefectoorzaakKostensoortFormPanel } from './DefectoorzaakKostensoortFormPanel'

type DefectoorzaakKostensoortListDtoType = z.infer<typeof DefectoorzaakKostensoortListDto>;

export const DefectoorzaakKostensoortList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const { isAdmin } = useRoles()
  const [error, setError] = React.useState<string>()
  const [selectedDefectoorzaakKostensoort, setSelectedDefectoorzaakKostensoort] = useState<DefectoorzaakKostensoortListDtoType | undefined>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetDefectoorzaakKostensoort({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' },
  ], [isAdmin])

  const handleItemInvoked = (item: DefectoorzaakKostensoortListDtoType) => {
    setSelectedDefectoorzaakKostensoort(item)
    openPanel()
  }

  const handleDismissPanel = () => {
    setSelectedDefectoorzaakKostensoort(undefined)
    dismissPanel()
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Defectoorzaak', getPropertyName<DefectoorzaakKostensoortListDtoType>('defectoorzaak'), 'XL', true,
        (item: DefectoorzaakKostensoortListDtoType) => (
          <Link key={item.defectoorzaakId} onClick={() => handleItemInvoked(item)}>
            {item.defectoorzaak}
          </Link>
        )),
      createColumn('Kostensoort', getPropertyName<DefectoorzaakKostensoortListDtoType>('kostensoort'), 'XXL', true)
    ]
  }, [])

  return (
    <MainTemplate title="Defectoorzaken || Kostensoorten" subTitle="overzicht" commandItems={commandItems}
                  error={error}>
      <DefectoorzaakKostensoortFormPanel defectoorzaakId={selectedDefectoorzaakKostensoort?.defectoorzaakId ?? 0} kostensoortId={selectedDefectoorzaakKostensoort?.kostensoortId!}
                                         dismissPanel={handleDismissPanel} isOpen={isPanelOpen}
                                         invalidate={invalidate} />
      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.defectoorzaakId}
        onItemInvoked={(e) => handleItemInvoked(e)}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />

    </MainTemplate>
  )
}
