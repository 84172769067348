import {
  DefaultButton,
  DetailsRow,
  IColumn, IDetailsListProps,
  IDetailsRowStyles, Selection,
  SelectionMode, ShimmeredDetailsList,
  Text
} from '@fluentui/react'
import React, { useState } from 'react'

export interface VgtPagedTableProps<T> {
  isLoading: boolean
  height?: string
  items?: T[]
  columns: IColumn[]
  pagingInfo: PagedSearch
  getKey: (item: T) => number
  onPageChanged: (page: number) => void
  onSortChanged: (key: string, direction: 'asc' | 'desc') => void
  onItemInvoked?: (item: T) => void

  onSelectionChanged?: (selection: number[]) => void
}

export interface PagedSearch {
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  totalCount: number
  totalPages: number
  pageSize: number
  filter?: string
  sortKey?: string
  sortDirection?: 'asc' | 'desc'
}

const onRenderRow: IDetailsListProps['onRenderRow'] = props => {
  const customStyles: Partial<IDetailsRowStyles> = {}
  if (props) {
    if (props.itemIndex % 2 === 0) {
      customStyles.root = { backgroundColor: '#f7f7f7' }
    } else {
      customStyles.root = { backgroundColor: '#ffffff' }
    }

    return <DetailsRow {...props} styles={customStyles} />
  }
  return null
}

export function VgtPagedTable<T>(props: VgtPagedTableProps<T>) {

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => selectionChangedHandler()
    })
  )

  const selectionChangedHandler = () => {
    if (props.onSelectionChanged) {
      const sel = selection.getSelection() as T[]

      const ids = sel.map((item) => props.getKey(item))
      props.onSelectionChanged(ids)
    }
  }

  const setSortingOnColumns = (columns: IColumn[]) => {
    const { sortKey, sortDirection } = props.pagingInfo
    return columns.map((column) => {
      if (column.fieldName === sortKey) {
        column.isSorted = true
        column.isSortedDescending = sortDirection === 'desc'
      } else {
        column.isSorted = false
        column.isSortedDescending = false
      }
      return column
    })
  }

  return (
    <>
      <div style={{
        height: props.height || '100%',
        overflow: 'auto',
        // display: 'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'center',
        // alignContent: 'center',
        width: '100%'
      }}>
        <ShimmeredDetailsList
          items={props.items || []}
          columns={setSortingOnColumns(props.columns)}
          selectionMode={SelectionMode.multiple}
          selection={selection}
          compact={true}
          onRenderRow={onRenderRow}
          getKey={(item: T) => props.getKey(item)?.toString()}
          onItemInvoked={props.onItemInvoked}
          enableShimmer={props.isLoading}
          shimmerLines={props.pagingInfo.pageSize}
          onColumnHeaderClick={(e, column) => {
            if (props.onSortChanged && column && column.fieldName) {
              const key = column.fieldName
              const direction = column.isSortedDescending ? 'asc' : 'desc'
              props.onSortChanged(key, direction)
            }
          }}
        />
      </div>

      {<div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
        <div>
          <DefaultButton iconProps={{ iconName: 'previous' }} disabled={!props.pagingInfo.hasPreviousPage}
                         onClick={() => props.onPageChanged(props.pagingInfo.currentPage - 1)} />
        </div>
        <div style={{ paddingLeft: 12, paddingTop: 4 }}>
          <Text>{props.pagingInfo.totalCount} resultaten.
            Pagina {(props.pagingInfo.currentPage).toString()} van {props.pagingInfo.totalPages}</Text>
        </div>
        <div style={{ paddingLeft: 12 }}>
          <DefaultButton iconProps={{ iconName: 'next' }} disabled={!props.pagingInfo.hasNextPage}
                         onClick={() => props.onPageChanged(props.pagingInfo.currentPage + 1)} />
        </div>
      </div>
      }
    </>
  )
}