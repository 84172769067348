import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { IVge } from 'features/vge/vgeSlice'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import { PagedEntities } from 'interfaces/pagedEntities'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'

const setPendingSate = (state: EntityState<IVge> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededSate = (state: EntityState<IVge> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IVge> & IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const fetchNogInTeDelen = createAsyncThunk('vge/fetchNogInTeDelenResults', async (filter: string, thunkAPI) => {
  return await invokeFetch<PagedEntities<IVge>>(thunkAPI, 'GET', `/vge/NogInTeDelen?Filter=${filter}`)
})

export const fetchNogInTeDelenZonderAdres = createAsyncThunk('vge/fetchNogInTeDelenZonderAdresResults', async (filter: string, thunkAPI) => {
  return await invokeFetch<PagedEntities<IVge>>(thunkAPI, 'GET', `/vge/NogInTeDelenZonderAdres?Filter=${filter}`)
})

const entityAdapter = createEntityAdapter<IVge>()

export const vgeInTeDelenSlice = createSlice({
  name: 'vgeInTeDelen',
  initialState: entityAdapter.getInitialState(entityAdapterInitState),
  reducers: {
    clearResults: state => {
      entityAdapter.removeAll(state)
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchNogInTeDelen.pending, state => setPendingSate(state))
    builder.addCase(fetchNogInTeDelen.fulfilled, (state, action: PayloadAction<PagedEntities<IVge>>) => {
      setSucceededSate(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchNogInTeDelen.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(fetchNogInTeDelenZonderAdres.pending, state => setPendingSate(state))
    builder.addCase(fetchNogInTeDelenZonderAdres.fulfilled, (state, action: PayloadAction<PagedEntities<IVge>>) => {
      setSucceededSate(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchNogInTeDelenZonderAdres.rejected, (state, action) => setRejectedState(state, action))
  }
})

export const { selectAll, selectEntities } = entityAdapter.getSelectors<RootState>(state => state.vgesInTeDelen)
export const { clearResults } = vgeInTeDelenSlice.actions

export default vgeInTeDelenSlice.reducer
