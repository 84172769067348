import React, { useEffect, useMemo, useState } from 'react'
import {
    DefaultButton,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    Stack,
    IDropdownOption,
    mergeStyles,
    StackItem,
    IStackItemStyles,
    IDropdownStyles
} from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useTypedSelector } from 'store'
import { getPropertyName } from 'lib/interfaceUtils'
import { useSelector } from 'react-redux'
import {
    updateVge,
    getErrorState,
    clearErrorState,
    selectAll, vgeEditSchema, IVgeEdit, IVge, getUpdateState
} from './vgeSlice'
import VgtTextField from 'components/VgtTextField'
import VgtDropdown from 'components/FluentDropdown'
import {
    getTerreinClassificatieAsOptions,
    fetchAll as fetchAllTerreinClassificatie
} from 'features/beheer/lemdo/terreinClassificatie/terreinClassificatieSlice'
import VgtCheckbox from 'components/FluentCheckbox'
import { selectAll as alleRechtspersonen } from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import { getSelectedEntity as getCurrentTenant } from '../../store/actions/app/tenantSlice'
import Autocomplete, { ISuggestionItem } from 'components/Autocomplete'
import { createAddressTextFromVge } from '../../lib/addressHelper'
import InputCard from 'components/InputCard'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import FluentNumberField from '../../components/FluentNumberField'
import { addZeroDropdownOption } from 'lib/controlSupport'
import { getOptionsForVastgoedeenheidwijziging } from 'features/beheer/lemdo/eenheidDetailSoort/eenheidDetailSoortSlice'
import { zodiosHooks } from '../../api/ApiClient'
import CurrencyField from "../../components/CurrencyField";

interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
    vge?: IVge
    isVerblijfsobject: boolean
}

function setUndefinedIfEmpty(variable: string | null | undefined) {
    return variable === '' ? undefined : variable;
}

const createVgeSuggestions = (vges: IVge[]) => {
    return vges.map(e => {
        return {
            key: e.id,
            displayValue: `${e.str ?? ''} ${e.nr ?? ''} ${e.ltr ?? ''}${e.nrT ?? ''} ${e.aa ?? ''}`,
            searchValue: e.str,
            tag: e
        } as ISuggestionItem
    })
}

const VgeEditPanel: React.FC<IEditPanelProps> = props => {
    const dispatch = useAppDispatch()
    const [title, setTitle] = useState('')
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const updateStatus = useSelector(getUpdateState)
    const errorStatus = useSelector(getErrorState)
    const [validationStatus, setValidationStatus] = useState('')
    const rechts = useSelector(alleRechtspersonen)
    const currentTenant = useSelector(getCurrentTenant)
    const vges = useSelector(selectAll)
    const [filteredVges, setFilteredVges] = useState(vges)
    const vgeSuggestions = createVgeSuggestions(filteredVges)
    const [gelegenBinnenVgeId, setGelegenBinnenVgeId] = useState<number>()
    const terreinClassificatie = useTypedSelector(s => getTerreinClassificatieAsOptions(s))

    const eenheidDetailSoortenRaw = useTypedSelector(s => getOptionsForVastgoedeenheidwijziging(s))
    const eenheidDetailSoorten = useMemo(() => addZeroDropdownOption(eenheidDetailSoortenRaw), [eenheidDetailSoortenRaw])

    const { data: leveringOpties } = zodiosHooks.useGetVerwarmingenleveringOpties()
    const hasBagId = props.vge?.baGid !== undefined && props.vge?.baGid !== ''
    const { data: verhuurbaarOpties } = zodiosHooks.useGetVgeverhuurtypeOpties()

    const { data: redennietverhuurdopties } = zodiosHooks.useGetVgenietVerhuurdOpties({}, { enabled: true })
    const { data: bestemmingOptions } = zodiosHooks.useGetBestemmingOpties({}, { enabled: true })
    const { data: kompasrichtingOptions } = zodiosHooks.useGetKompasrichtingOpties({}, { enabled: true })

    const naamplaatjesOptions: IDropdownOption[] = [
        { key: -1, text: '---' },
        { key: 0, text: '0' },
        { key: 1, text: '1' },
        { key: 2, text: '2' },
        { key: 3, text: '3' },
        { key: 4, text: '4' },
        { key: 5, text: '5' }
    ]


    const errorMessage = errorStatus ?? validationStatus
    const rechtspersonen = useMemo(() => rechts.filter(x => x.rolId === 2 || x.rolId === 3 || x.rolId === 5), [rechts])
    const vvesOptions = () => {
        const nullOption = { key: 0, text: '---' } as IDropdownOption
        const personen = rechts.filter(x => x.rolId === 3).map(r => ({
            key: r.id,
            text: r.handelsnaam
        })) as IDropdownOption[]
        return [nullOption, ...personen]
    }

    const beheerderOptions = () => {
        const nullOption = { key: '', text: '---' } as IDropdownOption
        const personen = rechtspersonen.map(r => ({ key: r.id, text: r.handelsnaam })) as IDropdownOption[]
        return [nullOption, ...personen]
    }

    const classAutocomplete = mergeStyles({
        '& .ms-Stack .ms-StackItem:first-Child': {
            width: '148px'
        },
        '& .ms-StackItem.autoCompleteCode': {
            paddingLeft: '80px'
        }
    })

    const defaultVge = {
        id: 0,
        bId: 0,
        vhes: [],
        kadV: '',
        kad: '',
        str: '',
        nr: '',
        ltr: '', nrT: '', aa: '',
        pc: '',
        baGid: '',
        ref: '',
        opm: '',
        etg: 0,
        bouwjaar: null,
        renovatiejaar: null,
        woonvoorzGehandicapten: null,
        zelfstandig: '',
        toegankelijkheid: '',
        lat: 0,
        long: 0,
        hist: false,
        loc: '',
        repVerzBeoordelen: false
    }

    const {
        control,
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors }
    } = useForm<IVgeEdit>({
        resolver: yupResolver(vgeEditSchema),
        defaultValues: defaultVge
    })

    useEffect(() => {
        setCloseOnFulFilled(false)
        setFilteredVges(vges)
        dispatch(fetchAllTerreinClassificatie())
    }, [])

    useEffect(() => {
        setTitle(`Vastgoedeenheid ${props.vge ? 'wijzigen' : 'toevoegen'}`)
        setValidationStatus('')
        if (props.vge) {
            setValidationStatus('')
            reset(props.vge)
        } else {
            reset(defaultVge)
        }
    }, [props.vge])

    const onCancel = _ => {
        dispatch(clearErrorState())
        props.dismissPanel()
    }

    const onSearchVge = (item: string) => {
        const searchTerm = item.toLocaleLowerCase().trim()
        const newFilteredVges = vges.filter(v => createAddressTextFromVge(v).toLocaleLowerCase().indexOf(searchTerm) >= 0)
        setFilteredVges(newFilteredVges)
    }

    const onSelectVge = (item: ISuggestionItem) => {
        const vge = item.tag
        if (vge) setGelegenBinnenVgeId(vge.id)
    }

    function onInvalid(data) {
        clearErrorState()
        const firstWord = Object.keys(data).filter(e => data[e].message !== '').length === 1 ? 'Validatiefout: ' : 'Validatiefouten: '
        const keys = Object.keys(data)
        const errors = keys.map(x => x + ':' + data[x].message).join(',')
        setValidationStatus(firstWord + errors)
    }

    function onSubmit(data: IVgeEdit) {
        setValidationStatus('')
        if (props.vge) {
            data.id = props.vge.id

            data.pId = setUndefinedIfEmpty(data.pId)
            data.oprId = setUndefinedIfEmpty(data.oprId)
            data.baGid = setUndefinedIfEmpty(data.baGid)
            data.kad = setUndefinedIfEmpty(data.kad)
            data.kadV = setUndefinedIfEmpty(data.kadV)
            data.brtC = setUndefinedIfEmpty(data.brtC)

            data.bId = (typeof data.bId !== 'number') ? undefined : data.bId
            data.histRedenId = !data.hist ? undefined : data.histRedenId
            data.histRedenId = data.histRedenId === 0 ? undefined : data.histRedenId
            data.aantalNaamplaatjes = data.aantalNaamplaatjes === -1 ? undefined : data.aantalNaamplaatjes


            if (data.baGid) {
                data.brt = undefined
                data.pId = undefined
                data.oprId = undefined
            }

            if (gelegenBinnenVgeId) data.gelegenBinnenVgeId = gelegenBinnenVgeId

            dispatch(updateVge(data))
            setCloseOnFulFilled(true)
        } else {
            props.dismissPanel()
        }
    }

    useEffect(() => {
        if (closeOnFulFilled && updateStatus === 'succeeded') {
            dispatch(clearErrorState())
            props.dismissPanel()
        }
    }, [updateStatus])

    const handleConfirmClick = () => {
        dispatch(clearErrorState())
    }

    const stackItemOneStyles: IStackItemStyles = {
        root: {
            maxWidth: '40%'
        }
    }

    const stackItemTwoStyles: IStackItemStyles = {
        root: {
            maxWidth: '60%',
            width: '80%'
        }
    }

    const beheerderFieldStyles: Partial<IDropdownStyles> = {
        root: {
            padding: '0px 0px 0px 12px',
            display: 'flex',
            borderBottom: '1px solid rgb(219 219 219)',
            width: '100%'
        },
        label: {
            fontWeight: 'Normal',
            width: '215px',
            flexGrow: '0',
            flexShrink: '0'
        },
        title: {
            borderWidth: '0px',
            paddingLeft: '4px'
        },
        dropdown: {
            width: '100%',
            fontWeight: 600
        }
    }

    const NietVerhuurd = 5
    const isNietVerhuurd = watch(getPropertyName<IVgeEdit>('vhbId')) === NietVerhuurd

    return (
        <Panel type={PanelType.custom} customWidth='80%' headerText={title} isOpen={props.isOpen}
               onDismiss={onCancel}
               closeButtonAriaLabel='Sluiten'>
            {errorMessage && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}
                            onDismiss={handleConfirmClick}
                            dismissButtonAriaLabel='Close'>
                    <b>{errorMessage}</b>
                </MessageBar>
            )}
            <br/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack>
                    <StackItem>
                        <Stack horizontal tokens={{ childrenGap: '20' }}>
                            <StackItem styles={stackItemOneStyles}>
                                <Stack tokens={{ childrenGap: '20' }}>
                                    <StackItem>
                                        <InputCard title='Locatie' boldTitle width='100%'>
                                            <VgtTextField label='Adresseerbaar object'
                                                          name={getPropertyName<IVgeEdit>('baGid')}
                                                          register={register} control={control} errors={errors}/>
                                            <VgtTextField
                                                label='Openbare ruimte'
                                                name={getPropertyName<IVgeEdit>('oprId')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                                readOnly={hasBagId}
                                            />
                                            <VgtTextField
                                                label='Pand'
                                                name={getPropertyName<IVgeEdit>('pId')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                                readOnly={hasBagId}
                                            />
                                            <VgtTextField label='CBS buurtcode'
                                                          name={getPropertyName<IVgeEdit>('brtC')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                            <VgtTextField label='Adresaanduiding'
                                                          name={getPropertyName<IVgeEdit>('aa')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                            <VgtTextField label='Locatieaanduiding'
                                                          name={getPropertyName<IVgeEdit>('loc')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                            <VgtTextField label='Kadastrale aand. object'
                                                          name={getPropertyName<IVgeEdit>('kad')}
                                                          register={register} control={control} errors={errors}/>
                                            <VgtTextField label='Kadastrale aand. vlak'
                                                          name={getPropertyName<IVgeEdit>('kadV')}
                                                          register={register} control={control} errors={errors}/>
                                            <VgtTextField label='Lat' name={getPropertyName<IVgeEdit>('lat')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                            <VgtTextField label='Long' name={getPropertyName<IVgeEdit>('long')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                        </InputCard>
                                    </StackItem>
                                    <StackItem>
                                        <InputCard title='Relaties' boldTitle width='100%'>
                                            {currentTenant?.isLight && (
                                                <VgtDropdown
                                                    options={vvesOptions()}
                                                    label='VvE'
                                                    name={getPropertyName<IVgeEdit>('vveId')}
                                                    register={register}
                                                    control={control}
                                                    errors={errors}
                                                />
                                            )}
                                            <div className={classAutocomplete}>
                                                <Autocomplete
                                                    label='Gelegen binnen'
                                                    value={props.vge?.gelegenBinnenVgeId ? createAddressTextFromVge(vges.find(x => x.id === props.vge?.gelegenBinnenVgeId)) : undefined}
                                                    items={vgeSuggestions}
                                                    searchBoxWidth={290}
                                                    searchTitle='Gelegen binnen'
                                                    searchCallback={onSearchVge}
                                                    suggestionCallback={onSelectVge}
                                                />
                                            </div>
                                            <br/>
                                            <VgtTextField label='Vaste-activanummer'
                                                          name={getPropertyName<IVgeEdit>('vasAnr')}
                                                          register={register} control={control} errors={errors}/>
                                            <VgtTextField label='Bouwnummer'
                                                          name={getPropertyName<IVgeEdit>('bouwnummer')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                            {props.vge?.isCol === true &&
                                                <VgtTextField label='Externe coll. referentie'
                                                              name={getPropertyName<IVgeEdit>('ref')}
                                                              register={register} control={control}
                                                              errors={errors}/>
                                            }
                                        </InputCard>
                                    </StackItem>
                                </Stack>
                            </StackItem>
                            <StackItem styles={stackItemTwoStyles}>
                                <Stack tokens={{ childrenGap: '20' }}>
                                    <StackItem>
                                        <InputCard title='Details' boldTitle width='120%'>
                                            <VgtDropdown
                                                options={bestemmingOptions}
                                                label='Bestemming'
                                                name={getPropertyName<IVgeEdit>('bstId')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                            />
                                            <VgtDropdown
                                                options={beheerderOptions()}
                                                label='Beheerder'
                                                name={getPropertyName<IVgeEdit>('bId')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                                styles={beheerderFieldStyles}
                                            />
                                            <VgtDropdown
                                                options={eenheidDetailSoorten}
                                                label='Eenheiddetailsoort'
                                                name={getPropertyName<IVgeEdit>('srt')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                            />
                                            <VgtDropdown
                                                options={terreinClassificatie}
                                                label='Terrein classificatie'
                                                name={getPropertyName<IVgeEdit>('trnClId')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                            />
                                            <VgtTextField label='Etage' name={getPropertyName<IVgeEdit>('etg')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                            <FluentNumberField label='Bouwjaar'
                                                               name={getPropertyName<IVgeEdit>('bouwjaar')}
                                                               control={control} errors={errors}/>
                                            <FluentNumberField label='Renovatiejaar'
                                                               name={getPropertyName<IVgeEdit>('renovatiejaar')}
                                                               control={control} errors={errors}/>
                                            <CurrencyField label={'Renovatie Investering'}
                                                           name={getPropertyName<IVgeEdit>('renovatieInvestering')}
                                                           control={control} errors={errors}/>
                                            <CurrencyField label={'Woonvoorz Gehandicapten'}
                                                           name={getPropertyName<IVgeEdit>('woonvoorzGehandicapten')}
                                                           control={control} errors={errors}/>
                                            <VgtCheckbox name={getPropertyName<IVgeEdit>('isCol')}
                                                         label='Is collectief'
                                                         register={register}
                                                         control={control}/>
                                            <VgtTextField
                                                label='Toegankelijkheid'
                                                name={getPropertyName<IVgeEdit>('toegankelijkheid')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                            />
                                            <VgtDropdown
                                                options={verhuurbaarOpties}
                                                label='Verhuurbaar'
                                                name={getPropertyName<IVgeEdit>('vhbId')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                            />
                                            {isNietVerhuurd &&
                                                <VgtDropdown
                                                    name={getPropertyName<IVgeEdit>('redennvId')}
                                                    label='Reden niet verhuurd'
                                                    options={redennietverhuurdopties}
                                                    register={register}
                                                    control={control}
                                                />
                                            }
                                            <VgtCheckbox name={getPropertyName<IVgeEdit>('kpgrnt')}
                                                         label='Koopgarant'
                                                         register={register}
                                                         control={control}/>
                                            <VgtTextField label='(On)zelfstandig'
                                                          name={getPropertyName<IVgeEdit>('zelfstandig')}
                                                          register={register} control={control} errors={errors}/>
                                            <VgtCheckbox name={getPropertyName<IVgeEdit>('repVerz')}
                                                         label='Reparatieverzoek toegestaan'
                                                         register={register} control={control}/>
                                            <VgtCheckbox name={getPropertyName<IVgeEdit>('repVerzBeoordelen')}
                                                         label='Reparatieverz. beoordelen'
                                                         register={register} control={control}/>
                                            <VgtDropdown
                                                name={getPropertyName<IVgeEdit>('aantalNaamplaatjes')}
                                                label='Aantal naamplaatjes'
                                                register={register}
                                                control={control}
                                                options={naamplaatjesOptions}
                                                errors={errors}/>
                                            <VgtDropdown
                                                name={getPropertyName<IVgeEdit>('kmpId')}
                                                label='Kompasrichting'
                                                register={register}
                                                control={control}
                                                options={kompasrichtingOptions}
                                                errors={errors}/>
                                            <VgtTextField label='Opmerking' name={getPropertyName<IVgeEdit>('opm')}
                                                          register={register}
                                                          control={control} errors={errors}/>
                                            <VgtTextField
                                                label="Advertentietekst"
                                                name={getPropertyName<IVgeEdit>('advt')}
                                                register={register}
                                                control={control}
                                                multiline={true}
                                                errors={errors}
                                            />
                                            <VgtDropdown
                                                options={leveringOpties}
                                                label='Levering warmte'
                                                name={getPropertyName<IVgeEdit>('warmteLeveringId')}
                                                register={register}
                                                control={control}
                                                errors={errors}
                                            />
                                        </InputCard>
                                    </StackItem>
                                    <StackItem>
                                        <InputCard title='Monumenten' boldTitle width='120%'>
                                            <VgtCheckbox name={getPropertyName<IVgeEdit>('gmntljkMnmnt')}
                                                         label='Gemeentelijk monument'
                                                         register={register} control={control}/>
                                            <VgtCheckbox
                                                name={getPropertyName<IVgeEdit>('bschrmdStdsOfdrpgzcht')}
                                                label='Beschermd stads-of dorpsgezicht'
                                                register={register}
                                                control={control}
                                            />
                                            <VgtCheckbox name={getPropertyName<IVgeEdit>('prvnclMnmnt')}
                                                         label='Provincial monument'
                                                         register={register} control={control}/>
                                        </InputCard>
                                        <Stack horizontal reversed tokens={{ childrenGap: '10' }}
                                               styles={{ root: { paddingTop: '7.5%', marginRight: '-20%' } }}>
                                            <DefaultButton text='Annuleren' onClick={onCancel}/>
                                            <DefaultButton text='Opslaan' type='submit' primary
                                                           disabled={updateStatus === 'pending'}
                                                           onClick={handleSubmit(onSubmit, onInvalid)}/>
                                        </Stack>
                                    </StackItem>
                                </Stack>
                            </StackItem>
                        </Stack>
                    </StackItem>
                </Stack>
            </form>
        </Panel>
    )
}
export default VgeEditPanel