import { SearchBox } from '@fluentui/react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GebouwDelen from '../gebouwen/gebouwDelen'
import { zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { usePagedParams } from '../../../../services/usePagedParams'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'


const Gebouwdelen: React.FC = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setFilter } = usePagedParams()

  const { data: gebouwdelenItems, invalidate: fetchGebouwdeelItems } = zodiosHooks.useGetGebouwdelen({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => fetchGebouwdeelItems(), icon: 'Refresh' }
  ], [])

  return (
    <MainTemplate title='Gebouwdelen' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder='Filter' styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => fetchGebouwdeelItems()}
      />
      <div style={{ height: '90vh' }}>
        <GebouwDelen gebouw={gebouwdelenItems?.items!} fetchGebouwdeelItems={fetchGebouwdeelItems} />
      </div>
    </MainTemplate>
  )
}

export default Gebouwdelen