import { IDropdownOption, IObjectWithKey } from '@fluentui/react'
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  Dictionary,
  PayloadAction
} from '@reduxjs/toolkit'
import lodash from 'lodash'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import * as yup from 'yup'
import { z } from 'zod'
import { VgeDto } from '../../api/ApiClient'

export interface ISuggestion {
  id: string
  itemType: string
  amount: number
  itemName: string
  locatedIn?: string
  selected: boolean
}

export interface IPand {
  identificatie: string
  status: string
  oorspronkelijkBouwjaar: string
}

export interface IAdres {
  identificatie: string
  woonplaats: string
  postcode: string
  straat: string
  huisnummer: string
  huisletter: string
  huisnummertoevoeging: string
}

export interface IVgeResponse {
  items: IVge[]
}

type VgeDTO = z.infer<typeof VgeDto>;

function validateKadastraleAanduiding(value) {
  if (value === undefined || value === null || value === '') return true
  return value.length === 17
}

function validateJaar(value: string | undefined | null) {
  if (value === undefined || value === null || value === '') return true
  const parsedValue = parseInt(value)
  return !(parsedValue < 1000 || parsedValue > new Date().getFullYear());
}

export const vgeEditSchema: yup.SchemaOf<IVgeEdit> = yup.object().shape({
  id: yup.number(),
  srt: yup.string().required('is verplicht').max(3, 'Lengte is 3 karakters.'),
  type: yup.string().nullable(),
  srtNm: yup.string().nullable(),
  wpl: yup.string().nullable(),
  gemeente: yup.string().nullable(),
  wk: yup.string().nullable(),
  brt: yup.string().nullable(),
  brtC: yup.string().nullable(),
  pc: yup.string().nullable(),
  str: yup.string().nullable(),
  nr: yup.string().nullable(),
  ltr: yup.string().nullable(),
  nrT: yup.string().nullable(),
  lat: yup.number().nullable(),
  long: yup.number().nullable(),
  geb: yup.string().nullable(),
  gebN: yup.string().nullable(),
  gdl: yup.number().nullable(),
  gdlCd: yup.string().nullable(),
  gebouwenClustersCode: yup.string().nullable(),
  gebouwenClustersName: yup.string().nullable(),
  gebCN: yup.string().nullable(),
  gdlN: yup.string().nullable(),
  coordinaten: yup.string().nullable(),
  gebouwencluster: yup.string().nullable(),
  locatieaanduiding: yup.string().nullable(),
  gebouw: yup.string().nullable(),
  gebouwdeel: yup.string().nullable(),
  kad: yup.string().nullable().test('Lengte', 'De lengte moet 17 karakters zijn.', validateKadastraleAanduiding),
  kadV: yup.string().nullable().test('Lengte', 'De lengte moet 17 karakters zijn.', validateKadastraleAanduiding),
  grt: yup.number().nullable(),
  panden: yup.array(),
  nevenadressen: yup.array(),
  aa: yup.string().nullable(),
  loc: yup.string().nullable(),
  en: yup.string().nullable(),
  bn: yup.string().nullable(),
  vhbId: yup.number().nullable(),
  bst: yup.string().nullable(),
  bstId: yup.number().nullable(),
  kmp: yup.string().nullable(),
  kmpId: yup.number().nullable(),
  vhes: yup.array(),
  rel: yup.string().nullable(),
  bId: yup.number().nullable(),
  opm: yup.string().nullable(),
  advt: yup.string().nullable(),
  vasAnr: yup.string().nullable(),
  trnClId: yup.number().nullable(),
  trnNm: yup.string().nullable(),
  roNr: yup.number().nullable(),
  baGid: yup.string().nullable(),
  oprId: yup.string().nullable(),
  pId: yup.string().nullable(),
  kpgrnt: yup.boolean().nullable(),
  gmntljkMnmnt: yup.boolean().nullable(),
  bschrmdStdsOfdrpgzcht: yup.boolean().nullable(),
  repVerz: yup.boolean().nullable(),
  repVerzBeoordelen: yup.boolean().required(),
  prvnclMnmnt: yup.boolean().nullable(),
  isCol: yup.boolean().nullable(),
  ref: yup.string().nullable(),
  etg: yup.number().nullable(),
  zelfstandig: yup.string().nullable(),
  toegankelijkheid: yup.string().nullable(),
  verwarmingIndividueelId: yup.number().nullable(),
  verwarmingCollectiefId: yup.number().nullable(),
  gebouwId: yup.number().nullable(),
  vveId: yup.number().nullable(),
  vveNaam: yup.string().nullable(),
  bouwnummer: yup.string().nullable(),
  bouwjaar: yup.mixed().nullable(),
  renovatiejaar: yup.mixed().nullable().test('Jaar', 'Jaar moet groter zijn dan 1000 en kleiner of gelijk aan huidige jaar.', validateJaar),
  renovatieInvestering: yup.number().nullable(),
  woonvoorzGehandicapten: yup.number().nullable(),
  gelegenBinnenVgeId: yup.number().nullable(),
  indelingType: yup.number().nullable(),
  hist: yup.boolean().nullable(),
  histRedenId: yup.number().nullable(),
  histReden: yup.string().nullable(),
  aantalNaamplaatjes: yup.number().nullable(),
  warmteLeveringId: yup.number().nullable(),
  redennvId:yup.number().nullable(),
  redennv: yup.string().nullable(),
  labelletter: yup.string().nullable()
})

export interface IVgeEdit {
  id?: number
  type?: string | null
  srt?: string | null
  srtNm?: string | null
  wpl?: string | null
  gemeente?: string | null
  wk?: string | null
  brt?: string | null
  brtC?: string | null
  pc?: string | null
  str?: string | null
  nr?: string | null
  ltr?: string | null
  nrT?: string | null
  lat?: number | null
  long?: number | null
  geb?: string | null
  gebN?: string | null
  gdl?: number | null
  gdlCd?: string | null
  gebCN?: string | null
  gdlN?: string | null
  gebouwenClustersCode?: string | null
  gebouwenClustersName?: string | null
  coordinaten?: string | null
  gebouwencluster?: string | null
  gebouw?: string | null
  gebouwdeel?: string | null
  kad?: string | null
  kadV?: string | null
  grt?: number | null
  vasAnr?: string | null
  panden?: IPand[]
  nevenadressen?: IAdres[]
  aa?: string | null
  loc?: string | null
  en?: string | null
  bn?: string | null
  vhbId?: number | null
  bst?: string | null
  bstId?: number | null
  kmp?: string | null
  kmpId?: number | null
  vhes?: string[] | null
  rel?: string | null
  bId?: number | null
  opm?: string | null
  advt?: string | null
  trnClId?: number | null
  trnNm?: string | null
  roNr?: number | null
  baGid?: string | null
  oprId?: string | null
  pId?: string | null
  kpgrnt?: boolean | null
  gmntljkMnmnt?: boolean | null
  bschrmdStdsOfdrpgzcht?: boolean | null
  prvnclMnmnt?: boolean | null
  isCol?: boolean | null
  etg?: number | null
  gebouwId?: number | null
  vveId?: number | null
  vveNaam?: string | null
  bouwnummer?: string | null
  bouwjaar?: number | null
  renovatiejaar?: number | null
  renovatieInvestering?: number | null
  woonvoorzGehandicapten?: number | null
  gelegenBinnenVgeId?: number | null
  toegankelijkheid?: string | null
  zelfstandig?: string | null
  locatieaanduiding?: string | null
  repVerz?: boolean | null
  repVerzBeoordelen: boolean
  hist?: boolean | null
  histRedenId?: number | null
  histReden?: string | null
  aantalNaamplaatjes?: number | null
  warmteLeveringId?: number | null
  ref?: string | null
  redennvId?: number | null
  redennv?: string | null
  labelletter?: string | null
}

export interface IVge extends IObjectWithKey {
  id: number
  type?: string
  srt?: string
  srtNm?: string
  wpl?: string | null
  gemeente?: string
  wk?: string
  brt?: string
  brtC?: string
  pc?: string
  str?: string | null
  nr?: string
  ltr?: string
  nrT?: string
  lat?: number
  long?: number
  geb?: string
  gebN?: string
  gdl?: number
  gdlCd?: string
  gebCN?: string
  gdlN?: string
  gebouwenClusterId?: number
  gebouwenClustersCode?: string
  gebouwenClustersName?: string
  coordinaten?: string
  gebouwencluster?: string
  gebouw?: string
  gebouwdeel?: string
  kad?: string | null
  kadV?: string | null
  grt?: number
  vasAnr?: string
  panden?: IPand[]
  nevenadressen?: IAdres[]
  aa?: string | null
  loc?: string | null
  en?: string
  bn?: string
  vhbId?: number
  vhb?: string
  bst?: string
  bstId?: number
  kmp?: string
  kmpId?: number
  vhes?: string[]
  rel?: string
  bId?: number
  opm?: string | null
  advt?: string | null
  trnClId?: number
  trnNm?: string
  roNr?: number
  baGid?: string
  oprId?: string
  pId?: string
  kpgrnt?: boolean
  gmntljkMnmnt?: boolean
  bschrmdStdsOfdrpgzcht?: boolean
  prvnclMnmnt?: boolean
  isCol?: boolean
  etg?: number
  gebouwId?: number
  vveId?: number
  vveNaam?: string
  bouwnummer?: string | null
  bouwjaar?: number | null
  renovatiejaar?: number | null
  renovatieInvestering?: number | null
  woonvoorzGehandicapten?: number | null
  gelegenBinnenVgeId?: number
  toegankelijkheid?: string
  zelfstandig?: string
  locatieaanduiding?: string
  repVerz?: boolean
  repVerzBeoordelen: boolean
  hist?: boolean
  histDt?: Date
  histRedenId?: number
  histReden?: string
  aantalNaamplaatjes?: number | null
  warmteLeveringNaam?: string | null
  ref?: string
  redennvId?: number| null
  redennv?: string | null
  huurcontractbepalingen: []
  labelletter?: string | null
}

export interface ISearchParams {
  text: string
  type: 'plaats' | 'straat' | 'postcode' | 'buurt' | 'wijk' | undefined
  locatedIn?: string
}

interface ISearchState {
  selectedId: string | undefined
  searchFilter: ISearchParams
  searchPerceelFilter: ISearchParams
  suggestionFilter: string
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  updateStatus: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
  presentation: 'map' | 'list'
}

const initState: ISearchState = {
  selectedId: undefined,
  presentation: 'list',
  searchFilter: {} as ISearchParams,
  searchPerceelFilter: {} as ISearchParams,
  suggestionFilter: '',
  status: 'idle',
  updateStatus: 'idle',
  error: null
}

const entityAdapter = createEntityAdapter<IVge>({})

const getSliceState = (state: RootState) => state.vges

const basePrefix = 'vge'
const baseUrl = '/' + basePrefix

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchStatus`, async (_, thunkAPI) => {
  return await invokeFetch<IVgeResponse>(thunkAPI, 'GET', `${baseUrl}?top=150000`)
})
export const updateVge = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IVgeEdit, thunkAPI) => {
  return await invokeFetch<IVge>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
})
export const deleteVge = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})
export const updateVgeFromBag = createAsyncThunk(`${basePrefix}/updateVgeFromBag`, async (id: number, thunkAPI) => {
  return await invokeFetch<IVge>(thunkAPI, 'POST', `${baseUrl}/${id}/UpdateLatLongFromBagId`)
})

const setPendingSate = state => {
  state.error = null
  state.status = 'pending'
}
const setSucceededSate = state => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const vgeSlice = createSlice({
  name: 'vge',
  initialState: entityAdapter.getInitialState(initState),

  reducers: {
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    clearErrorState: state => {
      state.error = null
    },
    setPresentationType: (state, action: PayloadAction<'map' | 'list'>) => {
      state.presentation = action.payload
    },
    setSearchPerceelFilter: (state, action: PayloadAction<ISearchParams>) => {
      action.payload.text = action.payload.text.toUpperCase()
      state.searchPerceelFilter = action.payload
    },
    setSearchFilter: (state, action: PayloadAction<ISearchParams>) => {
      action.payload.text = action.payload.text.toUpperCase()
      state.searchFilter = action.payload
    },
    setSuggestionFilter: (state, action: PayloadAction<string>) => {
      state.suggestionFilter = action.payload.toUpperCase()
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    modifyMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingSate(state))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<IVgeResponse>) => {
      entityAdapter.setAll(state, action.payload.items)
      setSucceededSate(state)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(updateVge.pending, state => {
      state.error = null
      state.updateStatus = 'pending'
    })
    builder.addCase(updateVge.fulfilled, (state, action: PayloadAction<IVge>) => {
      entityAdapter.upsertOne(state, action.payload)
      state.error = null
      state.updateStatus = 'succeeded'
    })
    builder.addCase(updateVge.rejected, (state, action) => {
      console.log('update failed', action)
      state.updateStatus = 'failed'
      state.error = ((action.payload) as any)['message'] || action.error.message || null
    })

    builder.addCase(updateVgeFromBag.pending, state => setPendingSate(state))
    builder.addCase(updateVgeFromBag.fulfilled, (state, action: PayloadAction<IVge>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededSate(state)
    })
    builder.addCase(updateVgeFromBag.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(deleteVge.pending, state => setPendingSate(state))
    builder.addCase(deleteVge.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg)
      setSucceededSate(state)
    })
    builder.addCase(deleteVge.rejected, (state, action) => setRejectedState(state, action))
  }
})

export const {
  modify,
  removeMany,
  setSearchFilter,
  setSearchPerceelFilter,
  setSuggestionFilter,
  setPresentationType
} = vgeSlice.actions
export const {
  selectAll,
  selectEntities,
  selectById
} = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) {
    return getSliceState(state).entities[selectedId]
  } else return undefined
}

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}
export const getUpdateState = (state: RootState) => {
  return getSliceState(state).updateStatus
}
export const getErrorState = (state: RootState) => {
  return getSliceState(state).error
}
export const { select, clearSelection, clearErrorState } = vgeSlice.actions

const getSearchFilter = (state: RootState) => getSliceState(state).searchFilter

export const selectFilteredEntities = createSelector([selectAll, getSearchFilter], (entities, filter) => {
  let result: IVge[] = []
  if (filter?.text?.length > 1)
    result = entities.filter(
      v =>
        ((v.str?.toUpperCase().indexOf(filter.text) ?? -1) >= 0 && ((filter.type === 'straat' && (v.wpl?.toUpperCase().indexOf(filter.locatedIn?.toUpperCase()!) ?? -1) >= 0) || !filter.type)) ||
        ((v.wpl?.toUpperCase().indexOf(filter.text) ?? -1) >= 0 && (filter.type === 'plaats' || !filter.type)) ||
        ((v.pc?.toUpperCase().indexOf(filter.text) ?? -1) >= 0 && (filter.type === 'postcode' || !filter.type)) ||
        (v.brt && v.brt?.toUpperCase().indexOf(filter.text) >= 0 && (filter.type === 'buurt' || !filter.type)) ||
        (v.wk && v.wk?.toUpperCase().indexOf(filter.text) >= 0 && (filter.type === 'wijk' || !filter.type))
    )
  return result
})

const getSearchPerceelFilter = (state: RootState) => getSliceState(state).searchPerceelFilter
export const selectFilteredPerceelEntities = createSelector([selectAll, getSearchPerceelFilter], (entities, filter) => {
  if (filter?.text?.length >= 1)
    return entities.filter(
      v => (v.nr === null || v.nr === undefined || v.nr === '') && ((v.str?.toUpperCase().indexOf(filter.text) ?? -1) >= 0 || (v.wpl?.toUpperCase().indexOf(filter.text) ?? -1) >= 0)
    )
  else return entities.filter(v => v.nr === undefined || v.nr === '')
})

export const getPresentationType = (state: RootState) => getSliceState(state).presentation

const getSuggestionFilter = (state: RootState) => getSliceState(state).suggestionFilter

const filterVgesOnProp = (vges: IVge[], propertyName: string, searchText: string) => {
  return vges.filter(v => {
    const propertyValue = v[propertyName]
    return propertyValue?.toUpperCase().includes(searchText.toUpperCase())
  })
}
export const selectSuggestions = createSelector([selectAll, getSuggestionFilter], (vges, filter) => {
  const searchText = filter.toUpperCase()

  if (searchText.length <= 1) return [] as ISuggestion[]

  const plaatsen = filterVgesOnProp(vges, 'wpl', searchText)
  const wijken = filterVgesOnProp(vges, 'wk', searchText)
  const buurten = filterVgesOnProp(vges, 'brt', searchText)
  const straten = filterVgesOnProp(vges, 'str', searchText)
  const postcodes = filterVgesOnProp(vges, 'pc', searchText)

  let currentId = 0

  const suggestions: ISuggestion[] = []
  if (plaatsen.length > 0) {
    const group = lodash.groupBy(plaatsen, 'wpl')
    const sortedGroup = lodash.sortBy(group, g => g.length * -1)
    lodash.map(sortedGroup, value =>
      suggestions.push({
        itemName: value[0].wpl,
        itemType: 'Plaats',
        id: (currentId++).toString(),
        amount: value.length
      } as ISuggestion)
    )
  }
  if (wijken.length > 0) {
    const group = lodash.groupBy(wijken, 'wk')
    const sortedGroup = lodash.sortBy(group, g => g.length)
    lodash.map(sortedGroup, value =>
      suggestions.push({
        itemName: value[0].wk,
        itemType: 'Wijk',
        id: (currentId++).toString(),
        amount: value.length,
        locatedIn: value[0].wpl
      } as ISuggestion)
    )
  }
  if (buurten.length > 0) {
    const group = lodash.groupBy(buurten, 'brt')
    const sortedGroup = lodash.sortBy(group, g => g.length)
    lodash.map(sortedGroup, value =>
      suggestions.push({
        itemName: value[0].brt,
        itemType: 'Buurt',
        id: (currentId++).toString(),
        amount: value.length,
        locatedIn: value[0].wpl
      } as ISuggestion)
    )
  }
  if (postcodes.length > 0) {
    const group = lodash.groupBy(postcodes, 'pc')
    const sortedGroup = lodash.sortBy(group, g => g.length)
    lodash.map(sortedGroup, value =>
      suggestions.push({
        itemName: value[0].pc,
        itemType: 'Postcode',
        id: (currentId++).toString(),
        amount: value.length,
        locatedIn: value[0].wpl
      } as ISuggestion)
    )
  }

  if (straten.length > 0) {
    let result: IStraat = straten.reduce((r, c) => {
      let key = `${c.str}-${c.wpl}`
      let { str, wpl } = c
      r[key] = r[key] || { vges: [] }
      r[key].vges.push({ str, wpl })
      return r
    }, {})

    const sortedGroup = lodash.sortBy(result, g => g!.vges.length * -1)
    lodash.map(sortedGroup, value => {
      suggestions.push({
        itemName: value?.vges[0].str,
        itemType: 'Straat',
        id: (currentId++).toString(),
        amount: value?.vges.length,
        locatedIn: value?.vges[0].wpl
      } as ISuggestion)
    })
  }
  return lodash.dropRight(suggestions, suggestions.length - 8)
})

export const sortVges = (vges: VgeDTO[]) => {

  return vges.sort((a, b) => {
    if (a.str === b.str) {
      if (a.nr === b.nr) {
        return a.nrT ?? '' < (b.nrT ?? '') ? -1 : 1
      }
      return a.nr ?? '' < (b.nr ?? '') ? -1 : 1
    } else {
      return a.str ?? '' < (b.str ?? '') ? -1 : 1
    }
  })
}

export const sortOverigGebouwdVges = (vges: VgeDTO[]) => {
  return vges.filter(vge => vge.wpl !== undefined && vge.srt !== undefined && vge.aa !== undefined)
    .sort((a, b) => {
      const wplComparison = (a.wpl ?? '').localeCompare(b.wpl ?? '')
      if (wplComparison !== 0) {
        return wplComparison
      }
      const srtComparison = (a.srt ?? '').localeCompare(b.srt ?? '')
      if (srtComparison !== 0) {
        return srtComparison
      }
      return (a.aa ?? '').localeCompare(b.aa ?? '')
    })
}

export const serviceadresOptions = (adressableVges: VgeDTO[]) => {
  const options: IDropdownOption[] = [{ key: '', text: '---' }]

  if (adressableVges !== undefined)
    sortVges(adressableVges).forEach(vge => {
      options.push({
        key: vge.id,
        text: `${vge.wpl}, ${vge.pc}, ${vge.str} ${vge.nr}${vge.ltr ?? ''}${vge.nrT ? '-' + vge.nrT : ''}`
      } as IDropdownOption)
    })

  return options
}

interface IStraat extends Dictionary<{ vges: { str: string; wpl: string }[] }> {
}

export default vgeSlice.reducer
