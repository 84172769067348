import React, { useEffect } from 'react'
import useRoles from '../../../services/UseRoles'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { HuurcontractbepalingAddOrUpdateDto } from '../../../api/ApiClient'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import InputCard from '../../../components/InputCard'
import VgtTextField from '../../../components/VgtTextField'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { OkCancelButtonStack } from '../../../components/OkCancelButtonStack/OkCancelButtonStack'
import { z } from 'zod'
import VgtFormattedText from '../../../components/VgtFormattedText'

export interface IEditPanelProps {
  huurcontractbepalingId: number,
  data?: CreateUpdateType,
  isLoading: boolean,
  isSubmitting: boolean,
  onSubmit: (values: CreateUpdateType) => void,
  onCancel: () => void
  onError?: (message: string) => void
}

export type CreateUpdateType = z.infer<typeof HuurcontractbepalingAddOrUpdateDto>;

export const HuurcontractbepalingForm: React.FC<IEditPanelProps> = props => {

  const { isVerhuur } = useRoles()

  const defaultHuurcontractbepaling = {
    naam: '',
    content: '',
    titel: ''
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(HuurcontractbepalingAddOrUpdateDto), mode: 'all', defaultValues: defaultHuurcontractbepaling
  })

  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(defaultHuurcontractbepaling)
    }
  }, [props.data])

  const FieldErrorsToMessage = (errors: any) => {
    return errors.naam ? errors.naam.message : undefined
  }

  return (

    <form onSubmit={handleSubmit(props.onSubmit, (data) => {
      if (props.onError) props.onError(FieldErrorsToMessage(data))
    })}>
      <ErrorMessageBar error={FieldErrorsToMessage(errors)} />
      <InputCard title="Huurcontractbepaling" boldTitle={true} width={'calc(100%)'}>
        <VgtTextField
          label="Naam"
          name={getPropertyName<CreateUpdateType>('naam')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isVerhuur}
          defaultValue={defaultHuurcontractbepaling.naam}
        />
        <VgtTextField
          label="Titel"
          name={getPropertyName<CreateUpdateType>('titel')}
          register={register}
          control={control}
          errors={errors}
          required
          readOnly={!isVerhuur}
          defaultValue={defaultHuurcontractbepaling.titel}
        />
        <VgtFormattedText
          label="Bepalingstekst"
          name={getPropertyName<CreateUpdateType>('content')}
          control={control}
          errors={errors}
          required
          defaultValue={defaultHuurcontractbepaling.content}
          height={'200px'}
          labelWidth={215}
          editorWidth={'1251px'}
        />
        <br />
        {isVerhuur ?
          <OkCancelButtonStack isSubmitting={isSubmitting} isLoading={isSubmitting ?? false}
                               onOkClick={() => handleSubmit(props.onSubmit)} onCancelClick={props.onCancel} /> : null}
      </InputCard>
    </form>

  )


}
