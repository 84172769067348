import { DefaultButton, Spinner, Stack } from '@fluentui/react'
import React, { JSX } from 'react'

export function OkCancelButtonStack(props: Readonly<{
  defaultButtonLabel?: string,
  children?: JSX.Element,
  isWriter?: boolean,
  isSubmitting: boolean,
  isLoading: boolean,
  onOkClick: () => void,
  onCancelClick: () => void
}>) {
  return <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10', padding: 10 }}>
    {props.isWriter ?? true ?
      <DefaultButton text={props.defaultButtonLabel ?? 'Opslaan'} type='submit' primary disabled={props.isSubmitting || props.isLoading} onClick={props.onOkClick} style={{ position: 'relative' }}>
        {props.isSubmitting ?
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Spinner />
          </div>
          : null}
      </DefaultButton> : null}
    {props.children}
    <DefaultButton text='Annuleren' onClick={props.onCancelClick} />
  </Stack>
}