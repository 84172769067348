import { IColumn, Link, SearchBox, SelectionMode } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import {
  clearSelection,
  deleteOnderhoudsTaakErp,
  fetchAll,
  getLoadingState,
  IOnderhoudsTaak,
  select,
  selectAll
} from './onderhoudsTaakErpSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import FluentTable from 'components/FluentTable'
import { OnderhoudsTaakEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'

const OnderhoudsTaakList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const [filter, setFilter] = useState('')
  const refreshItems = () => dispatch(fetchAll({ filter: filter }))
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])

  useEffect(() => {
    refreshItems()
  }, [])

  const handleItemInvoked = (id) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        dispatch(clearSelection())
        openPanel()
      }, icon: 'Add'
    },
    {
      text: 'Verwijderen', onClick: () =>
        dispatch(
          deleteOnderhoudsTaakErp(
            selectedItems.map(value => parseInt(value))
          )
        )
      , icon: 'Delete', disabled: !selectedItems.length
    }
  ], [selectedItems.length])

  const renderCode = (x: IOnderhoudsTaak) => <Link key={x.code}
                                                   onClick={() => handleItemInvoked(x.onderhoudstaakErpId.toString())}>{x.code}</Link>

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<IOnderhoudsTaak>('code'), renderCode, 'XXL', false),
    createCustomRenderedColumn('Omschrijving', getPropertyName<IOnderhoudsTaak>('omschrijving'), (x: IOnderhoudsTaak) => <>{x.omschrijving}</>
      , 'MAX', false, true)
  ]

  return (<MainTemplate title='Onderhoudstaken ERP' subTitle='Overzicht' commandItems={commandItems}>
    <OnderhoudsTaakEditPanel
      dismissPanel={dismissPanel}
      isOpen={isPanelOpen}
    />
    <SearchBox placeholder='Voer een tekst in om te filteren' styles={{ root: { width: '400px', margin: '6px' } }}
               value={filter}
               onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
               onSearch={() => refreshItems()}
    />

    <div style={{ height: '77vh' }}>
      <FluentTable
        columns={columns}
        items={items}
        loading={loadingState === 'pending'}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
        onGetKey={(item: IOnderhoudsTaak) => item.onderhoudstaakErpId.toString()}
        onItemInvoked={handleItemInvoked}
      />
    </div>
  </MainTemplate>)
}

export default OnderhoudsTaakList