import { CommandBar, Dropdown, IColumn, ICommandBarItemProps, IDropdownOption, IDropdownStyles, IStackStyles, IStackTokens, Link, mergeStyleSets, PrimaryButton, SearchBox, SelectionMode, Stack } from "@fluentui/react"
import commandBarStyles from 'styles/commandBarStyles'
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import FluentTable from "components/FluentTable"
import { fetchQuery, getLoadingState, getRecordsFound, IVerwerkingsFilter, IVerwerkingsRegel, selectAll } from "./verwerkingsregelsSlice"
import { setSelectedRowIndex } from "./verwerkingSlice"
import { getPropertyName } from "lib/interfaceUtils"
import { createColumn, createCustomRenderedColumn } from "lib/gridHelper"
import { NavigateToBagviewerByVbo, NavigateToKadastraleKaart, NavigateToVge } from "services/navigationService"

const classNames = mergeStyleSets({
  wrapper: {
    marginTop: 10,
    marginLeft: 10,
    height: '90vh',
    position: 'relative',
  },
  listWrapper: {
    marginTop: 10,
    marginLeft: 10,
    height: '80vh',
    width: '99%',
    position: 'absolute',
  },
})

const VerwerkingsRegels: React.FC = _props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const items = useSelector(selectAll)
  const { verwerkingen } = useParams()
  const [detailsFilter, setSearchDetailFilter] = useState<string | undefined>('')
  const [objectFilter, setObjectFilter] = useState<string | undefined>('')
  const [vgeFilter, setVgeFilter] = useState<string | undefined>('')
  const [vboFilter, setVboFilter] = useState<string | undefined>('')
  const [perceelFilter, setPerceelFilter] = useState<string | undefined>('')
  const [selectedActies, setSelectedActies] = React.useState<string[]>([]);
  const [selectedLogtypes, setSelectedLogtypes] = React.useState<string[]>([]);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    const filter: IVerwerkingsFilter = {
      verwerkingen: verwerkingen,
      logTypes: selectedLogtypes.length === 0 ? undefined : selectedLogtypes.join(','),
      actieIds: selectedActies.length === 0 ? undefined : selectedActies.join(','),
      objectType: objectFilter,
      detailsFilter: detailsFilter,
      verblijfsobjectId: vboFilter,
      vastgoedeenheidId: vgeFilter,
      kadastraalObjectId: perceelFilter
    }
    dispatch(fetchQuery(filter))
  }

  const loadingStatus = useSelector(getLoadingState)
  const recordsFound = useSelector(getRecordsFound)

  const selectCommandBarItems: ICommandBarItemProps[] = React.useMemo(
    () => [
      {
        key: 'back',
        text: 'Terug',
        iconProps: { iconName: 'Back', className: 'icon' },
        split: false,
        ariaLabel: 'terug',
        onClick: () => navigate(-1),
      },
    ],
    []
  )

  const onGetKey = (item: IVerwerkingsRegel) => item?.id?.toString()

  const openVge = (item: IVerwerkingsRegel) => NavigateToVge(item.vastgoedeenheidId)
  const openVbo = (item: IVerwerkingsRegel) => item.verblijfsobjectId ? NavigateToBagviewerByVbo({ vboId: item.verblijfsobjectId }) : null
  const openPerceel = (item: IVerwerkingsRegel) => item.kadastraalobjectId ? NavigateToKadastraleKaart({ kadastraleAanduiding: item.kadastraalobjectId }) : null

  const handleItemInvoked = (id: string, index: number) => {
    dispatch(setSelectedRowIndex(index))
  }

  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Id', getPropertyName<IVerwerkingsRegel>('id'), 'S', true),
      createCustomRenderedColumn('Logtype', getPropertyName<IVerwerkingsRegel>('logTypeId'), (item) => <span>{item.logTypeId === 1 ? 'Fout' : item.logTypeId === 2 ? 'Waarschuwing' : item.logTypeId === 3 ? 'Informatie' : 'Detailinformatie'}</span>, 'S', false),
      createCustomRenderedColumn('Actie', getPropertyName<IVerwerkingsRegel>('actieId'), (item) => <span>{item.actieId === 1 ? 'Toegevoegd' : item.actieId === 2 ? 'Gewijzigd' : item.actieId === 3 ? 'Verwijderd' : ''}</span>, 'S'),
      createColumn('Object', getPropertyName<IVerwerkingsRegel>('objectType'), 'M'),

      createCustomRenderedColumn('VGE', getPropertyName<IVerwerkingsRegel>('vastgoedeenheidId'), (item: IVerwerkingsRegel) => item.vastgoedeenheidId ? <Link onClick={() => openVge(item)}>{item.vastgoedeenheidId}</Link> : <></>, 'S'),
      createCustomRenderedColumn('Perceel', getPropertyName<IVerwerkingsRegel>('kadastraalobjectId'), (item: IVerwerkingsRegel) => item.kadastraalobjectId ? <Link onClick={() => openPerceel(item)}>{item.kadastraalobjectId}</Link> : <></>, 'M'),
      createCustomRenderedColumn('VBO', getPropertyName<IVerwerkingsRegel>('verblijfsobjectId'), (item: IVerwerkingsRegel) => item.verblijfsobjectId ? <Link onClick={() => openVbo(item)}>{item.verblijfsobjectId}</Link> : <></>, 'M'),

      createCustomRenderedColumn('Details', getPropertyName<IVerwerkingsRegel>('details'), (item: IVerwerkingsRegel) => item.bestandUrl ? <Link href={item.bestandUrl}>Excel-bestand met verwerkingsfouten</Link> : <>{item.details}</>, 'XL', false, true)
    ]
  }, [])

  const containerStackStyles: IStackStyles = {
    root: {
    },
  };

  const wrapStackTokens: IStackTokens = { childrenGap: '0 10' };
  const firstStackTokens: IStackTokens = { childrenGap: '10 10' };
  const secondStackTokens: IStackTokens = { childrenGap: '10 10' };
  const onActieChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      setSelectedActies(
        item.selected ? [...selectedActies, item.key as string] : selectedActies.filter(key => key !== item.key),
      );
    }
  };
  const onLogtypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item) {
      setSelectedLogtypes(
        item.selected ? [...selectedLogtypes, item.key as string] : selectedLogtypes.filter(key => key !== item.key),
      );
    }
  };

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const ActieOptions = [
    { key: '1', text: 'Toegevoegd' },
    { key: '2', text: 'Gewijzigd' },
    { key: '3', text: 'Verwijderd' },
  ];
  const LogtypeOptions = [
    { key: '1', text: 'Fout' },
    { key: '2', text: 'Waarschuwing' },
    { key: '3', text: 'Informatie' },
    { key: '4', text: 'Detailinformatie' },
  ];

  return (
    <>
      <CommandBar items={selectCommandBarItems} styles={commandBarStyles} />
      <div className={classNames.wrapper}>
        <h2>Verwerkingsregels</h2>

        <Stack horizontal styles={containerStackStyles} tokens={wrapStackTokens}>
          <Stack tokens={firstStackTokens}>
            <Dropdown placeholder="Filter op logtype" selectedKeys={selectedLogtypes} onChange={onLogtypeChange} multiSelect options={LogtypeOptions} styles={dropdownStyles} />
            <Dropdown placeholder="Filter op acties" selectedKeys={selectedActies} onChange={onActieChange} multiSelect options={ActieOptions} styles={dropdownStyles} />
            <SearchBox value={objectFilter} onChange={(_, newValue) => setObjectFilter(newValue?.trim())} onSearch={fetchData} placeholder="Filter op object" />
            <SearchBox value={detailsFilter} onChange={(_, newValue) => setSearchDetailFilter(newValue?.trim())} onSearch={fetchData} placeholder="Filter op details" />
          </Stack>
          <Stack tokens={secondStackTokens}>
            <SearchBox value={vgeFilter} onChange={(_, newValue) => setVgeFilter(newValue?.trim())} onSearch={fetchData} placeholder="Filter op VGE" />
            <SearchBox value={perceelFilter} onChange={(_, newValue) => setPerceelFilter(newValue?.trim())} onSearch={fetch} placeholder="Filter op perceel" />
            <SearchBox value={vboFilter} onChange={(_, newValue) => setVboFilter(newValue?.trim())} onSearch={fetchData} placeholder="Filter op VBO" />
            <PrimaryButton text="Filter" onClick={fetchData}></PrimaryButton>
          </Stack>
        </Stack>

        <Stack horizontal verticalAlign={'center'} tokens={{ padding: 10, childrenGap: 0 }}>
          <span>{recordsFound} verwerkingsregels gevonden.</span>
        </Stack>

        <div className={classNames.listWrapper}>
          <FluentTable
            columns={columns}
            items={items}
            loading={loadingStatus === 'pending'}
            selectionMode={SelectionMode.none}
            onGetKey={onGetKey}
            onItemInvoked={handleItemInvoked}
          />
        </div>
      </div>

    </>
  )
}

export default VerwerkingsRegels