import { IColumn, Link, SearchBox, SelectionMode } from "@fluentui/react"
import FluentTable from "components/FluentTable"
import MainTemplate from "containers/pages/PageTemplates/MainTemplate"
import { createColumn, createCustomRenderedColumn } from "lib/gridHelper"
import { getPropertyName } from "lib/interfaceUtils"
import { PageIdDefinition, PAGES } from "pages"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "store"
import { deleteVves, fetchVves, selectAll, getLoadingState, IVve, clearSelection } from "./vveSlice"
import downloadFile from "../../../../services/downloadFile";

const VveList: React.FC<{}> = _props => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const items = useSelector(selectAll)
    const loadingState = useSelector(getLoadingState)
    const [filter, setFilter] = useState("")
    const refreshItems = () => dispatch(fetchVves({ filter: filter }))
    const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])

    useEffect(() => {
        refreshItems()
    }, [])

    const navigateToEdit = (id: string) => {
        navigate(PAGES[PageIdDefinition.beheer.relatie.vves.edit].route.replace(":id", id))
    }
    
    function exportToExcel() {
        downloadFile('/vve/exportToExcel', 'vgeszondervve.xlsx')
    }

    const commandItems = useMemo(() => [
        { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
        { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
        { text: 'Toevoegen', onClick: () => { dispatch(clearSelection()); navigateToEdit('0') }, icon: 'Add' },
        { text: 'Verwijderen', onClick: () => dispatch(deleteVves(selectedItems.map(value => parseInt(value)))), icon: 'Delete', disabled: !selectedItems.length },
        { text: 'Exporteren naar Excel', onClick: () => exportToExcel(), icon: 'Export'},
    ], [selectedItems.length])
    const renderAdres = (vve: IVve) => vve.straat ?? " " + (vve.postcode ?? "") + " " + (vve.woonplaats ?? "")
    const renderNaam = (vve: IVve) => <Link key={vve.id} onClick={() => navigateToEdit(vve.id.toString())}>{vve.naamOpAkte ?? vve.handelsnaam}</Link>
    const columns: IColumn[] = [
        createCustomRenderedColumn('Naam', getPropertyName<IVve>('naamOpAkte'), renderNaam, 900, true),
        createCustomRenderedColumn('Adres', '', renderAdres, 'XL', false),
        createColumn('Type splitsing', getPropertyName<IVve>('typeSplitsing'), 'L'),
        createColumn('Aantal app.rechten', getPropertyName<IVve>('aantalAppartementsrechten')),
    ]

    return (<>
        <MainTemplate title="Verenigingen van eigenaars" subTitle="Overzicht" commandItems={commandItems}>
            <SearchBox placeholder='Voer een tekst in om te filteren' styles={{ root: { width: '400px', margin: '6px' } }}
                value={filter}
                onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                onSearch={(newValue: string) => refreshItems()} />

            <div style={{ height: '78.5vh' }}>
                <FluentTable
                    columns={columns}
                    items={items}
                    loading={loadingState === 'pending'}
                    selectionMode={SelectionMode.multiple}
                    onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
                    onGetKey={(item: IVve) => item.id.toString()}
                    onItemInvoked={(id: string) => { navigateToEdit(id) }}
                />
            </div>
        </MainTemplate>
    </>)
}

export default VveList