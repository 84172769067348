import React, { ReactNode, useEffect, useState } from 'react'
import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react'
import { DeepPartial, DefaultValues, UnpackNestedValue, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { RootState, useAppDispatch } from 'store'
import { getPropertyName } from 'lib/interfaceUtils'
import { useSelector } from 'react-redux'
import VgtTextField from 'components/VgtTextField'
import ILEmDOEntity, { ILEmDOCodeEntity } from './lemdoEntity'
import IEditPanelProps from './editPanelProps'
import { ActionCreatorWithPayload, Dictionary } from '@reduxjs/toolkit'
import useRoles from 'services/UseRoles'

export interface ILEmDOPanelProps<TLEmDOEntity extends ILEmDOEntity> extends IEditPanelProps {
  stateIdentifier: string
  getSelectedEntity: (state: RootState) => TLEmDOEntity | undefined
  modify: ActionCreatorWithPayload<TLEmDOEntity, string>
  add: ActionCreatorWithPayload<TLEmDOEntity, string>
  entitySchema: any
  renderFields?: (props: ILEmDOFieldsProps) => ReactNode
  fieldNames: Dictionary<string>
  title: string
  handleSubmit?: (entity: TLEmDOEntity) => void
  defaultValues?: DefaultValues<TLEmDOEntity>
}

export interface ILEmDOFieldsProps {
  register: any
  control: any
  errors: any
  fieldNames: Dictionary<string>
}

const LEmDOFields: React.FC<ILEmDOFieldsProps> = props => {
  const { isAdmin } = useRoles()
  const register = props.register
  const control = props.control
  const errors = props.errors
  return (
    <>
      <VgtTextField
        label={props.fieldNames[getPropertyName<ILEmDOCodeEntity>('code')] ?? 'Code'}
        name={getPropertyName<ILEmDOCodeEntity>('code')}
        register={register}
        control={control}
        errors={errors}
        required
        readOnly={!isAdmin ? true : false}
      />
    </>
  )
}

function LEmDOEditPanel<TLEmDOEntity extends ILEmDOEntity>(props: ILEmDOPanelProps<TLEmDOEntity>) {
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState(props.stateIdentifier)
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { /*isDirty, */ isSubmitting, errors, isValid },
  } = useForm<TLEmDOEntity>({ resolver: yupResolver(props.entitySchema), mode: 'onChange', defaultValues: props.defaultValues })

  const selectedEntity = useSelector(props.getSelectedEntity)
  const { isAdmin } = useRoles()

  useEffect(() => {
    setTitle(`${props.title} ${selectedEntity ? (isAdmin ? 'wijzigen' : '') : 'toevoegen'}`)
    if (selectedEntity) {
      reset(selectedEntity as UnpackNestedValue<DeepPartial<TLEmDOEntity>>)
    } else {
      reset()
    }
  }, [selectedEntity])

  const onCancel = () => {
    //if (!isDirty || window.confirm('Gemaakte wijzigingen worden geannuleerd.') === true)

    props.dismissPanel()
  }

  const onSubmit = (data: UnpackNestedValue<TLEmDOEntity>) => {
    const realData = data as TLEmDOEntity
    if (props.handleSubmit) {
      props.handleSubmit(realData)
    } else {
      if (isValid) {
        dispatch(props.modify(realData))
      } else {
        dispatch(props.add(realData))
      }
    }
    props.dismissPanel()
    reset()
  }

  const lemdoProps = { control, register, errors } as ILEmDOFieldsProps

  const onInvalid = (errors) => {
    console.log(errors)
  }

  return (
    <Panel type={PanelType.medium} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
      <br />
      <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <div style={{ visibility: 'hidden' }}>
          <VgtTextField label="Id" name={getPropertyName<ILEmDOEntity>('id')} register={register} control={control} errors={errors} />
        </div>

        {props.renderFields ? (
          props.renderFields(lemdoProps)
        ) : (
          <LEmDOFields fieldNames={props.fieldNames} register={register} control={control} errors={errors} />
        )}

        <br />
        {isAdmin ? (
          <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
            <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
            <DefaultButton text="Annuleren" onClick={onCancel} />
          </Stack>
        ) : null}
      </form>
    </Panel>
  )
}

export default LEmDOEditPanel
