import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'

interface IEntityAdapterStateExtended extends IEntityAdapterState {
  query?: string
  selectedRowIndex: number
}

export interface IVerwerking {
  id: number
  beschrijving: string
  gestartDoor: string
  gestartOp: Date
  gereedOp: Date
  status: string
  foutmelding: string
}

const entityAdapter = createEntityAdapter<IVerwerking>({})

export const entityAdapterInitState: IEntityAdapterStateExtended = {
  selectedId: undefined,
  status: 'idle',
  error: null,
  searchFilter: '',
  selectedRowIndex: -1,
}
const baseUrl = '/verwerking'
const basePrefix = 'verwerkingen'
const getSliceState = (state: RootState) => state.verwerkingen

export const fetchQuery = createAsyncThunk(`${basePrefix}/fetchStatus`, async ({ top, filter }: { top: number; filter: string }, thunkAPI) => {
  return await invokeFetch<PagedEntities<IVerwerking>>(thunkAPI, 'GET', `${baseUrl}?top=50000&Filter=${filter}`)
})

const setPendingSate = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededSate = (state: IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const verwerkingQuery = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    setSelectedRowIndex: (state, action: PayloadAction<number>) => {
      state.selectedRowIndex = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchQuery.pending, state => setPendingSate(state))
    builder.addCase(fetchQuery.fulfilled, (state, action) => {
      setSucceededSate(state)
      entityAdapter.setAll(state, action.payload.items)
      state.searchFilter = action.meta.arg.filter
    })
    builder.addCase(fetchQuery.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const getLoadingState = (state: RootState) => {
  return getSliceState(state).status
}

export const getRecordsFound = (state: RootState) => {
  return getSliceState(state).ids.length
}

export const { selectAll } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))
export const { setSelectedRowIndex } = verwerkingQuery.actions

export default verwerkingQuery.reducer
