import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Pivot, PivotItem } from '@fluentui/react'
import AdresseerbaarObject from './adresseerbaarObject'
import { getUpdateState, IVge, select, updateVgeFromBag } from './vgeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import {
  NavigateToBagviewerByStreet,
  NavigateToGoogleMaps,
  NavigateToKadastraleKaart
} from 'services/navigationService'
import PerceelGegevens from './perceelGegevens'
import { fetchPerceel, selectById as selectPerceelById } from './perceelSlice'
import { getSelectedEntity as getCurrentTenant } from '../../store/actions/app/tenantSlice'
import { useBoolean } from '@fluentui/react-hooks'
import VgeEditPanel from './vgeEditPanel'
import WozCard from './woz/wozCard'
import WWSCard from './WWS/WWSCard'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { VheSelectionPanel } from './VheSelectionPanel'
import { ICommandItem } from 'containers/pages/PageTemplates/CommandBarTemplate'
import VgeOnderhoudsContracten from './onderhoudsContract'
import { PageIdDefinition, PAGES } from 'pages'
import { fetchRechtspersonen } from 'features/beheer/relaties/rechtspersoon/rechtspersoonSlice'
import { CartotheekWizardPanel } from '../beheer/cartotheek/CartotheekPanel'
import useApi from '../../services/UseApi'
import VerhuureenheidTab from './VerhuureenheidTab'
import { VgeRuimtes } from './ruimtes/VgeRuimtes'
import { createAddressTextFromVge } from '../../lib/addressHelper'
import { SleutelsEnMeterstanden } from './sleutelsEnMeterstanden/sleutelsEnMeterstanden'
import { useAssignedRoles } from '../../services/UseRoles'
import DocumentList, { DocumentDoelType } from '../beheer/documents/DocumentList'
import { zodiosHooks } from '../../api/ApiClient'
import { VgeHistorischPanel } from './VgeHistorischPanel'
import { createCommandItems } from '../../components/VgtCommandItem/VgeCommandItem'
import Oppervlakte from '../beheer/fysiek/Oppervlakte/Oppervlakte'
import { CoencadZorggeschiktheidList } from './coencadZorggeschiktheid/CoencadZorggeschiktheidList'
import { CoencadMeetinstructieList } from './coencadMeetinstructie/CoencadMeetinstructieList'
import CompassDirection from '../../components/CompassDirections'
import { EnergyLabelType } from '../../components/EnergyLabel'
import EpOnlineCard from './eponline/EpOnlineCard'

const VgeEdit: React.FC = () => {
  const { id } = useParams()
  // validate router param
  const idAsNumber = parseInt(id ?? '')
  if (isNaN(idAsNumber)) {
    throw new Error('Invalid id in url')
  }
  const { state, execute } = useApi<IVge>(`vge/${idAsNumber}`, 'GET')
  const vge = state?.data
  const vgeIds: number[] = vge ? [vge.id] : [-1]

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currentTenant = useSelector(getCurrentTenant)
  const roles = useAssignedRoles()
  const perceel = useSelector(state => selectPerceelById(state as RootState, vge?.id ?? -1))
  const [inEditMode, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false)
  const [inHistorischMode, { setTrue: openHistorischPanel, setFalse: dismissHistorischPanel }] = useBoolean(false)
  const [InVheSelection, { setTrue: openVheSelectionPanel, setFalse: dismissVheSelectionPanel }] = useBoolean(false)
  const [selectedPivot, setSelectedPivot] = useState('vge')
  const vgeUpdateState = useSelector(getUpdateState)
  const [documentCount, setDocumentCount] = useState(0)

  const { data: documents } = zodiosHooks.useGetApiDocuments({
    queries: {
      DoelType: DocumentDoelType.vge,
      DoelId: idAsNumber
    }
  })

  useEffect(() => {
    dispatch(select(id))
    if (id) dispatch(fetchPerceel(parseInt(id)))
  }, [id])

  useEffect(() => {
    dispatch(fetchRechtspersonen({ filter: '' }))
    setDocumentCount(documents?.items?.length ?? 0)
  }, [documents])

  useEffect(() => {
    if ('succeeded' === vgeUpdateState) {
      execute()
    }
  }, [vgeUpdateState])

  const memoedCommandItems = useMemo(() => {
    if (!currentTenant) return [] as ICommandItem[]

    return createCommandItems(roles,
      [
        { text: 'Terug', onClick: () => navigate(-1), icon: 'Back' },
        { text: 'Wijzigen', onClick: () => openEditPanel(), icon: 'Edit', allowedRoles: ['Vastgoedbeheer'] },
        {
          text: 'Historisch maken',
          onClick: () => openHistorischPanel(),
          icon: 'Edit',
          allowedRoles: ['Vastgoedbeheer'],
          disabled: vge?.hist
        },
        {
          text: 'Bagviewer',
          onClick: () => NavigateToBagviewerByStreet({
            straat: vge?.str,
            huisnummer: vge?.nr,
            huisletter: vge?.ltr,
            postcode: vge?.pc,
            woonplaats: vge?.wpl,
            baGid: vge?.baGid
          }),
          icon: 'View'
        },
        {
          text: 'WOZ-waardeloket',
          onClick: () => window.open('https://www.wozwaardeloket.nl/', '_blank'),
          icon: 'RealEstate'
        },
        {
          text: 'Kadastrale kaart',
          onClick: () => NavigateToKadastraleKaart({ kadastraleAanduiding: vge?.kadV }),
          icon: 'MapDirections'
        },
        {
          text: 'Google maps',
          onClick: () =>
            NavigateToGoogleMaps({
              woonplaats: vge?.wpl,
              straat: vge?.str,
              huisnummer: vge?.nr ? vge?.nr : '',
              huisnummerToevoeging: vge?.nrT ? vge?.nrT : '',
              huisletter: vge?.ltr ? vge?.ltr : ''
            }),
          icon: 'MapPin'
        },
        {
          text: 'Begrippenlijst',
          onClick: () => navigate(PAGES[PageIdDefinition.beheer.lemdo.begrippenLijstViewOnly].route.replace(':viewonly', 'viewonly')),
          icon: 'EntitlementPolicy'
        },
        {
          text: 'Geo bijwerken',
          icon: 'Globe',
          onClick: () => dispatch(updateVgeFromBag(vge?.id!)),
          allowedRoles: ['VgtAdministrator']
        },
        {
          text: 'Verhuur adm.',
          onClick: () => NavigateToRentalAdm(),
          icon: 'Home',
          disabled: !vge?.vhes?.length,
          visible: currentTenant.vheUrlToRentalApplication !== ''
        }
      ])
  }, [currentTenant, openEditPanel, vge?.id, roles, openHistorischPanel])

  const isVerblijfsobject = vge?.baGid?.slice(4, 6) === '01'

  const getVheFromCurrentVge = () => {
    if (vge?.vhes?.length === 0) return undefined
    if (vge?.vhes?.length === 1) return vge.vhes[0]

    openVheSelectionPanel()
  }

  const NavigateToRentalAdm = () => {
    const vheId = getVheFromCurrentVge()
    if (vheId) {
      const url: string = `${currentTenant?.vheUrlToRentalApplication}${vheId}`
      window.open(url, '_blank')
    }
  }

  const onModified = () => {
    execute()
    dismissHistorischPanel()
  }

  const historischDate = vge?.histDt ? new Date(vge?.histDt).toLocaleDateString('nl-NL').replace(/\//g, '-') : 'No date'

  const historisch = (vge: any) => { 
    if(vge?.hist){
      var verkoopAan = vge?.histReden === 'Verkoop' ? vge?.verkpAan : ''
      return `(Historisch, ${vge?.histReden}${verkoopAan !== '' ? ` : ${verkoopAan}` : ''} (${historischDate}))`
    }
    else{return ''}
  }

  const title = createAddressTextFromVge(vge) + `, ${vge?.pc ?? ''} ${vge?.wpl ?? ''} ${historisch(vge)}`

  const onOpenPivot = (key: string) => {
    setSelectedPivot(key)
  }

  return (
    <MainTemplate title={title} subTitle='Vastgoedeenheid' commandItems={memoedCommandItems} error={state.error}
                  titleIcon={<CompassDirection direction={vge?.kmp} />}>
      <VgeEditPanel dismissPanel={dismissEditPanel} isOpen={inEditMode} isVerblijfsobject={isVerblijfsobject}
                    vge={vge} />
      <VgeHistorischPanel onModified={onModified} dismissPanel={dismissHistorischPanel} isOpen={inHistorischMode}
                          vgeIds={vgeIds} />
      <VheSelectionPanel baseUrl={currentTenant?.vheUrlToRentalApplication} vge={vge}
                         dismissPanel={dismissVheSelectionPanel} isOpen={InVheSelection} />
      <div style={{ marginLeft: 20, marginTop: 10 }}>
        <Pivot
          aria-label='Vastgoedeenheid details'
          styles={{
            link: { padding: '0 8 0 0' },
            linkIsSelected: { padding: '0 8 0 0' },
            itemContainer: { padding: '16px 0 0 0' }
          }}
          style={{ marginBlockEnd: 10 }}
          selectedKey={selectedPivot}
          onLinkClick={item => setSelectedPivot(item?.props?.itemKey ?? '')}
          overflowBehavior={'menu'}
          overflowAriaLabel={'Meer opties'}
        >
          <PivotItem headerText='Vastgoedeenheid' itemKey='vge'>
            <AdresseerbaarObject tenant={currentTenant} vge={vge as IVge} onOpenPivot={onOpenPivot}
                                 labelletter={vge?.labelletter as EnergyLabelType} />
          </PivotItem>

          {(vge?.vhbId ?? 4) > 1 && (
            <PivotItem headerText='Verhuureenheid' itemKey='vhe'>
              <VerhuureenheidTab verhuurOptie={vge?.vhbId}></VerhuureenheidTab>
            </PivotItem>
          )}

          <PivotItem headerText='Perceel' itemKey='perceel'>
            <PerceelGegevens perceel={perceel}></PerceelGegevens>
          </PivotItem>

          <PivotItem headerText='Ruimtes' itemKey='ruimtes'>
            <VgeRuimtes vgeId={idAsNumber} />
          </PivotItem>

          <PivotItem headerText='Energieprestatie' itemKey='eponline'>
            <EpOnlineCard vgeId={idAsNumber}></EpOnlineCard>
          </PivotItem>

          <PivotItem headerText='WOZ' itemKey='woz'>
            <WozCard vgeId={vge?.id} />
          </PivotItem>

          <PivotItem headerText='WWD' itemKey='WWD'>
            <WWSCard vgeId={vge?.id} />
          </PivotItem>

          {vge && (
            <PivotItem headerText='Zorggeschiktheid' itemKey='coencadZorggeschiktheid'>
              <CoencadZorggeschiktheidList vgeId={vge?.id} />
            </PivotItem>
          )}

          {vge && (
            <PivotItem headerText='Meetinstructies' itemKey='coencadMeetinstructie'>
              <CoencadMeetinstructieList vgeId={vge?.id} />
            </PivotItem>
          )}

          <PivotItem itemKey='Oppervlakte' headerText='NEN2580'>
            <Oppervlakte vgeId={vge?.id} />
          </PivotItem>

          <PivotItem headerText='Cartotheek' itemKey='cartotheek'>
            <CartotheekWizardPanel vgeId={vge?.id} />
          </PivotItem>

          <PivotItem headerText='Sleutels en meterstanden' itemKey='sleutelEnMeterstanden'>
            <SleutelsEnMeterstanden vgeId={vge?.id} />
          </PivotItem>

          <PivotItem itemKey='Contracten' headerText='Onderhoudscontracten'>
            <VgeOnderhoudsContracten vgeId={vge?.id ?? 0} />
          </PivotItem>

          {vge && (
            <PivotItem key='vge_documenten' headerText='Media' itemCount={documentCount} itemKey='documents'>
              <DocumentList doelType={DocumentDoelType.vge} doelId={idAsNumber} />
            </PivotItem>
          )}

        </Pivot>
      </div>
    </MainTemplate>
  )
}

export default VgeEdit
