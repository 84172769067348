import React, { useEffect, useState } from 'react'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import VgtTextField from '../../../../components/VgtTextField'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  GebouwdeelAddOrUpdateDto,
  GebouwDeelDto,
  UpdateGebouwdeelCommand,
  zodiosHooks
} from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { useParams } from 'react-router-dom'
import VgtDropdown from '../../../../components/FluentDropdown'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

type GebouwDeelDtoType = z.infer<typeof GebouwDeelDto>;
type CreateUpdateGebouwDeelType = z.infer<typeof UpdateGebouwdeelCommand>;
type GebouwdeelAddOrUpdateDtoType = z.infer<typeof GebouwdeelAddOrUpdateDto>;

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: () => void;
  fetchData: () => void;
  selectedGebouwdeel?: GebouwdeelAddOrUpdateDtoType;
  gebouwDelen?: GebouwDeelDtoType
}

export const GebouwDeelEditPanel: React.FC<IEditPanelProps> = ({
                                                                 dismissPanel,
                                                                 isOpen,
                                                                 fetchData,
                                                                 selectedGebouwdeel,
                                                                 gebouwDelen
                                                               }) => {
  const [error, setError] = useState<string>()
  const { id } = useParams()

  const { data: gebouwList } = zodiosHooks.useGetGebouwen({}, { enabled: id !== undefined })

  const { mutate: addGebouwdeel, isLoading: isPostingGebouwdeel } = zodiosHooks.usePostGebouwdelen(
    {}, {
      onSuccess: () => {
        fetchData()
        dismissPanel()
      }, onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const { mutate: updateGebouwdeel, isLoading: isUpdatingGebouwdeel } = zodiosHooks.usePutGebouwdelenId(
    { params: { id: parseInt(id!) } }, {
      onSuccess: () => {
        fetchData()
        dismissPanel()
      },
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const defaultGebouwDeel = {
    id: selectedGebouwdeel?.gebouwDeelId ?? 0,
    code: selectedGebouwdeel?.code,
    naam: selectedGebouwdeel?.naam,
    gebouwId: selectedGebouwdeel?.gebouwId,
    gebouwNaam: gebouwDelen?.gebouwNaam ?? '',
    serviceadresVge: selectedGebouwdeel?.serviceadresVge ?? null,
    advertentietekst: selectedGebouwdeel?.advertentietekst ?? null,
    vgesMetAdres: gebouwDelen?.vgesMetAdres ?? null,
    vgesZonderAdres: gebouwDelen?.vgesZonderAdres ?? null
  } as CreateUpdateGebouwDeelType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateGebouwDeelType>({
    mode: 'onChange',
    defaultValues: defaultGebouwDeel
  })

  useEffect(() => {
    if (selectedGebouwdeel) {
      reset(selectedGebouwdeel)
    }
  }, [selectedGebouwdeel])

  const serviceAdresOptions = () => {
    const options: IDropdownOption[] = []
    gebouwDelen?.vgesMetAdres?.forEach(vge => {
      options.push({
        key: vge.id,
        text: `${vge.wpl}, ${vge.pc}, ${vge.str} ${vge.nr}${vge.ltr ?? ''}${vge.nrT ? '-' + vge.nrT : ''} ${vge.aa}`
      } as IDropdownOption)
    })
    return options
  }

  const gebouwOptions = () => {
    const options: IDropdownOption[] = []
    gebouwList?.items?.forEach(vge => {
      options.push({
        key: vge.id,
        text: vge.naam
      } as IDropdownOption)
    })
    return options
  }

  const onSubmit = data => {
    if (data.serviceadresVge === '') {
      data.serviceadresVge = null
    }
    const GebouwdeelData = { ...gebouwDelen, ...data }

    if (gebouwDelen?.id) {
      updateGebouwdeel(GebouwdeelData)
    } else {
      addGebouwdeel({ ...defaultGebouwDeel, ...GebouwdeelData, omschrijving: '' })
    }
  }

  return (
    <Panel type={PanelType.custom} customWidth='60%' headerText={'GebouwenCluster toevoegen'} isOpen={isOpen}
           onDismiss={dismissPanel}
           closeButtonAriaLabel='Sluiten'>
      <ErrorMessageBar error={error} />
      <form>
        <VgtTextField
          label='Code'
          name={getPropertyName<CreateUpdateGebouwDeelType>('code')}
          register={register}
          control={control}
          errors={errors}
          required
        />
        <VgtTextField
          label='Naam'
          name={getPropertyName<CreateUpdateGebouwDeelType>('naam')}
          register={register}
          control={control}
          errors={errors}
          required
        />
        <VgtTextField
          label='Advertentietekst'
          name={getPropertyName<CreateUpdateGebouwDeelType>('advertentietekst')}
          register={register}
          control={control}
          multiline={true}
          errors={errors}
        />
        <VgtDropdown
          label='Gebouw'
          name={getPropertyName<CreateUpdateGebouwDeelType>('gebouwId')}
          defaultSelectedKey={selectedGebouwdeel?.gebouwId ?? ''}
          register={register}
          control={control}
          errors={errors}
          required
          options={gebouwOptions()}
        />
        {gebouwDelen && <VgtDropdown
          label='Serviceadres'
          name={getPropertyName<CreateUpdateGebouwDeelType>('serviceadresVge')}
          defaultSelectedKey={selectedGebouwdeel?.serviceadresVge ?? 0}
          register={register}
          control={control}
          errors={errors}
          options={serviceAdresOptions()}
        />}
        <OkCancelButtonStack
          isSubmitting={isSubmitting}
          isLoading={isPostingGebouwdeel || isUpdatingGebouwdeel}
          onOkClick={handleSubmit(onSubmit)}
          onCancelClick={dismissPanel}
        />
      </form>
    </Panel>
  )
}