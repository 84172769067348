import { IColumn, SearchBox, SelectionMode, Link } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'
import { createCustomRenderedColumn } from 'lib/gridHelper'
import {
  clearSelection,
  deleteOnderhoudsspecialisme,
  fetchAll,
  getLoadingState,
  IOnderhoudsspecialisme,
  select,
  selectAll
} from './onderhoudsspecialismeSlice'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import FluentTable from 'components/FluentTable'
import { OnderhoudsspecialismeEditPanel } from './editPanel'
import { useBoolean } from '@fluentui/react-hooks'
import useRoles from 'services/UseRoles'

const OnderhoudsspecialismeList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const items = useSelector(selectAll)
  const loadingState = useSelector(getLoadingState)
  const [filter, setFilter] = useState('')
  const refreshItems = () => dispatch(fetchAll({ filter: filter }))
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([] as string[])
  const { isAdmin } = useRoles()

  useEffect(() => {
    refreshItems()
  }, [])

  const handleItemInvoked = (id) => {
    dispatch(select(id))
    openPanel()
  }

  const commandItems = useMemo(() => [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
    {
      visible: isAdmin, text: 'Toevoegen', onClick: () => {
        dispatch(clearSelection())
        openPanel()
      }, icon: 'Add'
    },
    {
      visible: isAdmin, text: 'Verwijderen', onClick: () =>
        dispatch(
          deleteOnderhoudsspecialisme(
            selectedItems.map(value => parseInt(value))
          )
        )
      , icon: 'Delete', disabled: !selectedItems.length
    }
  ], [isAdmin, selectedItems.length])

  const renderCode = (x: IOnderhoudsspecialisme) => <Link key={x.code}
                                                          onClick={() => handleItemInvoked(x.onderhoudsspecialismeId.toString())}>{x.code}</Link>

  const columns: IColumn[] = [
    createCustomRenderedColumn('Code', getPropertyName<IOnderhoudsspecialisme>('code'), renderCode, 'XXL', false),
    createCustomRenderedColumn('Naam', getPropertyName<IOnderhoudsspecialisme>('naam'), (x: IOnderhoudsspecialisme) => <>{x.naam}</>
      , 'MAX', false, true)
  ]

  return (<>
    <MainTemplate title='Onderhoudsspecialismen' subTitle='Overzicht' commandItems={commandItems}>
      <OnderhoudsspecialismeEditPanel
        dismissPanel={dismissPanel}
        isOpen={isPanelOpen}
      />
      <SearchBox placeholder='Voer een tekst in om te filteren' styles={{ root: { width: '400px', margin: '6px' } }}
                 value={filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => refreshItems()}
      />

      <div style={{ height: '77vh' }}>
        <FluentTable
          columns={columns}
          items={items}
          loading={loadingState === 'pending'}
          selectionMode={SelectionMode.multiple}
          onSelectionChanged={(ids: string[]) => setSelectedItems(ids)}
          onGetKey={(item: IOnderhoudsspecialisme) => item.onderhoudsspecialismeId.toString()}
          onItemInvoked={handleItemInvoked}
        />
      </div>
    </MainTemplate>
  </>)
}

export default OnderhoudsspecialismeList