import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react'
import { IVgtFieldPropsBase } from 'lib/IVgtFieldPropsBase'

interface IVgtDropdownProps extends IVgtFieldPropsBase {
  control?: any
  defaultSelectedKey?: number | string
  disabled?: boolean
  dropdownWidth?: number | 'auto'
  errors?: any
  label: string
  name: string
  onChange?: (key?: number | string) => void
  options: IDropdownOption[] | undefined
  readOnly?: boolean
  register?: any
  required?: boolean
  selectedKey?: string | number | undefined
  setSelectedKey?: (key: string | number | undefined) => void
  setValue?: (name: string, value: unknown, config?: Partial<{
    shouldValidate: boolean;
    shouldDirty: boolean;
  }> | undefined) => void
  styles?: object
}

const textFieldStyles: Partial<IDropdownStyles> = {
  root: {
    padding: '0px 0px 0px 12px',
    display: 'flex',
    width: '98%',
  },
  label: {
    fontWeight: 'Normal',
    width: '215px',
    flexGrow: '0',
    flexShrink: '0',
  },
  title: {
    borderWidth: '0px',
    paddingLeft: '4px',
  },
  dropdown: {
    width: '70%',
    fontWeight: 600,
  },
}

export function useFluentDropDown(props: IVgtDropdownProps) {  
  const [selectedKey, setSelectedKey] = useState(props.selectedKey);
  const setSelectedKeyState = (value: string | number | undefined) => {
    setSelectedKey(value);
    if(props.onChange)
      props.onChange(value);
    if(props.setValue)
      props.setValue(props.name, value, {shouldDirty: true});
  }
  return {DropDownComponent: (<VgtDropdown {...props} selectedKey={selectedKey} setSelectedKey={setSelectedKey} />), selectedKey, setSelectedKeyState};
}

const VgtDropdown: React.FC<IVgtDropdownProps> = props => {
  return (
    <div hidden={props.showIf !== undefined && !props.showIf}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultSelectedKey}
        render={({ onChange, value }) => (
          <Dropdown
            onChange={props.readOnly ? () => null : (_e, option) => {
              onChange(option?.key)
              if (props.onChange) props.onChange(option?.key)
            }}
            defaultValue={props.defaultSelectedKey}
            selectedKey={value ?? props.selectedKey}
            styles={props.styles ? props.styles : textFieldStyles}
            label={props.label}
            required={!!props.required}
            placeholder=" ---"
            options={props.options!}
            errorMessage={props.errors ? props.errors[props.name]?.message : ""}
            disabled={props.disabled}
            dropdownWidth={props.dropdownWidth}
          />
        )}
      />
    </div>
  )
}

export default VgtDropdown
