import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'
import * as yup from 'yup'
import { IDropdownOption } from '@fluentui/react'
import { IRechtspersoon } from '../rechtspersoon/rechtspersoonSlice'
import { IKvk } from '../kvk/kvkSlice'
import { IKadastraalObject } from './kadastraalObjectSlice'

export interface IVve extends IRechtspersoon {
    naamOpAkte: string,
    typeSplitsing: string,
    oprichtingsdatum: Date,
    gerechtigdeNummer: string,
    administratiefBeheerder: string,
    administratiefBeheerderContactpersoon: string,
    technischBeheerder: string,
    technischBeheerderContactpersoon: string,
    vertegenwoordigerCorporatie: string,
    codeInExterneVveRegistratie: string,
    onderliggendeVves: IVve[],
    bovenliggendeVve?: IVve,
    bovenliggendeVveNaam: string,
    beheerTelefoon: string,
    beheerEmail: string,
    aantalAppartementsrechten: number,
    appartmentsrechten: IKadastraalObject[],
    bronnenVoorSplitsing: IKadastraalObject[]
    intakeDoorCorporatie: boolean
}

export const vveSchema = yup.object().shape({
    naamOpAkte: yup.string().required('Naam op akte is verplicht'),
    handelsnaam: yup.string().required('Handelsnaam is verplicht').max(150, 'Maximale lengte is 150'),
    emailadres: yup.string().trim().email('Geen geldig e-mailadres'),
    telefoonnummer: yup
        .string()
        .trim()
        .matches(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/, 'Geen geldig telefoonnummer'),
    beheeremail: yup.string().trim().email('Geen geldig e-mailadres'),
    beheertelefoon: yup
      .string()
      .trim()
      .matches(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/, 'Geen geldig telefoonnummer'),
    postcode: yup
        .string()
        .trim()
        .uppercase()
        .matches(/^[1-9][0-9]{3}([A-RT-Z][A-Z]|[S][BCE-RT-Z])$/, 'Geen geldige postcode'),
    kvkNummer: yup.string().max(50, 'Maximale lengte is 50'),
    vestigingsNummer: yup.string().max(50, 'Maximale lengte is 50'),
})

export interface IVveState {
    selectedId: string | undefined
    status: 'idle' | 'pending' | 'succeeded' | 'failed'
    error: string | null
    searchFilter: string
}

export const vveInitState: IVveState = {
    selectedId: undefined,
    status: 'idle',
    error: null,
    searchFilter: ''
}

const entityAdapter = createEntityAdapter<IVve>({
    sortComparer: (a, b) => a.handelsnaam?.localeCompare(b.handelsnaam),
})

const baseUrl = '/vve'
const basePrefix = 'vve'
const getSliceState = (state: RootState) => state.vves

export interface IFetchVvesArgs {
    filter?: string
}

export const fetchVves = createAsyncThunk(`${basePrefix}/fetchStatus`, async (args: IFetchVvesArgs, thunkAPI) => {
    const uri = `${baseUrl}?Filter=${args.filter}`
    return await invokeFetch<PagedEntities<IVve>>(thunkAPI, 'GET', uri)
})

export const fetchVve = createAsyncThunk(`${basePrefix}/fetchOneStatus`, async (id: string, thunkAPI) => {
    const uri = `${baseUrl}/${id}`
    return await invokeFetch<IVve>(thunkAPI, 'GET', uri)
})

export const updateVve = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IVve, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
})

export interface IUpdateFromKvkArgs {
    id: number
    entity: IKvk
}

export interface IKadastraleObjectenArgs {
    id: number
    kadastraleObjecten: string[]
}

export interface IVveArgs {
    id: number
    onderliggendeVves: string[]
}

export interface IKadastraalSubjectArgs {
    vveId: number
    kadastraalSubjectId: number
}

export const updateVveFromKvk = createAsyncThunk(`${basePrefix}/updateFromKvkStatus`, async (args: IUpdateFromKvkArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'PUT', `${baseUrl}/fromkvk/${args.id}`, args.entity)
})

export const addVve = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IVve, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'POST', baseUrl, entity)
})

export const addAptIndexenToVve = createAsyncThunk(`${basePrefix}/addIndexenStatus`, async (args: IKadastraleObjectenArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'POST', `${baseUrl}/${args.id}/appartementsindexen`, args.kadastraleObjecten)
})

export const addBronnenToVve = createAsyncThunk(`${basePrefix}/addBronnenStatus`, async (args: IKadastraleObjectenArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'POST', `${baseUrl}/${args.id}/bronnen`, args.kadastraleObjecten)
})

export const removeBronnenFromVve = createAsyncThunk(`${basePrefix}/removeBronnenStatus`, async (args: IKadastraleObjectenArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'DELETE', `${baseUrl}/${args.id}/bronnen`, args.kadastraleObjecten)
})

export const addKadastraalSubjectToVve = createAsyncThunk(`${basePrefix}/addKadastraalSubjectStatus`, async (args: IKadastraalSubjectArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'POST', `${baseUrl}/${args.vveId}/kadastraalsubject`, args.kadastraalSubjectId)
})

export const removeKadastraalSubjectFromVve = createAsyncThunk(`${basePrefix}/removeKadastraalSubjectStatus`, async (vveId: number, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'DELETE', `${baseUrl}/${vveId}/kadastraalsubject`)
})

export const removeAptIndexenFromVve = createAsyncThunk(`${basePrefix}/removeIndexenStatus`, async (args: IKadastraleObjectenArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'DELETE', `${baseUrl}/${args.id}/appartementsindexen`, args.kadastraleObjecten)
})

export const removeVves = createAsyncThunk(`${basePrefix}/removeVvesStatus`, async (args: IVveArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'DELETE', `${baseUrl}/${args.id}/vves`, args.onderliggendeVves)
})

export const addVves = createAsyncThunk(`${basePrefix}/addVvesStatus`, async (args: IVveArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'POST', `${baseUrl}/${args.id}/vves`, args.onderliggendeVves)
})

export const removeBovenliggendeVve = createAsyncThunk(`${basePrefix}/removeBovenliggendeVvesStatus`, async (vveId: number, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'DELETE', `${baseUrl}/${vveId}/bovenliggendeVve`)
})

export const addBovenliggendeVve = createAsyncThunk(`${basePrefix}/addBovenliggendeVvesStatus`, async (args: IVveArgs, thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'POST', `${baseUrl}/${args.id}/bovenliggendeVve`, args.onderliggendeVves[0])
})

export const deleteVves = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
    return await invokeFetch<IVve>(thunkAPI, 'DELETE', baseUrl, { vvesToDelete: entities })
})

const setPendingSate = (state: EntityState<IVve> & IEntityAdapterState) => {
    state.error = null
    state.status = 'pending'
}
const setSucceededState = (state: EntityState<IVve> & IEntityAdapterState) => {
    state.error = null
    state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IVve> & IEntityAdapterState, action) => {
    state.status = 'failed'
    state.error = action.error.message || null
}

export const Vves = createSlice({
    name: basePrefix,
    initialState: entityAdapter.getInitialState(vveInitState),

    reducers: {
        clearError: state => {
            state.error = null
        },
        select: (state, action: PayloadAction<string | undefined>) => {
            state.selectedId = action.payload
        },
        clearSelection: state => {
            state.selectedId = undefined
        },
        setSearchFilter: (state, action: PayloadAction<string>) => {
            state.searchFilter = action.payload.toUpperCase()
        },
        add: entityAdapter.addOne,
        modify: entityAdapter.upsertOne,
        removeMany: entityAdapter.removeMany,
        setAll: entityAdapter.setAll,
    },

    extraReducers: builder => {
        builder.addCase(fetchVves.pending, state => setPendingSate(state))
        builder.addCase(fetchVves.fulfilled, (state, action: PayloadAction<PagedEntities<IVve>>) => {
            setSucceededState(state)
            entityAdapter.setAll(state, action.payload.items)
        })
        builder.addCase(fetchVves.rejected, (state, action) => setRejectedState(state, action))
        builder.addCase(fetchVve.pending, state => setPendingSate(state))
        builder.addCase(fetchVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            setSucceededState(state)
            state.selectedId = action.payload.id.toString()
            entityAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(fetchVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(updateVveFromKvk.pending, state => setPendingSate(state))
        builder.addCase(updateVveFromKvk.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(updateVveFromKvk.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(updateVve.pending, state => setPendingSate(state))
        builder.addCase(updateVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(updateVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(addVve.pending, state => setPendingSate(state))
        builder.addCase(addVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            state.selectedId = action.payload.id.toString()
            setSucceededState(state)
        })
        builder.addCase(addVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(deleteVves.pending, state => setPendingSate(state))
        builder.addCase(deleteVves.fulfilled, (state, action) => {
            entityAdapter.removeMany(state, action.meta.arg)
            setSucceededState(state)
        })
        builder.addCase(deleteVves.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(addBronnenToVve.pending, state => setPendingSate(state))
        builder.addCase(addBronnenToVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(addBronnenToVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(removeBronnenFromVve.pending, state => setPendingSate(state))
        builder.addCase(removeBronnenFromVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(removeBronnenFromVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(addAptIndexenToVve.pending, state => setPendingSate(state))
        builder.addCase(addAptIndexenToVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(addAptIndexenToVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(removeAptIndexenFromVve.pending, state => setPendingSate(state))
        builder.addCase(removeAptIndexenFromVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(removeAptIndexenFromVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(removeVves.pending, state => setPendingSate(state))
        builder.addCase(removeVves.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(removeVves.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(addVves.pending, state => setPendingSate(state))
        builder.addCase(addVves.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(addVves.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(removeBovenliggendeVve.pending, state => setPendingSate(state))
        builder.addCase(removeBovenliggendeVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            // action.payload.bovenliggendeVve = undefined
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(removeBovenliggendeVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(addBovenliggendeVve.pending, state => setPendingSate(state))
        builder.addCase(addBovenliggendeVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(addBovenliggendeVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(removeKadastraalSubjectFromVve.pending, state => setPendingSate(state))
        builder.addCase(removeKadastraalSubjectFromVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(removeKadastraalSubjectFromVve.rejected, (state, action) => setRejectedState(state, action))

        builder.addCase(addKadastraalSubjectToVve.pending, state => setPendingSate(state))
        builder.addCase(addKadastraalSubjectToVve.fulfilled, (state, action: PayloadAction<IVve>) => {
            entityAdapter.upsertOne(state, action.payload)
            setSucceededState(state)
        })
        builder.addCase(addKadastraalSubjectToVve.rejected, (state, action) => setRejectedState(state, action))
    },
})

export const getSelectedEntity = (state: RootState) => {
    const selectedId = getSliceState(state).selectedId
    if (selectedId) return getSliceState(state).entities[selectedId]
    else return { id: 0 } as IVve
}

export const getLoadingState = (state: RootState) => {
    return getSliceState(state).status
}
export const getErrorState = (state: RootState) => {
    return getSliceState(state).error
}

export const { add, modify, removeMany, select, clearSelection, clearError, setSearchFilter, setAll } = Vves.actions
export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export const getVvesAsOptions = (state: RootState) => {
    const options: IDropdownOption[] = []
    getSliceState(state).ids.forEach(id => {
        options.push({ key: id, text: getSliceState(state).entities[id]?.handelsnaam } as IDropdownOption)
    })
    return options
}

export const getFilterState = (state: RootState) => {
    return getSliceState(state).searchFilter
}

export default Vves.reducer
