import FluentTable from 'components/FluentTable'
import { createBooleanColumn, createColumn } from 'lib/gridHelper'
import { getPropertyName } from 'lib/interfaceUtils'
import { Link, Spinner, SpinnerSize, StackItem } from '@fluentui/react'
import ReadOnlyField, { ReadOnlyDate } from '../../components/ReadOnlyField'
import VgtControlGroup from 'components/ControlGroup'
import { z } from 'zod'
import { VheListDto, VheVgeListDto, zodiosHooks } from 'api/ApiClient'
import { useNavigate, useParams } from 'react-router-dom'
import { PageIdDefinition, PAGES } from 'pages'

type VheListDtoType = z.infer<typeof VheListDto>
type VgeListDtoType = z.infer<typeof VheVgeListDto>

const EenOpEen = ({ vhes }) => {
  const vhe = vhes?.length > 0 ? vhes[0] : {}
  return (
    <div style={{ marginRight: 15 }}>
      <VgtControlGroup name='group1' label='Verhuureenheid 1-op-1'>
        <StackItem>
          <VheView data={vhe} />
        </StackItem>
      </VgtControlGroup>
    </div>
  )
}

const Gesplitst = ({ vhes }) => {
  const navigate = useNavigate()
  const inputLinkRender = (item: VheListDtoType) => {
    if (item?.id) {
      return (
        <Link key={item?.id} onClick={() =>
          navigate(PAGES[PageIdDefinition.beheer.vhe.edit].route.replace(':id', item.id.toString()))}>{item?.code}
        </Link>
      )
    }
    return (
      <p>{item?.code}</p>
    )
  }
  const cols = [
    createColumn('Code', getPropertyName<VheListDtoType>('code'), 'M', true, inputLinkRender),
    createColumn('Eenheiddetailsoort', getPropertyName<VheListDtoType>('detailsoort')),
    createColumn('Activatiedatum', getPropertyName<VheListDtoType>('activatieDatum')),
    createColumn('In exploitatie', getPropertyName<VheListDtoType>('inExploitatieDatum')),
    createColumn('Uit exploitatie', getPropertyName<VheListDtoType>('uitExploitatieDatum')),
    createColumn('Adres', getPropertyName<VheListDtoType>('straat')),
    createColumn('Adresaanduiding', getPropertyName<VheListDtoType>('adresaanduiding'))
  ]

  return (
    <div style={{ marginRight: 15 }}>
      <h3>Gesplitst in verhuureenheden</h3>
      <div style={{ display: 'flex', height: '70vh' }}>
        <FluentTable columns={cols} items={vhes ?? []} onItemInvoked={r => alert(r)} onGetKey={r => r.id} />
      </div>
    </div>)
}

const Gegroepeerd = ({ vhes }) => {
  const navigate = useNavigate()
  const inputLinkRender = (item: VgeListDtoType) => {
    if (item?.id) {
      return (
        <Link key={item?.id} onClick={() => {
          navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', item.id.toString()))
          window.location.reload()
        }}>{item?.id}
        </Link>
      )
    }
    return (
      <p>{item?.id}</p>
    )
  }
  const vgeCols = [
    createColumn('VGE Code', getPropertyName<VgeListDtoType>('id'), 'M', true, inputLinkRender),
    createColumn('Adres', getPropertyName<VgeListDtoType>('adres'), 'XXL'),
    createColumn('Aanduiding', getPropertyName<VgeListDtoType>('adresaanduiding'), 'M'),
    createColumn('Eenheiddetailsoort', getPropertyName<VgeListDtoType>('detailsoort'), 'M'),
    createBooleanColumn('Collectief', getPropertyName<VgeListDtoType>('isCollectief'), 'S')
  ]
  const vhe = vhes?.length > 0 ? vhes[0] : {} as VheListDtoType
  const { data: vgeData, isLoading } = zodiosHooks.useGetVhesIdvges({
    params: {
      id: vhe.id
    }
  })
  if (isLoading)
    return (
      <Spinner size={SpinnerSize.large} />
    )
  else
    return (
      <div style={{ marginRight: 15 }}>
        <VgtControlGroup name='group1' label='Verhuureenheid gegroepeerd'>
          <StackItem>
            <VheView data={vhe} />
          </StackItem>
        </VgtControlGroup>
        <VgtControlGroup name='group2' label='Vastgoedeenheden'>
          <div style={{ display: 'flex', height: '70vh' }}>
            <FluentTable
              columns={vgeCols}
              items={vgeData!.items ?? []}
              onItemInvoked={r => navigate(PAGES[PageIdDefinition.vge.edit].route.replace(':id', r.toString()))}
              onGetKey={r => r.id} />
          </div>
        </VgtControlGroup>
      </div>
    )
}

const VerhuureenheidTab = ({ verhuurOptie }) => {
  const { id } = useParams()
  // validate router param
  const idAsNumber = parseInt(id ?? '')
  if (isNaN(idAsNumber)) {
    throw new Error('Invalid id in url')
  }
  const { data: vgeVhesData, isLoading } = zodiosHooks.useGetVgeIdvhes({
    params: {
      id: idAsNumber
    }
  })
  if (!isLoading) {
    return (
      <>
        {verhuurOptie === 2 && <EenOpEen vhes={vgeVhesData} />}
        {verhuurOptie === 3 && <Gesplitst vhes={vgeVhesData} />}
        {verhuurOptie === 4 && <Gegroepeerd vhes={vgeVhesData} />}
      </>
    )
  } else {
    return (
      <Spinner size={SpinnerSize.large} />
    )
  }
}

export const VheView = ({ data }: {
  data?: VheListDtoType,
}) => {
  const navigate = useNavigate()
  const inputLinkRender = () => {
    if (data?.id) {
      return (
        <Link key={data?.id} onClick={() =>
          navigate(PAGES[PageIdDefinition.beheer.vhe.edit].route.replace(':id', data.id.toString()))}>{data?.code}
        </Link>
      )
    }
    return (
      <p>{data?.code}</p>
    )
  }

  return (<>
      <ReadOnlyField title='VHE-Code' value={data?.code} labelWidth={174} onInputRender={
        inputLinkRender
      } />
      <ReadOnlyField title='Eenheiddetailsoort' value={data?.detailsoort} />
      <ReadOnlyDate title='Activatiedatum' value={data?.activatieDatum!} />
      <ReadOnlyDate title='In exploitatiedatum' value={data?.inExploitatieDatum!} />
      <ReadOnlyDate title='Uit exploitatiedatum' value={data?.uitExploitatieDatum!} />
      <ReadOnlyField title={'Adres'} value={data?.adres} />
      <ReadOnlyField title='Adresaanduiding' value={data?.adresaanduiding} />
    </>
  )
}
export default VerhuureenheidTab