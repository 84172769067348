import React, { useState } from 'react'
import {
  DefaultButton,
  DetailsList,
  IColumn,
  mergeStyleSets,
  Panel,
  PanelType,
  SearchBox,
  Selection,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Checkbox,
  ICheckboxStyles, IObjectWithKey
} from '@fluentui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { gebouwSchema, IGebouw } from './gebouwenSlice'
import { debounce } from 'lodash'
import { clearResults, fetchNogInTeDelen, fetchNogInTeDelenZonderAdres, selectAll } from './inTeDelenVgesSlice'
import { IVge } from 'features/vge/vgeSlice'
import { getPropertyName } from 'lib/interfaceUtils'
import { createColumn, createCustomRenderedColumn } from 'lib/gridHelper'

interface IEditPanelProps {
  isOpen: boolean
  dismissPanel: any
  onConnectVges: any
  withoutAdres?: boolean
  isTerrein?: boolean
  linkVgeContracten?: boolean
  onLinkVgeContractenChanged?: (link: boolean) => void
}

const classNames = mergeStyleSets({
  wrapper: {
    position: 'relative',
    margin: '10px',
  },
  filter: {
    backgroundColor: 'white',
    paddingBottom: 20,
    maxWidth: 300,
  },
  header: {
    margin: 0,
    backgroundColor: 'white',
  },
  row: {
    display: 'inline-block',
  },
})

const checkboxStyles: Partial<ICheckboxStyles> = {
  root: {
    padding: '5px 0px 5px 12px',
    borderBottom: '1px solid rgb(219 219 219)',
  },
  text: {
    width: 215,
    fontWeight: 'normal',
  },
  checkbox: {
    marginLeft: 0,
  },
}

const SelectAdressenPanel: React.FC<IEditPanelProps> = props => {
  const items = useSelector(selectAll)
  const dispatch = useDispatch()
  const [currentSearchValue, setCurrentSearchValue] = useState<string | undefined>('')

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<IGebouw>({ resolver: yupResolver(gebouwSchema), mode: 'onChange' })

  const onLinkContractChanged = (checked?: boolean) => {
    if (props.onLinkVgeContractenChanged) props.onLinkVgeContractenChanged(checked ?? false)
  }

  const clearSearch = () => {
    setCurrentSearchValue('')
  }
  const closePanel = () => {
    clearSearch()
    dispatch(clearResults())
    props.dismissPanel()
  }
  const onCancel = e => {
    closePanel()
  }
  const onSubmit = () => {
    if (selection.count > 0) {
      const items = selection.getSelection()
      props.onConnectVges(items.map(vge => vge.id))
      closePanel()
    }
  }

  const [selection] = React.useState(() => {
    const s = new Selection<IVge>({
      selectionMode: SelectionMode.multiple,
      onSelectionChanged: () => selection.count,
      getKey: item => item.id,
    })

    return s
  })

  const delayedSearch = debounce(eventData => dispatch(props.withoutAdres ? fetchNogInTeDelenZonderAdres(eventData) : fetchNogInTeDelen(eventData)), 500)

  const onSearchBoxChanged = (newValue: string | undefined) => {
    setCurrentSearchValue(newValue)
    if (newValue && newValue.length >= 2) {
      delayedSearch(newValue)
    }
  }

  const onClearSearchBox = () => {
    clearSearch()
  }

  const columns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srt'), 120),
    createCustomRenderedColumn(
      'Adres',
      getPropertyName<IVge>('str'),
      item => (
        <div>
          {item.str} {item.nr} {item.ltr} {item.nrT} {item.pc} {item.wpl}
        </div>
      ),
      'XL',
      false
    ),
    createColumn('Adresaanduiding', getPropertyName<IVge>('aa')),
  ]

  const terreinColumns: IColumn[] = [
    createColumn('Eenheiddetailsoort', getPropertyName<IVge>('srt'), 120),
    createCustomRenderedColumn(
      'Adres',
      getPropertyName<IVge>('str'),
      item => (
        <div>
          {item.str} {item.nr} {item.ltr} {item.nrT} {item.pc} {item.wpl}
        </div>
      ),
      'XL',
      false
    ),
    createColumn('Terreinclassificatie', getPropertyName<IVge>('trnNm')),
  ]

  const onRenderFooterContent = () => {
    return (
      <>
          <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
            <DefaultButton text="Koppelen" type="submit" primary disabled={isSubmitting} onClick={onSubmit} />
            <DefaultButton text="Annuleren" onClick={onCancel} />
          </Stack>
      </>
    )
  }

  return (
    <Panel
      type={PanelType.medium}
      headerText={`Koppelen vastgoedeenheden ${props.withoutAdres ? 'zonder' : 'met'} adres`}
      isOpen={props.isOpen}
      onDismiss={onCancel}
      closeButtonAriaLabel="Sluiten"
      onRenderFooterContent={onRenderFooterContent}
    >
      <div className={classNames.wrapper}>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Sticky stickyPosition={StickyPositionType.Header}>
            <SearchBox placeholder="Zoek op adres" onChanged={onSearchBoxChanged} onClear={onClearSearchBox} value={currentSearchValue} autoComplete="off" />
          </Sticky>

          {props.onLinkVgeContractenChanged && (
            <>
              <br />
              <Checkbox
                label="Koppel onderhoudscontract(en)"
                name="linkContracten"
                boxSide="end"
                styles={checkboxStyles}
                onChange={(_, checked) => onLinkContractChanged(checked)}
                defaultChecked={props.linkVgeContracten ?? false}
              />
              <br />
            </>
          )}

          <DetailsList items={items} selection={selection as Selection<IObjectWithKey>} columns={props.isTerrein ? terreinColumns : columns}></DetailsList>
          <br />
          <br />
        </form>
      </div>
    </Panel>
  )
}

export default SelectAdressenPanel
