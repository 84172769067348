import { DefaultButton, Panel, PanelType, Stack } from "@fluentui/react"
import VgtTextField from "components/VgtTextField"
import { getPropertyName } from "lib/interfaceUtils"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { useSelector } from "react-redux"
import { useAppDispatch } from "store"
import { 
    getLoadingState, 
    IDefectoorzaak, 
    defectoorzaakSchema, getSelectedEntity, updateDefectoorzaak, addDefectoorzaak } from "./defectoorzaakSlice"
import { useEffect, useState } from "react"
import useRoles from "services/UseRoles";

interface IEditPanelProps {
    isOpen: boolean
    dismissPanel: any
}

export const DefectoorzaakEditPanel: React.FC<IEditPanelProps> = props => {
    const dispatch = useAppDispatch()
    const [title, setTitle] = useState('')
    const [closeOnFulFilled, setCloseOnFulFilled] = useState(false)
    const fetchStatus = useSelector(getLoadingState)
    const selectedEntity = useSelector(getSelectedEntity)
    const { isAdmin } = useRoles()

    useEffect(() => {
        setCloseOnFulFilled(false)
    }, []);

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors },
    } = useForm<IDefectoorzaak>({ resolver: yupResolver(defectoorzaakSchema), mode: 'all' })

    useEffect(() => {
        setTitle(`Defectoorzak ${selectedEntity ? isAdmin ? 'wijzigen' : '' : 'toevoegen'}`)
        if (selectedEntity) {
            reset(selectedEntity)
        } else {
            reset({})
        }
    }, [selectedEntity]) 

    const onCancel = e => { props.dismissPanel() }

    const onSubmit = (data: IDefectoorzaak) => {
        if (selectedEntity) {
            data.defectoorzaakId = selectedEntity.defectoorzaakId
            dispatch(updateDefectoorzaak(data))
            setCloseOnFulFilled(true)
        } else {
            dispatch(addDefectoorzaak(data))
            setCloseOnFulFilled(true)
        }
    }

    useEffect(() => {
        if (closeOnFulFilled && fetchStatus === 'succeeded') props.dismissPanel() 
    }, [fetchStatus])

    return (
        <div>
            <Panel type={PanelType.large} headerText={title} isOpen={props.isOpen} onDismiss={onCancel} closeButtonAriaLabel="Sluiten">
                <br />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VgtTextField label="Code" name={getPropertyName<IDefectoorzaak>('code')} register={register} control={control} errors={errors} required={isAdmin ? true : false} readOnly={isAdmin ? false : true}/>
                    <VgtTextField label="Naam" name={getPropertyName<IDefectoorzaak>('naam')} register={register} control={control} errors={errors} required={isAdmin ? true : false} multiline autoAdjustHeight readOnly={isAdmin ? false : true}/>
                    <VgtTextField label="Omschrijving" name={getPropertyName<IDefectoorzaak>('omschrijving')} register={register} control={control} errors={errors} required={isAdmin ? true : false} autoAdjustHeight readOnly={isAdmin ? false : true}/>
                    <br />
                    { isAdmin ? 
                    <Stack horizontal wrap horizontalAlign={'end'} tokens={{ childrenGap: '10 10' }}>
                        <DefaultButton text="Opslaan" type="submit" primary disabled={isSubmitting} onClick={handleSubmit(onSubmit)} />
                        <DefaultButton text="Annuleren" onClick={onCancel} />
                    </Stack> : null}
                </form>
            </Panel>
        </div>
    )    
}