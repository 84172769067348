import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import { PagedEntities } from 'interfaces/pagedEntities'
import { IEntityAdapterState } from 'interfaces/entityAdapterState'

import { IDropdownOption } from '@fluentui/react'

export interface IRechtspersoon {
  id: number
  handelsnaam: string
  externeReferentie: string
  rolId: number
  rolNaam: string
  gln: string
  kvkNummer: string
  vestigingsnummer: string
  rsin: string
  straat?: string
  woonplaats?: string
  postcode?: string
  nummeraanduidingId?: string
  landCode: string
  emailadres?: string
  website?: string
  telefoonnummer?: string
  contactpersoon?: string
  geblokkeerd: boolean
  intakeDoorCorporatie: boolean
}

export enum RelatieRolIds {
  rechtspersoon = 0,
  leverancier = 1,
  klant = 2,
  vve = 3,
  eigenaar = 4,
  beheerder = 5,
}

export interface IRechtspersoonState {
  selectedId: string | undefined
  status: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null
  searchFilter: string
}

export const rechtspersoonInitState: IRechtspersoonState = {
  selectedId: undefined,
  status: 'idle',
  error: null,
  searchFilter: '',
}

const entityAdapter = createEntityAdapter<IRechtspersoon>({
  sortComparer: (a, b) => a.handelsnaam?.localeCompare(b.handelsnaam),
})

const baseUrl = '/rechtspersonen'
const basePrefix = 'rechtspersonen'
const getSliceState = (state: RootState) => state.rechtspersonen

export interface IFetchRechtsPersonenArgs {
  filter?: string
  relatieRol?: string
}

export const fetchRechtspersonen = createAsyncThunk(`${basePrefix}/fetchStatus`, async (args: IFetchRechtsPersonenArgs, thunkAPI) => {
  let uri: string
  if (args.relatieRol) uri = `${baseUrl}?Filter=${args.filter}&relatieRol=${args.relatieRol}`
  else uri = `${baseUrl}?Filter=${args.filter}`
  return await invokeFetch<PagedEntities<IRechtspersoon>>(thunkAPI, 'GET', uri)
})

export const updateRechtspersoon = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IRechtspersoon, thunkAPI) => {
  return await invokeFetch<IRechtspersoon>(thunkAPI, 'PUT', `${baseUrl}/${entity.id}`, entity)
})

export const addRechtspersoon = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IRechtspersoon, thunkAPI) => {
  return await invokeFetch<IRechtspersoon>(thunkAPI, 'POST', baseUrl, entity)
})

export const deleteRechtspersonen = createAsyncThunk(`${basePrefix}/deleteStatus`, async (entities: number[], thunkAPI) => {
  return await invokeFetch(thunkAPI, 'DELETE', baseUrl, entities)
})

const setPendingSate = (state: EntityState<IRechtspersoon> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: EntityState<IRechtspersoon> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IRechtspersoon> & IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const rechtspersonen = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(rechtspersoonInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.searchFilter = action.payload.toUpperCase()
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll,
  },

  extraReducers: builder => {
    builder.addCase(fetchRechtspersonen.pending, state => setPendingSate(state))
    builder.addCase(fetchRechtspersonen.fulfilled, (state, action: PayloadAction<PagedEntities<IRechtspersoon>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchRechtspersonen.rejected, (state, action) => setRejectedState(state, action))

    builder.addCase(updateRechtspersoon.pending, state => setPendingSate(state))
    builder.addCase(updateRechtspersoon.fulfilled, (state, action: PayloadAction<IRechtspersoon>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(updateRechtspersoon.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(addRechtspersoon.pending, state => setPendingSate(state))
    builder.addCase(addRechtspersoon.fulfilled, (state, action: PayloadAction<IRechtspersoon>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(addRechtspersoon.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(deleteRechtspersonen.pending, state => setPendingSate(state))
    builder.addCase(deleteRechtspersonen.fulfilled, (state, action) => {
      entityAdapter.removeMany(state, action.meta.arg)
      setSucceededState(state)
    })
    builder.addCase(deleteRechtspersonen.rejected, (state, action) => setRejectedState(state, action))
  },
})

export const { selectAll, selectEntities, selectById } = entityAdapter.getSelectors<RootState>(state => getSliceState(state))

export const getRechtspersonenAsOptions = (state: RootState, roleId?: RelatieRolIds) => {
  const options: IDropdownOption[] = []
  const persoonState = getSliceState(state)
  persoonState.ids.forEach(id => {
    const persoon = persoonState.entities[id]
    if (persoon && (!roleId || roleId === persoon.rolId)) options.push({ key: id, text: persoon.handelsnaam } as IDropdownOption)
  })
  return options
}

export const getRechtspersonen = (state: RootState, roleId: RelatieRolIds) => {
  const personen = [] as IRechtspersoon[]
  const persoonState = getSliceState(state)
  persoonState.ids.forEach(id => {
    const persoon = persoonState.entities[id]
    if (persoon && (!roleId || roleId === persoon.rolId)) personen.push(persoon)
  })
  return personen
}

export default rechtspersonen.reducer
