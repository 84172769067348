import useRoles from '../../../../services/UseRoles'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { DefectoorzaakKostensoortAddOrUpdateDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import { IDropdownOption, Panel, PanelType } from '@fluentui/react'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getPropertyName } from '../../../../lib/interfaceUtils'
import VgtDropdown from '../../../../components/FluentDropdown'
import { addZeroDropdownOption } from '../../../../lib/controlSupport'
import { OkCancelButtonStack } from '../../../../components/OkCancelButtonStack/OkCancelButtonStack'

export interface IEditPanelProps {
  defectoorzaakId: number,
  kostensoortId: number | undefined,
  isOpen: boolean
  dismissPanel: any
  invalidate: () => void
}

export type CreateUpdateType = z.infer<typeof DefectoorzaakKostensoortAddOrUpdateDto>;

export const DefectoorzaakKostensoortFormPanel: React.FC<IEditPanelProps> = ({
                                                                               defectoorzaakId,
                                                                               kostensoortId,
                                                                               dismissPanel,
                                                                               isOpen,
                                                                               invalidate
                                                                             }) => {
  const { isReparatieboom } = useRoles()
  const [error, setError] = React.useState<string>()

  const defaultDefectoorzaakKostensoort = {
    defectoorzaakId: defectoorzaakId,
    kostensoortId: kostensoortId
  } as CreateUpdateType

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors }
  } = useForm<CreateUpdateType>({
    resolver: zodResolver(DefectoorzaakKostensoortAddOrUpdateDto),
    mode: 'all',
    defaultValues: defaultDefectoorzaakKostensoort
  })

  const {
    mutate: addDefectoorzaakKostensoort,
    isLoading: isPostingDefectoorzaakKostensoort
  } = zodiosHooks.usePostDefectoorzaakKostensoort(
    {}, { onSuccess: () => onCancel(), onError: (error) => setError(getTitleAndMessage(error).message) }
  )

  const {
    mutate: updateDefectoorzaakKostensoort,
    isLoading: isUpdatingDefectoorzaakKostensoort
  } = zodiosHooks.usePutDefectoorzaakKostensoortId({ params: { id: defectoorzaakId } }, {
      onSuccess: () => onCancel(),
      onError: (error) => setError(getTitleAndMessage(error).message)
    }
  )

  const onCancel = () => {
    invalidate()
    reset({})
    setError('')
    dismissPanel()
  }

  useEffect(() => {
    if (kostensoortId) {
      reset({
        defectoorzaakId: defectoorzaakId,
        kostensoortId: kostensoortId
      })
    } else {
      reset({})
    }
  }, [kostensoortId])

  const onSubmit = (data: CreateUpdateType) => {
    if(data.kostensoortId === 0){
      data.kostensoortId = null
    }

    if (!kostensoortId) {
      updateDefectoorzaakKostensoort(data)
    } else {
      addDefectoorzaakKostensoort(data)
    }
  }

  const { data: defectoorzaak } = zodiosHooks.useGetDefectoorzaak({})
  const defectoorzaakOpties = defectoorzaak?.items?.map((item) => {
    return {
      key: item.defectoorzaakId,
      text: item.naam
    } as IDropdownOption
  })

  const { data: kostensoort } = zodiosHooks.useGetKostensoort({})
  const kostensoortOpties = kostensoort?.items?.map((item) => {
    return {
      key: item.kostensoortId,
      text: `${item.code} - ${item.omschrijving}`
    } as IDropdownOption
  }) || []

  const kostensoortOptions = useMemo(() => addZeroDropdownOption(kostensoortOpties), [kostensoortOpties])

  return (
    <div>
      <Panel type={PanelType.large}
             headerText={'Defectoorzaak Kostensoort wijzigen'} isOpen={isOpen}
             onDismiss={onCancel}
             closeButtonAriaLabel='Sluiten'>
        <br />
        <ErrorMessageBar error={error} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <VgtDropdown
            label='Defectoorzaak'
            name={getPropertyName<CreateUpdateType>('defectoorzaakId')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isReparatieboom}
            options={defectoorzaakOpties}
            defaultSelectedKey={defectoorzaakId}
          />
          <VgtDropdown
            label='Kostensoort'
            name={getPropertyName<CreateUpdateType>('kostensoortId')}
            register={register}
            control={control}
            errors={errors}
            required
            readOnly={!isReparatieboom}
            options={kostensoortOptions}
            defaultSelectedKey={kostensoortId ?? 0}
          />
          <OkCancelButtonStack
            isSubmitting={isSubmitting}
            isLoading={isPostingDefectoorzaakKostensoort || isUpdatingDefectoorzaakKostensoort}
            onOkClick={handleSubmit(onSubmit)}
            onCancelClick={onCancel}
            isWriter={isReparatieboom}
          />
        </form>
      </Panel>
    </div>
  )
}