import React, { useEffect, useMemo, useState } from 'react'
import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogType,
  IButtonStyles,
  Icon,
  IconButton,
  IDialogContentProps,
  IDialogStyles,
  IIconStyles,
  Image,
  IModalProps,
  ISpinnerStyles,
  IStackStyles,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import { useDropzone } from 'react-dropzone'
import { DocumentListDtoType } from './DocumentList'
import downloadFile from '../../../services/downloadFile'
import { Document, Page } from 'react-pdf'
import { SharedColors } from '@fluentui/theme'
import fetchUtils from '../../../services/fetchUtils'
import 'pdfjs-dist/webpack'

interface IFilesDetails {
  id: number,
  extensie: string,
  bestandsnaam: string
}

interface FileViewerProps {
  closeDialog: () => void;
  documentList: DocumentListDtoType;
  data: IFilesDetails[];
}

const dropZoneStyles: React.CSSProperties = {
  color: '#bdbdbd',
  minHeight: 125,
  maxHeight: '90%'
}

const dialogStyles: Partial<IDialogStyles> = {
  main: {
    maxHeight: '90%'
  }
}

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

const iconStyles: Partial<IIconStyles> = {
  root: {
    fontSize: 100,
    color: SharedColors.red10,
    margin: '0 auto'
  }
}

const carouselStyles: Partial<IStackStyles> = {
  root: {
    height: '80%',
    overflow: 'auto'
  }
}

const indicatorsStackStyles: Partial<IStackStyles> = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    position: 'fixed',
    bottom: '1px',
    left: '50%',
    translate: '-50% -1rem',
    overflow: 'auto',
    marginTop: '1rem'
  }
}

const leftArrowStyles: Partial<IButtonStyles> = {
  root: {
    position: 'fixed',
    width: '4rem',
    height: '4rem',
    color: SharedColors.red20,
    top: '50%',
    translate: '-50%',
    backgroundColor: '#fff',
    left: '9%'
  },
  rootDisabled: {
    position: 'fixed',
    cursor: 'not-allowed',
  },
  icon: {
    fontSize: '3rem'
  }
}

const rightArrowStyles: Partial<IButtonStyles> = {
  root: {
    position: 'fixed',
    width: '4rem',
    height: '4rem',
    color: SharedColors.red20,
    top: '50%',
    translate: '-50%',
    right: '5%',
    backgroundColor: '#fff'
  },
  rootDisabled: {
    position: 'fixed',
    cursor: 'not-allowed'
  },
  icon: {
    fontSize: '3rem'
  }
}

const indicatorStyles: Partial<IButtonStyles> = {
  root: {
    backgroundColor: '#fff',
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    border: 'none',
    outline: 'none',
    margin: ' 0 0.2rem',
    cursor: 'pointer'
  }
}

const indicatorInactiveStyles: Partial<IButtonStyles> = {
  root: {
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    border: 'none',
    outline: 'none',
    margin: ' 0 0.2rem',
    cursor: 'pointer',
    backgroundColor: SharedColors.red10
  }
}

const FileViewer: React.FC<FileViewerProps> = ({ closeDialog, documentList, data }) => {

  const [numPages, setNumPages] = useState<number>()
  const [url, setUrl] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const [slide, setSlide] = useState(
    data.findIndex((item) => item.id === documentList.documentId)
  )

  const fileName = (item: IFilesDetails): string => (item.bestandsnaam ?? 'document') + (item.extensie ?? '.txt')

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages)
  }

  const nextSlide = () => {
    setSlide(slide === data.length - 1 ? 0 : slide + 1)
  }

  const prevSlide = () => {
    setSlide(slide === 0 ? data.length - 1 : slide - 1)
  }
  const isImageExtension = (extensie: string) => {
    return ['.jpeg', '.jpg', '.png', 'gif', 'svg', 'webp'].includes(extensie.toLowerCase())
  }

  const isPDFExtension = (extensie: string) => {
    return ['.pdf'].includes(extensie.toLowerCase())
  }

  const { getRootProps } = useDropzone({
    multiple: true
  })

  const modalProps = useMemo(
    (): IModalProps => ({
      isBlocking: true,
      layerProps: { eventBubblingEnabled: true }
    }),
    []
  )

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      title: <IconButton text="Downloaden" iconProps={{ iconName: 'Download', title: 'Bestand downloaden' }}
                         onClick={() => downloadFile(`/api/documents/${data[slide].id}/download`, fileName(data[slide]))} />
    }),
    [slide]
  )

  const fetchAndSetData = async (fileId: number) => {
    setLoading(true)
    const response = await fetchUtils(`/api/documents/${fileId}/download`)
    setUrl(response)
    setLoading(false)
  }

  useEffect(() => {
    fetchAndSetData(data[slide].id).then(r => r)
  }, [slide, data])

  if (loading) {
    return <Spinner size={SpinnerSize.large} styles={spinnerStyles} />
  }


  return (
    <Dialog hidden={false} onDismiss={closeDialog} modalProps={modalProps} minWidth={'30%'} maxWidth={'70%'}
            styles={dialogStyles} dialogContentProps={dialogContentProps}>
      <DialogContent styles={{ header: { height: 0, width: 0, display: 'none' }, inner: { padding: 0 } }}>
        <div
          {...getRootProps({
            className: 'dropzone',
            style: dropZoneStyles
          })}
        >
          <Stack styles={carouselStyles} tokens={{ childrenGap: 10 }}>
            <IconButton iconProps={{ iconName: 'ChevronLeft' }}
                        title="Previous File" ariaLabel="Prev File"
                        onClick={slide === 0 ? undefined : prevSlide}
                        styles={leftArrowStyles}
                        disabled={slide === 0}
            />
            <Stack>
              {data?.map((item: IFilesDetails, index: number) => {
                const isActive = slide === index
                if (isActive) {
                  if (isImageExtension(item.extensie)) {
                    return (
                      <Image
                        src={url}
                        alt={item.bestandsnaam}
                        width={'100%'}
                        height={'auto'}
                        key={item.id}
                      />
                    )
                  } else if (isPDFExtension(item.extensie)) {
                    return (
                      <Document
                        file={url}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={'Please wait!'}
                        error={<Icon iconName={'FileBug'} styles={iconStyles} />}
                        key={item.id}
                      >
                        {Array(...Array(numPages)).map((x, i) => (
                          <Page
                            key={i + 1}
                            pageNumber={i + 1}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            width={950}
                          />
                        ))}
                      </Document>
                    )
                  } else {
                    return (
                      <Stack style={{ margin: '0 auto' }} key={item.id}>
                        <Icon iconName={'FileBug'} styles={iconStyles} />
                        <Text style={{ marginTop: 10, fontWeight: '700' }}>
                          Voor dit type bijlage hebben we geen previewbeschikbaar
                        </Text>
                        <DefaultButton
                          style={{ marginTop: 10 }}
                          text="Downloaden bijlage"
                          title={'Bestand downloaden'}
                          onClick={_ => downloadFile(`/api/documents/${item.id}/download`, fileName(item))}
                        />
                      </Stack>
                    )
                  }
                }
                return null
              })}
            </Stack>
            <IconButton iconProps={{ iconName: 'ChevronRight' }} title="Next File" ariaLabel="Next File"
                        onClick={slide === data.length - 1 ? undefined : nextSlide}
                        styles={rightArrowStyles}
                        disabled={slide === data.length - 1}
            />
            <Stack styles={indicatorsStackStyles}>
              {data?.map((item: IFilesDetails, index: number) => {
                return (
                  <IconButton
                    key={item.id}
                    styles={slide === index ? indicatorStyles : indicatorInactiveStyles}
                    onClick={() => setSlide(index)}
                  />
                )
              })}
            </Stack>
          </Stack>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default FileViewer