import { Dialog, IDialogContentProps, Link } from "@fluentui/react"
import { IVge } from "./vgeSlice"

export const VheSelectionPanel: React.FC<{ baseUrl: string | undefined, vge?: IVge, isOpen: boolean, dismissPanel: () => void }> = ({ isOpen, dismissPanel, vge, baseUrl }) => {

    const cleanUp = () => {
        dismissPanel()
    }

    const vhes = vge?.vhes ?? [] as string[]
    const sortedVhes = [...vhes].sort((a, b) => a.localeCompare(b))

    const dialogContentProps: IDialogContentProps = { title: "Navigeren verhuureenheid", subText: "Meerdere vhe's gevonden bij deze vastgoedeenheid. Klik op de gewenste link om de verhuuradministratie te openen." }

    return (
        <Dialog
            dialogContentProps={dialogContentProps}
            hidden={!isOpen}
            onDismiss={() => cleanUp()} closeButtonAriaLabel="Sluiten"
        >
            {
                sortedVhes.map(vhe => <div key={vhe}><Link rel="noreferrer" onClick={() => dismissPanel()} href={`${baseUrl}${vhe}`} target='_blank' >{vhe}</Link></div>)
            }

        </Dialog>
    )
}