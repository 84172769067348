import { Link } from '@fluentui/react'
import { getPropertyName } from 'lib/interfaceUtils'
import React from 'react'
import { createColumn, createDatumColumns } from '../common/columnUtils'
import LemdoList from '../common/lemdoEntityList'
import ITaak, { taakSchema } from './model'
import { add, clearSelection, fetchAll, getSelectedEntity, modify, removeMany, select, selectAll } from './slice'
import { ILEmDOFieldsProps } from '../common/lemdoEditPanel'
import VgtTextField from 'components/VgtTextField'
import useRoles from 'services/UseRoles'

const createColumns = (clickHandler: (id: number) => void) => {
  return [
    createColumn<ITaak>(
      'naam',
      {
        minWidth: 500,
        maxWidth: 800,
        onRender: (item: ITaak) => (
          <Link key={item.naam} onClick={() => clickHandler(item.id)}>
            {item.naam}
          </Link>
        )
      },
      'Naam'
    )
  ].concat(createDatumColumns())
}

const TaakFields: React.FC<ILEmDOFieldsProps> = props => {
  const { isAdmin } = useRoles()
  return <VgtTextField label="Naam" name={getPropertyName<ITaak>('naam')} register={props.register}
                       control={props.control} errors={props.errors} required readOnly={!isAdmin} />
}

const TaakList: React.FC<{}> = () => {
  return (
    <LemdoList
      title="Reparatieboom-taken"
      subTitle="Overzicht"
      stateIdentifier="Taak"
      selectAll={selectAll}
      add={add}
      modify={modify}
      clearSelection={clearSelection}
      fetchAll={fetchAll}
      getSelectedEntity={getSelectedEntity}
      select={select}
      renderFields={props => <TaakFields {...props} />}
      removeMany={removeMany}
      entitySchema={taakSchema}
      columnsCreator={createColumns}
      filter
    />
  )
}

export default TaakList
