import MainTemplate from '../../../containers/pages/PageTemplates/MainTemplate'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from '@fluentui/react-hooks'
import ErrorMessageBar from '../../../components/ErrorMessageBar/ErrorMessageBar'
import { TenantInspectietakenFormPanel } from './TenantInspectietakenFormPanel'
import { TenantInspectietaakListDto, zodiosHooks } from '../../../api/ApiClient'
import { usePagedParams } from '../../../services/usePagedParams'
import { VgtPagedTable } from '../../../components/VgtPagedTable/VgtPagedTable'
import { IColumn, Link, SearchBox } from '@fluentui/react'
import { createBooleanColumn, createColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { getTitleAndMessage } from '../../../services/HandleError'

export type TenantInspectietakenListDtoType = z.infer<typeof TenantInspectietaakListDto>;

export const TenantInspectietakenList = () => {
  const navigate = useNavigate()
  const { validatedSearchParams, setPage, setOrder, setFilter } = usePagedParams()
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [inspectietaak, setInspectietaak] = useState<TenantInspectietakenListDtoType|undefined>()
  const [error, setError] = React.useState<string>()

  const { data, invalidate, isLoading } = zodiosHooks.useGetApiTenantInspectietaak({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })
  const columns: IColumn[] = React.useMemo(() => {
    return [
      createColumn('Inspectietaak', getPropertyName<TenantInspectietakenListDtoType>('taak'), 600, true,
        (item: TenantInspectietakenListDtoType) => (
          <Link key={item.tenantInspectietaakId}
                onClick={() => handleItemInvoked(item)}>
            {item.taak}
          </Link>
        )),
      createBooleanColumn('Actief', getPropertyName<TenantInspectietakenListDtoType>('actief')),
      createColumn('Bestekcode', getPropertyName<TenantInspectietakenListDtoType>('bestekcode'), 'L', false),
      createColumn('Verkoopprijs (incl. btw)', getPropertyName<TenantInspectietakenListDtoType>('verkoopprijs'), 'XL', false),
      createColumn('Inkoopprijs (excl. btw)', getPropertyName<TenantInspectietakenListDtoType>('inkoopprijs'), 'XL', false),
      createColumn('Onderhoudsspecialisme', getPropertyName<TenantInspectietakenListDtoType>('onderhoudsspecialisme'), 'XL', false),
      createColumn('Onderhoudstaak ERP', getPropertyName<TenantInspectietakenListDtoType>('onderhoudstaakErp'), 'XL', false),
      createColumn('Bewerkingstijd (min)', getPropertyName<TenantInspectietakenListDtoType>('bewerkingstijdMin'), 'XL', false),
      createColumn('Kostensoort', getPropertyName<TenantInspectietakenListDtoType>('kostensoort'), 'XL', false),
    ]
  }, [])

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => invalidate(), icon: 'Refresh' }
  ]

  const handleItemInvoked = (inspectietaak: TenantInspectietakenListDtoType) => {
    setInspectietaak(inspectietaak)
    openPanel()
  }
  const handleDismissPanel = () => {
    setInspectietaak(undefined)
    dismissPanel()
  }

  return (
    <MainTemplate title="Inrichten inspectietaken" subTitle="Overzicht" commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder="Filter" styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(_event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => invalidate()} />
      <TenantInspectietakenFormPanel inspectietaak={inspectietaak} dismissPanel={handleDismissPanel}
                                     isOpen={isPanelOpen}
                                     invalidate={invalidate} />

      <VgtPagedTable
        items={data?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: data?.totalCount ?? 0,
          totalPages: data?.totalPages ?? 0,
          hasNextPage: data?.hasNextPage || false,
          hasPreviousPage: data?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isLoading}
        getKey={(item) => item?.inspectietaakId}
        onItemInvoked={(e) => handleItemInvoked(e)}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}