import {
  DefaultButton,
  ISpinnerStyles,
  IStackStyles,
  SearchBox,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import { PageIdDefinition, PAGES } from 'pages'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { zodiosHooks } from '../../../../api/ApiClient'
import { usePagedParams } from '../../../../services/usePagedParams'
import GebouwenRow from './gebouwenRow'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { getTitleAndMessage } from '../../../../services/HandleError'

const stackStyles: IStackStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: '5px',
    overflow: 'auto'
  }
}

const spinnerStyles: Partial<ISpinnerStyles> = {
  root: {
    zIndex: 100,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}

const Gebouwen: React.FC<{}> = () => {
  const navigate = useNavigate()
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const { validatedSearchParams, setFilter, setPage } = usePagedParams()
  const [error, setError] = useState<string>()


  const { data: gebouwenItems, invalidate: refreshGebouwenItems } = zodiosHooks.useGetGebouwendetails({
    queries: {
      Filter: validatedSearchParams.filter,
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize
    }
  })

  const handleListSelectionChanged = (id: number) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        return prevSelectedItems.filter(item => item !== id)
      } else {
        return [...prevSelectedItems, id]
      }
    })
  }

  const handleItemInvoked = (id: string) => {
    let url: string
    if (id) url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route.replace(':id', id)
    else url = PAGES[PageIdDefinition.beheer.fysiek.gebouwEdit].route
    navigate(url)
  }

  const { mutate: deleteGebouw } = zodiosHooks.useDeleteGebouwen({}, {
    onSuccess: () => refreshGebouwenItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const removeItems = () => {
    deleteGebouw({ itemsToDelete: selectedItems })
    setSelectedItems([])
  }

  const commandItems = [
    { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
    { text: 'Verversen', onClick: () => refreshGebouwenItems(), icon: 'Refresh' },
    {
      text: 'Toevoegen', onClick: () => {
        handleItemInvoked('0')
      }, icon: 'Add'
    },
    { text: 'Verwijderen', onClick: () => removeItems(), icon: 'Delete', disabled: !selectedItems.length }
  ]

  return (
    <MainTemplate title="Gebouwen" subTitle="Overzicht" commandItems={commandItems}>
      <SearchBox placeholder="Filter" styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => refreshGebouwenItems()} />
      <ErrorMessageBar error={error} />
      <div style={{ overflow: 'auto', marginBottom: 5 }}>
        <Stack styles={stackStyles}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
            <span style={{ width: 100 }}></span>
            <Text style={{ width: 150, fontWeight: '600', paddingLeft: 3 }}>Gebouwcode</Text>
            <Text style={{ width: 275, fontWeight: '600', paddingLeft: 2 }}>Gebouwnaam</Text>
          </div>
          {gebouwenItems ? gebouwenItems?.items?.map((item, index) =>
            <GebouwenRow key={item.gebouwId} index={index} item={item}
                         handleItemSelectionChanged={handleListSelectionChanged} />
          ) : <Spinner size={SpinnerSize.large} styles={spinnerStyles} />}
        </Stack>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', padding: 12 }}>
        <div>
          <DefaultButton iconProps={{ iconName: 'previous' }} disabled={!gebouwenItems?.hasPreviousPage}
                         onClick={() => setPage(validatedSearchParams.page - 1)} />
        </div>
        <div style={{ paddingLeft: 12, paddingTop: 4 }}>
          <Text>{gebouwenItems?.totalCount} resultaten.
            Pagina {(validatedSearchParams.page).toString()} van {gebouwenItems?.totalPages}</Text>
        </div>
        <div style={{ paddingLeft: 12 }}>
          <DefaultButton iconProps={{ iconName: 'next' }} disabled={!gebouwenItems?.hasNextPage}
                         onClick={() => setPage(validatedSearchParams.page + 1)} />
        </div>
      </div>
    </MainTemplate>
  )
}

export default Gebouwen