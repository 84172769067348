import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { entityAdapterInitState, IEntityAdapterState } from 'interfaces/entityAdapterState'
import { PagedEntities } from 'interfaces/pagedEntities'
import { invokeFetch } from 'services/apiClient'
import { RootState } from 'store'
import * as yup from 'yup'

export const GebouwOnderhoudsbedrijfSchema = yup.object().shape({})

export interface IGebouwOnderhoudsbedrijf {
  gebouwId: number
  onderhoudsbedrijfTyperingId: number
  typeringNaam: string
  rechtspersoonId: number
  handelsNaam: string
  emailadres: string
  telefoonnummer: string
  contactpersoon: string
}

export interface IGebouwOnderhoudsbedrijfArgs {
  gebouwId: number
}

const entityAdapter = createEntityAdapter<IGebouwOnderhoudsbedrijf>({
  selectId: e => e.onderhoudsbedrijfTyperingId,
  sortComparer: (a, b) => (a.typeringNaam ?? '').toString().localeCompare(b.typeringNaam?.toString())
})

const baseUrl = '/gebouwOnderhoudsbedrijf'
const basePrefix = 'gebouwOnderhoudsbedrijf'
const getSliceState = (state: RootState) => state.gebouwOnderhoudsbedrijf

export const fetchAll = createAsyncThunk(`${basePrefix}/fetchAll`, async (args: IGebouwOnderhoudsbedrijfArgs, thunkAPI) => {
  return await invokeFetch<PagedEntities<IGebouwOnderhoudsbedrijf>>(thunkAPI, 'GET', `${baseUrl}?gebouwId=${args.gebouwId}`)
})

export const updateGebouwOnderhoudsbedrijf = createAsyncThunk(`${basePrefix}/updateStatus`, async (entity: IGebouwOnderhoudsbedrijf, thunkAPI) => {
  return await invokeFetch<IGebouwOnderhoudsbedrijf>(thunkAPI, 'PUT', `${baseUrl}/${entity.gebouwId}`, entity)
})

export const addGebouwOnderhoudsbedrijf = createAsyncThunk(`${basePrefix}/addStatus`, async (entity: IGebouwOnderhoudsbedrijf, thunkAPI) => {
  return await invokeFetch<IGebouwOnderhoudsbedrijf>(thunkAPI, 'POST', baseUrl, entity)
})


const setPendingState = (state: EntityState<IGebouwOnderhoudsbedrijf> & IEntityAdapterState) => {
  state.error = null
  state.status = 'pending'
}
const setSucceededState = (state: EntityState<IGebouwOnderhoudsbedrijf> & IEntityAdapterState) => {
  state.error = null
  state.status = 'succeeded'
}
const setRejectedState = (state: EntityState<IGebouwOnderhoudsbedrijf> & IEntityAdapterState, action) => {
  state.status = 'failed'
  state.error = action.error.message || null
}

export const gebouwOnderhoudsbedrijfSlice = createSlice({
  name: basePrefix,
  initialState: entityAdapter.getInitialState(entityAdapterInitState),

  reducers: {
    clearError: state => {
      state.error = null
    },
    select: (state, action: PayloadAction<string | undefined>) => {
      state.selectedId = action.payload
    },
    clearSelection: state => {
      state.selectedId = undefined
    },
    add: entityAdapter.addOne,
    modify: entityAdapter.upsertOne,
    removeMany: entityAdapter.removeMany,
    setAll: entityAdapter.setAll
  },

  extraReducers: builder => {
    builder.addCase(fetchAll.pending, state => setPendingState(state))
    builder.addCase(fetchAll.fulfilled, (state, action: PayloadAction<PagedEntities<IGebouwOnderhoudsbedrijf>>) => {
      setSucceededState(state)
      entityAdapter.setAll(state, action.payload.items)
    })
    builder.addCase(fetchAll.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(updateGebouwOnderhoudsbedrijf.pending, state => setPendingState(state))
    builder.addCase(updateGebouwOnderhoudsbedrijf.fulfilled, (state, action: PayloadAction<IGebouwOnderhoudsbedrijf>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(updateGebouwOnderhoudsbedrijf.rejected, (state, action) => setRejectedState(state, action))
    builder.addCase(addGebouwOnderhoudsbedrijf.pending, state => setPendingState(state))
    builder.addCase(addGebouwOnderhoudsbedrijf.fulfilled, (state, action: PayloadAction<IGebouwOnderhoudsbedrijf>) => {
      entityAdapter.upsertOne(state, action.payload)
      setSucceededState(state)
    })
    builder.addCase(addGebouwOnderhoudsbedrijf.rejected, (state, action) => {
      setRejectedState(state, action)
    })

  }
})

export const getSelectedEntity = (state: RootState) => {
  const selectedId = getSliceState(state).selectedId
  if (selectedId) return getSliceState(state).entities[selectedId]
  else return undefined
}

export const getLoadingState = (state: RootState) => state.gebouwOnderhoudsbedrijf.status

export const getErrorState = (state: RootState) => state.gebouwOnderhoudsbedrijf.error

export const { add, modify, removeMany, select, clearSelection } = gebouwOnderhoudsbedrijfSlice.actions
export const {
  selectAll,
  selectEntities,
  selectById
} = entityAdapter.getSelectors<RootState>(state => state.gebouwOnderhoudsbedrijf)

export default gebouwOnderhoudsbedrijfSlice.reducer
