import React, { useEffect, useState } from 'react'
import FluentTable from '../../../components/FluentTable'
import { createColumn, createNumberColumn } from '../../../lib/gridHelper'
import { getPropertyName } from '../../../lib/interfaceUtils'
import { z } from 'zod'
import { VgeRuimteListDto, zodiosHooks } from '../../../api/ApiClient'
import { VgeRuimtePanel } from './VgeRuimtePanel'
import { useBoolean } from '@fluentui/react-hooks'
import DeleteConfirmationDialog from '../../../components/DeleteConfirmationDialog'
import CommandBarTemplate, { ICommandItem } from '../../../containers/pages/PageTemplates/CommandBarTemplate'
import useRoles from '../../../services/UseRoles'
import { Link, PivotItem } from '@fluentui/react'
import DocumentList, { DocumentDoelType } from '../../beheer/documents/DocumentList'

interface VgeRuimtesProps {
  vgeId: number;
}

type VgeRuimteListDtoType = z.infer<typeof VgeRuimteListDto>;

export const VgeRuimtes: React.FC<VgeRuimtesProps> = ({ vgeId }) => {
  const [vgeRuimteId, setVgeRuimteId] = useState<number>(-1)
  const [inEditMode, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [hideDialog, setHideDialog] = useState<boolean>(true)
  const { isVastgoedbeheer } = useRoles()
  const [isMedia, setIsMedia] = useState<boolean>(false)
  const [showMedia, setShowMedia] = useState<boolean>(false)

  const { data, invalidate, isLoading } = zodiosHooks.useGetVgeVgeIdruimtes({ params: { vgeId: vgeId ?? 0 } })
  const { mutate: deleteRows, isLoading: isDeleting } = zodiosHooks.useDeleteVgeRuimte({}, {
    onSuccess: () => {
      setSelectedIds([])
      invalidate()
      setHideDialog(true)
    },
    onError: (error) => alert(error)
  })
  const removeItems = () => {
    deleteRows({ itemsToDelete: selectedIds.map(v => parseInt(v)) })
  }

  const commandItems: ICommandItem[] = !isVastgoedbeheer ? [] : [
    {
      text: 'Toevoegen',
      onClick: () => {
        setVgeRuimteId(-1)
        openPanel()
      },
      icon: 'Add',
      visible: !showMedia
    },
    {
      text: 'Verwijderen',
      onClick: () => {
        setHideDialog(false)
      },
      icon: 'Delete',
      disabled: isDeleting || selectedIds.length === 0,
      visible: !showMedia
    },
    {
      text: 'Terug naar lijst',
      onClick: () => {
        invalidate()
        setIsMedia(false)
        setShowMedia(false)
      },
      icon: 'PlaybackRate1x',
      disabled: !isMedia,
      visible: showMedia
    },
    {
      text: 'Media',
      onClick: () => {
        setShowMedia(true)
      },
      icon: 'DocumentSet',
      disabled: !isMedia
    }
  ]

  const toggleShowMedia = () => {
    setIsMedia(true)
    setShowMedia(true)
  }

  const cols = [
    createColumn('Ruimtesoort', getPropertyName<VgeRuimteListDtoType>('soort'), 'XXL'),
    createColumn('Nummer', getPropertyName<VgeRuimteListDtoType>('nummer'), 'M'),
    createColumn('Categorie', getPropertyName<VgeRuimteListDtoType>('categorie'), 'L'),
    createNumberColumn('Bouwlaag', getPropertyName<VgeRuimteListDtoType>('bouwlaag')),
    createNumberColumn('Oppervlakte', getPropertyName<VgeRuimteListDtoType>('oppervlakte')),
    createColumn('Verwarmd', getPropertyName<VgeRuimteListDtoType>('verwarmd'), 'M'),
    createColumn('Gekoeld', getPropertyName<VgeRuimteListDtoType>('gekoeld'), 'M'),
    createColumn('Verhuureenheden', getPropertyName<VgeRuimteListDtoType>('vheCount'), 'L', false,
      (item: VgeRuimteListDtoType) => (item.vheCount > 0 && <span>{item.vheCount} verhuureenheden</span>)),
    createColumn('Media', getPropertyName<VgeRuimteListDtoType>('documentCount'), 'M', false,
      (item: VgeRuimteListDtoType) => (
        <Link key={item.id}
              onClick={() => toggleShowMedia()}>
          ({item.documentCount})
        </Link>
      ))
  ]

  useEffect(() => {
    if (selectedIds.length === 1) {
      setIsMedia(true)
    } else {
      setIsMedia(false)
    }
  }, [selectedIds])

  return (
    <>
      <VgeRuimtePanel dismissPanel={dismissPanel} isOpen={inEditMode} vgeRuimteId={vgeRuimteId}
                      invalidateParent={invalidate} vgeId={vgeId} />
      <DeleteConfirmationDialog onConfirm={removeItems} onCancel={() => setHideDialog(true)} entityName={'ruimtes'}
                                hideDialog={hideDialog} />
      <div style={{ marginRight: 15 }}>
        <CommandBarTemplate items={commandItems}></CommandBarTemplate>
        <div style={{ display: 'flex', height: '70vh' }}>
          {showMedia ? selectedIds.length === 1 && (
            <PivotItem key='vge_ruimte_documenten' headerText='Media' itemKey='documents'>
              <DocumentList doelType={DocumentDoelType.vgeRuimte} doelId={parseInt(selectedIds[0])} />
            </PivotItem>
          )
            : <FluentTable loading={isLoading}
                           columns={cols}
                           items={data ?? [] as VgeRuimteListDtoType[]}
                           onItemInvoked={r => {
                             setVgeRuimteId(parseInt(r))
                             openPanel()
                           }
                           }
                           onGetKey={r => r.id.toString()}
                           onSelectionChanged={setSelectedIds}
            />}
        </div>
      </div>
    </>
  )
}