import React, { useMemo, useState } from 'react'
import { IColumn, Link, SearchBox } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'
import { getPropertyName } from 'lib/interfaceUtils'
import EditPanelElementElementDetail from './editPanel'
import MainTemplate from 'containers/pages/PageTemplates/MainTemplate'
import useRoles from 'services/UseRoles'
import { ElementElementDetailDto, zodiosHooks } from '../../../../api/ApiClient'
import { getTitleAndMessage } from '../../../../services/HandleError'
import { z } from 'zod'
import ErrorMessageBar from '../../../../components/ErrorMessageBar/ErrorMessageBar'
import { usePagedParams } from '../../../../services/usePagedParams'
import { VgtPagedTable } from '../../../../components/VgtPagedTable/VgtPagedTable'

type ElementElementDetailDtoType = z.infer<typeof ElementElementDetailDto>;

const ElementElementDetailList: React.FC<{}> = _props => {
  const navigate = useNavigate()
  const [isPanelOpen, { setTrue: openPanel, setFalse: closePanel }] = useBoolean(false)
  const { isAdmin } = useRoles()
  const [selectedElementElementDetailspage, setSelectedElementElementDetailspage] = useState<ElementElementDetailDtoType | undefined>()
  const [error, setError] = useState<string>()
  const { validatedSearchParams, setFilter, setOrder, setPage } = usePagedParams()
  const [selectedIds, setSelectedIds] = useState<number[]>([])

  const {
    data: elementElementDetailspage,
    invalidate: fetchElementElementDetailspageItems,
    isLoading: isFetchingElementElementDetailspageItems
  } = zodiosHooks.useGetElementElementDetailspage({
    queries: {
      SortKey: validatedSearchParams.sortKey,
      SortDirection: validatedSearchParams.sortDirection,
      PageIndex: validatedSearchParams.page,
      PageSize: validatedSearchParams.pageSize,
      Filter: validatedSearchParams.filter
    }
  }, {
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const { mutate: deleteElementElementDetails } = zodiosHooks.useDeleteElementElementDetails({}, {
    onSuccess: () => fetchElementElementDetailspageItems(),
    onError: (error) => setError(getTitleAndMessage(error).message)
  })

  const refreshItems = () => fetchElementElementDetailspageItems()

  const dismissPanel = () => {
    closePanel()
  }

  const handleItemInvoked = (item: ElementElementDetailDtoType) => {
    setSelectedElementElementDetailspage(item)
    openPanel()
  }

  const removeItems = () => {
    deleteElementElementDetails(selectedIds)
  }

  const columns: IColumn[] = [
    {
      key: getPropertyName<ElementElementDetailDtoType>('element'),
      name: 'Element',
      fieldName: getPropertyName<ElementElementDetailDtoType>('element'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: ElementElementDetailDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item)}>
          {item.element}
        </Link>
      )
    },
    {
      key: getPropertyName<ElementElementDetailDtoType>('elementDetail'),
      name: 'Elementdetail',
      fieldName: getPropertyName<ElementElementDetailDtoType>('elementDetail'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true,
      onRender: (item: ElementElementDetailDtoType) => (
        <Link key={item.id} onClick={() => handleItemInvoked(item)}>
          {item.elementDetail}
        </Link>
      )
    },
    {
      key: getPropertyName<ElementElementDetailDtoType>('materiaalSoortNaam'),
      name: 'Materiaalsoort',
      fieldName: getPropertyName<ElementElementDetailDtoType>('materiaalSoortNaam'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<ElementElementDetailDtoType>('nlSfBCode'),
      name: 'NL-SfB Elementcode',
      fieldName: getPropertyName<ElementElementDetailDtoType>('nlSfBCode'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    },
    {
      key: getPropertyName<ElementElementDetailDtoType>('nlSfBOmschrijving'),
      name: 'NL-SfB Omschrijving',
      fieldName: getPropertyName<ElementElementDetailDtoType>('nlSfBOmschrijving'),
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isRowHeader: true
    }
  ]

  const commandItems = useMemo(
    () => [
      { text: 'Terug', onClick: () => navigate(-1), icon: 'Back', iconOnly: true },
      { text: 'Verversen', onClick: () => refreshItems(), icon: 'Refresh' },
      {
        visible: isAdmin,
        text: 'Toevoegen',
        onClick: () => {
          setSelectedElementElementDetailspage(undefined)
          openPanel()
        },
        icon: 'Add'
      },
      {
        visible: isAdmin,
        text: 'Verwijderen',
        onClick: () => removeItems(),
        icon: 'Delete',
        disabled: !selectedIds.length
      }
    ],
    [isAdmin, selectedIds.length]
  )

  return (
    <MainTemplate title='Cartotheek-item' subTitle='Overzicht' commandItems={commandItems}>
      <ErrorMessageBar error={error} />
      <SearchBox placeholder='Filter' styles={{ root: { width: '400px', margin: '6px' } }}
                 value={validatedSearchParams.filter}
                 onChange={(event, newValue: string | undefined) => setFilter(newValue ?? '')}
                 onSearch={() => fetchElementElementDetailspageItems()} />
      <EditPanelElementElementDetail fetchElementElementDetailspageItems={fetchElementElementDetailspageItems}
                                     selectedElementElementDetailspage={selectedElementElementDetailspage}
                                     dismissPanel={dismissPanel} isOpen={isPanelOpen} />
      <VgtPagedTable
        items={elementElementDetailspage?.items || undefined}
        height={'203'}
        pagingInfo={{
          pageSize: validatedSearchParams.pageSize,
          currentPage: validatedSearchParams.page,
          totalCount: elementElementDetailspage?.totalCount ?? 0,
          totalPages: elementElementDetailspage?.totalPages ?? 0,
          hasNextPage: elementElementDetailspage?.hasNextPage || false,
          hasPreviousPage: elementElementDetailspage?.hasPreviousPage || false,
          sortKey: validatedSearchParams.sortKey || 'naam',
          sortDirection: validatedSearchParams.sortDirection || 'asc'
        }}
        columns={columns}
        isLoading={isFetchingElementElementDetailspageItems}
        getKey={(item) => item?.id}
        onItemInvoked={(e) => handleItemInvoked(e)}
        onSelectionChanged={setSelectedIds}
        onPageChanged={setPage}
        onSortChanged={setOrder}
      />
    </MainTemplate>
  )
}
export default ElementElementDetailList
